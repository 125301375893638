@charset "UTF-8";
/*
* > COLORS
*/
/*
* > FONTS
*/
/*
* > SPACING
*/
/*
* > COMPONENTS
*/
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.material-icons-sharp {
  font-size: inherit;
  line-height: inherit;
}

:root {
  --blue: #0c234b;
  --red: #ab0520;
  --white: #fff;
  --bloom: #ef4056;
  --chili: #8b0015;
  --sky: #81d3eb;
  --oasis: #378dbd;
  --azurite: #1e5288;
  --midnight: #001c48;
  --cool-gray: #e2e9eb;
  --warm-gray: #f4ede5;
  --leaf: #70b865;
  --river: #007d84;
  --silver: #9eabae;
  --mesa: #a95c42;
  --ash: #403635;
  --sage: #4a634e;
  --black: #000;
  --success: #70b865;
  --info: #81d3eb;
  --warning: #f19e1f;
  --danger: #a95c42;
  --light: #dee2e6;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: proxima-nova, calibri, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: proxima-nova, calibri, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #8b0015;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #3f0009;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #495057;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1,
.text-size-h1, .h2, .text-size-h2, .h3, .text-size-h3, .h4, .text-size-h4, .h5, .text-size-h5, .h6, .text-size-h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1,
.text-size-h1 {
  font-size: 2.5rem;
}

h2, .h2, .text-size-h2 {
  font-size: 2rem;
}

h3, .h3, .text-size-h3 {
  font-size: 1.75rem;
}

h4, .h4, .text-size-h4 {
  font-size: 1.5rem;
}

h5, .h5, .text-size-h5 {
  font-size: 1.25rem;
}

h6, .h6, .text-size-h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1, .col-xs-offset-1 {
  margin-left: 8.333333%;
}

.offset-2, .col-xs-offset-2 {
  margin-left: 16.666667%;
}

.offset-3, .col-xs-offset-3 {
  margin-left: 25%;
}

.offset-4, .col-xs-offset-4 {
  margin-left: 33.333333%;
}

.offset-5, .col-xs-offset-5 {
  margin-left: 41.666667%;
}

.offset-6, .col-xs-offset-6 {
  margin-left: 50%;
}

.offset-7, .col-xs-offset-7 {
  margin-left: 58.333333%;
}

.offset-8, .col-xs-offset-8 {
  margin-left: 66.666667%;
}

.offset-9, .col-xs-offset-9 {
  margin-left: 75%;
}

.offset-10, .col-xs-offset-10 {
  margin-left: 83.333333%;
}

.offset-11, .col-xs-offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1, .col-sm-offset-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2, .col-sm-offset-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3, .col-sm-offset-3 {
    margin-left: 25%;
  }
  .offset-sm-4, .col-sm-offset-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5, .col-sm-offset-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6, .col-sm-offset-6 {
    margin-left: 50%;
  }
  .offset-sm-7, .col-sm-offset-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8, .col-sm-offset-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9, .col-sm-offset-9 {
    margin-left: 75%;
  }
  .offset-sm-10, .col-sm-offset-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11, .col-sm-offset-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1, .col-md-offset-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2, .col-md-offset-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3, .col-md-offset-3 {
    margin-left: 25%;
  }
  .offset-md-4, .col-md-offset-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5, .col-md-offset-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6, .col-md-offset-6 {
    margin-left: 50%;
  }
  .offset-md-7, .col-md-offset-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8, .col-md-offset-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9, .col-md-offset-9 {
    margin-left: 75%;
  }
  .offset-md-10, .col-md-offset-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11, .col-md-offset-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1, .col-lg-offset-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2, .col-lg-offset-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3, .col-lg-offset-3 {
    margin-left: 25%;
  }
  .offset-lg-4, .col-lg-offset-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5, .col-lg-offset-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6, .col-lg-offset-6 {
    margin-left: 50%;
  }
  .offset-lg-7, .col-lg-offset-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8, .col-lg-offset-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9, .col-lg-offset-9 {
    margin-left: 75%;
  }
  .offset-lg-10, .col-lg-offset-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11, .col-lg-offset-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1, .col-xl-offset-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2, .col-xl-offset-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3, .col-xl-offset-3 {
    margin-left: 25%;
  }
  .offset-xl-4, .col-xl-offset-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5, .col-xl-offset-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6, .col-xl-offset-6 {
    margin-left: 50%;
  }
  .offset-xl-7, .col-xl-offset-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8, .col-xl-offset-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9, .col-xl-offset-9 {
    margin-left: 75%;
  }
  .offset-xl-10, .col-xl-offset-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11, .col-xl-offset-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(222, 226, 230, 0.4);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(222, 226, 230, 0.5);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d7ebd4;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b5daaf;
}

.table-hover .table-success:hover {
  background-color: #c7e3c3;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #c7e3c3;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #dcf3f9;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #bde8f5;
}

.table-hover .table-info:hover {
  background-color: #c6ecf5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c6ecf5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbe4c0;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f8cd8b;
}

.table-hover .table-warning:hover {
  background-color: #f9daa8;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9daa8;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e7d1ca;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #d2aa9d;
}

.table-hover .table-danger:hover {
  background-color: #dfc2b8;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #dfc2b8;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f6f7f8;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #eef0f2;
}

.table-hover .table-light:hover {
  background-color: #e8eaed;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e8eaed;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(222, 226, 230, 0.5);
}

.table-hover .table-active:hover {
  background-color: rgba(207, 213, 219, 0.5);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(207, 213, 219, 0.5);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #343a40;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #1e56b9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #495057;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #70b865;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(112, 184, 101, 0.9);
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #70b865;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2370b865' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #70b865;
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #70b865;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2370b865' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #70b865;
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #70b865;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #70b865;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #70b865;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #90c888;
  background-color: #90c888;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #70b865;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #70b865;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #70b865;
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #a95c42;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(169, 92, 66, 0.9);
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #a95c42;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23a95c42' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23a95c42' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #a95c42;
  box-shadow: 0 0 0 0.2rem rgba(169, 92, 66, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #a95c42;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23a95c42' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23a95c42' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #a95c42;
  box-shadow: 0 0 0 0.2rem rgba(169, 92, 66, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #a95c42;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #a95c42;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #a95c42;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #c0775e;
  background-color: #c0775e;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(169, 92, 66, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #a95c42;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #a95c42;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #a95c42;
  box-shadow: 0 0 0 0.2rem rgba(169, 92, 66, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-success {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-success:hover {
  color: #fff;
  background-color: #5aa94e;
  border-color: #55a04a;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #5aa94e;
  border-color: #55a04a;
  box-shadow: 0 0 0 0.2rem rgba(100, 162, 92, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #55a04a;
  border-color: #519746;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 162, 92, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-info:hover {
  color: #212529;
  background-color: #60c7e6;
  border-color: #55c4e4;
}

.btn-info:focus, .btn-info.focus {
  color: #212529;
  background-color: #60c7e6;
  border-color: #55c4e4;
  box-shadow: 0 0 0 0.2rem rgba(115, 185, 206, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #55c4e4;
  border-color: #4ac0e2;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 185, 206, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-warning:hover {
  color: #fff;
  background-color: #dc8a0e;
  border-color: #d0830d;
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #dc8a0e;
  border-color: #d0830d;
  box-shadow: 0 0 0 0.2rem rgba(210, 140, 33, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d0830d;
  border-color: #c47b0c;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 140, 33, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #a95c42;
  border-color: #a95c42;
}

.btn-danger:hover {
  color: #fff;
  background-color: #8d4d37;
  border-color: #844834;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #8d4d37;
  border-color: #844834;
  box-shadow: 0 0 0 0.2rem rgba(182, 116, 94, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #a95c42;
  border-color: #a95c42;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #844834;
  border-color: #7b4330;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(182, 116, 94, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-light:hover {
  color: #212529;
  background-color: #c8cfd6;
  border-color: #c1c9d0;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #c8cfd6;
  border-color: #c1c9d0;
  box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #c1c9d0;
  border-color: #bac2cb;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 198, 202, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-success {
  color: #70b865;
  border-color: #70b865;
}

.btn-outline-success:hover {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #70b865;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #70b865;
  border-color: #70b865;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
}

.btn-outline-info {
  color: #81d3eb;
  border-color: #81d3eb;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #81d3eb;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #81d3eb;
  border-color: #81d3eb;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
}

.btn-outline-warning {
  color: #f19e1f;
  border-color: #f19e1f;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f19e1f;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f19e1f;
  border-color: #f19e1f;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
}

.btn-outline-danger {
  color: #a95c42;
  border-color: #a95c42;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #a95c42;
  border-color: #a95c42;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(169, 92, 66, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #a95c42;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #a95c42;
  border-color: #a95c42;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(169, 92, 66, 0.5);
}

.btn-outline-light {
  color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #dee2e6;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #dee2e6;
  border-color: #dee2e6;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #8b0015;
  text-decoration: none;
}

.btn-link:hover {
  color: #3f0009;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block, .btn-group-block, .btn-group-block .btn {
  display: block;
  width: 100%;
}

.btn-block + .btn-block, .btn-group-block + .btn-block, .btn-group-block .btn + .btn-block, .btn-block + .btn-group-block, .btn-group-block + .btn-group-block, .btn-group-block .btn + .btn-group-block, .btn-group-block .btn-block + .btn, .btn-group-block .btn-group-block + .btn, .btn-group-block .btn + .btn {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block, input.btn-group-block[type="submit"], .btn-group-block input.btn[type="submit"],
input[type="reset"].btn-block,
input.btn-group-block[type="reset"],
.btn-group-block input.btn[type="reset"],
input[type="button"].btn-block,
input.btn-group-block[type="button"],
.btn-group-block input.btn[type="button"] {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider, .divider,
.nav-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e5288;
}

.dropdown-item.active, .dropdown-item:active {
  color: #0c234b;
  text-decoration: none;
  background-color: #fff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #fff;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group-prepend, .input-group-btn,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn, .input-group-btn .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus, .input-group-btn .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-btn .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-btn .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-btn .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-btn .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend, .input-group-btn {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-btn > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-btn > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-btn > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-btn > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0c234b;
  background-color: #0c234b;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #1e56b9;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #2c6ddd;
  border-color: #2c6ddd;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0c234b;
  background-color: #0c234b;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(12, 35, 75, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(12, 35, 75, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(12, 35, 75, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(12, 35, 75, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #1e56b9;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #1e56b9;
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0c234b;
  border: 0;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #2c6ddd;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0c234b;
  border: 0;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #2c6ddd;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0c234b;
  border: 0;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #2c6ddd;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #495057;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e9ecef;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #e9ecef;
}

.nav-tabs .nav-link.disabled {
  color: #495057;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #e9ecef #e9ecef #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #001c48;
  background-color: #e9ecef;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-default .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-default .navbar-brand:hover, .navbar-light .navbar-brand:focus, .navbar-default .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link, .navbar-default .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-default .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-default .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled, .navbar-default .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-default .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-default .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-default .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-default .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler, .navbar-default .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon, .navbar-default .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text, .navbar-default .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a, .navbar-default .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-default .navbar-text a:hover, .navbar-light .navbar-text a:focus, .navbar-default .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card, .well,
.panel,
.thumbnail {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card > hr, .well > hr, .panel > hr, .thumbnail > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group, .well > .list-group, .panel > .list-group, .thumbnail > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child, .well > .list-group:first-child, .panel > .list-group:first-child, .thumbnail > .list-group:first-child {
  border-top-width: 0;
}

.card > .list-group:last-child, .well > .list-group:last-child, .panel > .list-group:last-child, .thumbnail > .list-group:last-child {
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .well > .card-header + .list-group, .panel > .card-header + .list-group, .thumbnail > .card-header + .list-group,
.card > .list-group + .card-footer,
.well > .list-group + .card-footer,
.panel > .list-group + .card-footer,
.thumbnail > .list-group + .card-footer {
  border-top: 0;
}

.card-body, .panel .panel-heading,
.panel .panel-footer, .panel .panel-body, .thumbnail .caption, .well {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #e9ecef;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #e9ecef;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-deck .card, .card-deck .well,
.card-deck .panel,
.card-deck .thumbnail {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card, .card-deck .well,
  .card-deck .panel,
  .card-deck .thumbnail {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card, .card-group > .well,
.card-group > .panel,
.card-group > .thumbnail {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card, .card-group > .well,
  .card-group > .panel,
  .card-group > .thumbnail {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card, .card-group > .well + .card, .card-group > .panel + .card, .card-group > .thumbnail + .card, .card-group > .card + .well, .card-group > .well + .well, .card-group > .panel + .well, .card-group > .thumbnail + .well,
  .card-group > .card + .panel,
  .card-group > .well + .panel,
  .card-group > .panel + .panel,
  .card-group > .thumbnail + .panel,
  .card-group > .card + .thumbnail,
  .card-group > .well + .thumbnail,
  .card-group > .panel + .thumbnail,
  .card-group > .thumbnail + .thumbnail {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card, .card-columns .well,
.card-columns .panel,
.card-columns .thumbnail {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card, .card-columns .well,
  .card-columns .panel,
  .card-columns .thumbnail {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card, .accordion > .well,
.accordion > .panel,
.accordion > .thumbnail {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type), .accordion > .well:not(:last-of-type),
.accordion > .panel:not(:last-of-type),
.accordion > .thumbnail:not(:last-of-type) {
  border-bottom: 0;
}

.accordion > .card > .card-header, .accordion > .well > .card-header, .accordion > .panel > .card-header, .accordion > .thumbnail > .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1e5288;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #001c48;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(12, 35, 75, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0c234b;
  border-color: #0c234b;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.badge, .label {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge, .label {
    transition: none;
  }
}

a.badge:hover, a.label:hover, a.badge:focus, a.label:focus {
  text-decoration: none;
}

.badge:empty, .label:empty {
  display: none;
}

.btn .badge, .btn .label {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.badge-success, .label-success {
  color: #212529;
  background-color: #70b865;
}

a.badge-success:hover, a.label-success:hover, a.badge-success:focus, a.label-success:focus {
  color: #212529;
  background-color: #55a04a;
}

a.badge-success:focus, a.label-success:focus, a.badge-success.focus, a.focus.label-success {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(112, 184, 101, 0.5);
}

.badge-info, .label-info {
  color: #212529;
  background-color: #81d3eb;
}

a.badge-info:hover, a.label-info:hover, a.badge-info:focus, a.label-info:focus {
  color: #212529;
  background-color: #55c4e4;
}

a.badge-info:focus, a.label-info:focus, a.badge-info.focus, a.focus.label-info {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(129, 211, 235, 0.5);
}

.badge-warning, .label-warning {
  color: #212529;
  background-color: #f19e1f;
}

a.badge-warning:hover, a.label-warning:hover, a.badge-warning:focus, a.label-warning:focus {
  color: #212529;
  background-color: #d0830d;
}

a.badge-warning:focus, a.label-warning:focus, a.badge-warning.focus, a.focus.label-warning {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(241, 158, 31, 0.5);
}

.badge-danger, .label-danger {
  color: #fff;
  background-color: #a95c42;
}

a.badge-danger:hover, a.label-danger:hover, a.badge-danger:focus, a.label-danger:focus {
  color: #fff;
  background-color: #844834;
}

a.badge-danger:focus, a.label-danger:focus, a.badge-danger.focus, a.focus.label-danger {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(169, 92, 66, 0.5);
}

.badge-light, .label-muted {
  color: #212529;
  background-color: #dee2e6;
}

a.badge-light:hover, a.label-muted:hover, a.badge-light:focus, a.label-muted:focus {
  color: #212529;
  background-color: #c1c9d0;
}

a.badge-light:focus, a.label-muted:focus, a.badge-light.focus, a.focus.label-muted {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 226, 230, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-success {
  color: #3a6035;
  background-color: #e2f1e0;
  border-color: #d7ebd4;
}

.alert-success hr {
  border-top-color: #c7e3c3;
}

.alert-success .alert-link {
  color: #263f23;
}

.alert-info {
  color: #436e7a;
  background-color: #e6f6fb;
  border-color: #dcf3f9;
}

.alert-info hr {
  border-top-color: #c6ecf5;
}

.alert-info .alert-link {
  color: #315059;
}

.alert-warning {
  color: #7d5210;
  background-color: #fcecd2;
  border-color: #fbe4c0;
}

.alert-warning hr {
  border-top-color: #f9daa8;
}

.alert-warning .alert-link {
  color: #50340a;
}

.alert-danger {
  color: #583022;
  background-color: #eeded9;
  border-color: #e7d1ca;
}

.alert-danger hr {
  border-top-color: #dfc2b8;
}

.alert-danger .alert-link {
  color: #331c14;
}

.alert-light {
  color: #737678;
  background-color: #f8f9fa;
  border-color: #f6f7f8;
}

.alert-light hr {
  border-top-color: #e8eaed;
}

.alert-light .alert-link {
  color: #5a5c5e;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0c234b;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0c234b;
  border-color: #0c234b;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-success {
  color: #3a6035;
  background-color: #d7ebd4;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #3a6035;
  background-color: #c7e3c3;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #3a6035;
  border-color: #3a6035;
}

.list-group-item-info {
  color: #436e7a;
  background-color: #dcf3f9;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #436e7a;
  background-color: #c6ecf5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #436e7a;
  border-color: #436e7a;
}

.list-group-item-warning {
  color: #7d5210;
  background-color: #fbe4c0;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7d5210;
  background-color: #f9daa8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7d5210;
  border-color: #7d5210;
}

.list-group-item-danger {
  color: #583022;
  background-color: #e7d1ca;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #583022;
  background-color: #dfc2b8;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #583022;
  border-color: #583022;
}

.list-group-item-light {
  color: #737678;
  background-color: #f6f7f8;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #737678;
  background-color: #e8eaed;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #737678;
  border-color: #737678;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: proxima-nova, calibri, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0c234b;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #0c234b;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #0c234b;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #0c234b;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #0c234b;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: proxima-nova, calibri, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle, .margin-align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom, .margin-align-top {
  vertical-align: text-bottom !important;
}

.align-text-top, .margin-align-bottom {
  vertical-align: text-top !important;
}

.bg-success, .panel-success .panel-heading, .card-success {
  background-color: #70b865 !important;
}

a.bg-success:hover, .panel-success a.panel-heading:hover, a.card-success:hover, a.bg-success:focus, .panel-success a.panel-heading:focus, a.card-success:focus,
button.bg-success:hover,
.panel-success button.panel-heading:hover,
button.card-success:hover,
button.bg-success:focus,
.panel-success button.panel-heading:focus,
button.card-success:focus {
  background-color: #55a04a !important;
}

.bg-info, .panel-info .panel-heading, .card-info {
  background-color: #81d3eb !important;
}

a.bg-info:hover, .panel-info a.panel-heading:hover, a.card-info:hover, a.bg-info:focus, .panel-info a.panel-heading:focus, a.card-info:focus,
button.bg-info:hover,
.panel-info button.panel-heading:hover,
button.card-info:hover,
button.bg-info:focus,
.panel-info button.panel-heading:focus,
button.card-info:focus {
  background-color: #55c4e4 !important;
}

.bg-warning, .panel-warning .panel-heading, .card-warning {
  background-color: #f19e1f !important;
}

a.bg-warning:hover, .panel-warning a.panel-heading:hover, a.card-warning:hover, a.bg-warning:focus, .panel-warning a.panel-heading:focus, a.card-warning:focus,
button.bg-warning:hover,
.panel-warning button.panel-heading:hover,
button.card-warning:hover,
button.bg-warning:focus,
.panel-warning button.panel-heading:focus,
button.card-warning:focus {
  background-color: #d0830d !important;
}

.bg-danger, .panel-danger .panel-heading, .card-danger {
  background-color: #a95c42 !important;
}

a.bg-danger:hover, .panel-danger a.panel-heading:hover, a.card-danger:hover, a.bg-danger:focus, .panel-danger a.panel-heading:focus, a.card-danger:focus,
button.bg-danger:hover,
.panel-danger button.panel-heading:hover,
button.card-danger:hover,
button.bg-danger:focus,
.panel-danger button.panel-heading:focus,
button.card-danger:focus {
  background-color: #844834 !important;
}

.bg-light,
.panel .panel-footer, .panel-default .panel-heading, .well {
  background-color: #dee2e6 !important;
}

a.bg-light:hover, .panel a.panel-footer:hover, .panel-default a.panel-heading:hover, a.well:hover, a.bg-light:focus, .panel a.panel-footer:focus, .panel-default a.panel-heading:focus, a.well:focus,
button.bg-light:hover,
.panel button.panel-footer:hover,
.panel-default button.panel-heading:hover,
button.well:hover,
button.bg-light:focus,
.panel button.panel-footer:focus,
.panel-default button.panel-heading:focus,
button.well:focus {
  background-color: #c1c9d0 !important;
}

.bg-dark, .callout-dark, .card-inverse, .modal-bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.callout-dark:hover, a.card-inverse:hover, a.modal-bg-dark:hover, a.bg-dark:focus, a.callout-dark:focus, a.card-inverse:focus, a.modal-bg-dark:focus,
button.bg-dark:hover,
button.callout-dark:hover,
button.card-inverse:hover,
button.modal-bg-dark:hover,
button.bg-dark:focus,
button.callout-dark:focus,
button.card-inverse:focus,
button.modal-bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white,
.panel, .well.well-hollow, .modal-bg-dark .modal-content {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border,
.panel {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0, .card-borderless {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-success, .card-success-outline {
  border-color: #70b865 !important;
}

.border-info, .card-info-outline {
  border-color: #81d3eb !important;
}

.border-warning, .card-warning-outline {
  border-color: #f19e1f !important;
}

.border-danger, .card-danger-outline {
  border-color: #a95c42 !important;
}

.border-light {
  border-color: #dee2e6 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left, .pull-left {
  float: left !important;
}

.float-right, .pull-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm,
.panel {
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 0.75rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .margin-zero-top,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0, .card-clickable .card-clickable-link, .panel .panel-title, .text-size-h1, .text-size-h2, .text-size-h3, .text-size-h4, .text-size-h5, .text-size-h6, .margin-zero-bottom,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .page-header,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .page-header,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-card {
  margin: 1.25rem !important;
}

.mt-card,
.my-card {
  margin-top: 1.25rem !important;
}

.mr-card,
.mx-card {
  margin-right: 1.25rem !important;
}

.mb-card,
.my-card {
  margin-bottom: 1.25rem !important;
}

.ml-card,
.mx-card {
  margin-left: 1.25rem !important;
}

.m-6 {
  margin: 4rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4rem !important;
}

.m-7 {
  margin: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 5rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 5rem !important;
}

.m-8 {
  margin: 6rem !important;
}

.mt-8,
.my-8 {
  margin-top: 6rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 6rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 6rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 6rem !important;
}

.m-9 {
  margin: 7rem !important;
}

.mt-9,
.my-9 {
  margin-top: 7rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 7rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 7rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 7rem !important;
}

.m-10 {
  margin: 8rem !important;
}

.mt-10,
.my-10 {
  margin-top: 8rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 8rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 8rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 8rem !important;
}

.m-20 {
  margin: 16rem !important;
}

.mt-20,
.my-20 {
  margin-top: 16rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 16rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 16rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 16rem !important;
}

.m-30 {
  margin: 24rem !important;
}

.mt-30,
.my-30 {
  margin-top: 24rem !important;
}

.mr-30,
.mx-30 {
  margin-right: 24rem !important;
}

.mb-30,
.my-30 {
  margin-bottom: 24rem !important;
}

.ml-30,
.mx-30 {
  margin-left: 24rem !important;
}

.m-min {
  margin: 0.0625rem !important;
}

.mt-min,
.my-min {
  margin-top: 0.0625rem !important;
}

.mr-min,
.mx-min {
  margin-right: 0.0625rem !important;
}

.mb-min,
.my-min {
  margin-bottom: 0.0625rem !important;
}

.ml-min,
.mx-min {
  margin-left: 0.0625rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2, .well.well-sm {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3, .page-header,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-card {
  padding: 1.25rem !important;
}

.pt-card,
.py-card {
  padding-top: 1.25rem !important;
}

.pr-card,
.px-card {
  padding-right: 1.25rem !important;
}

.pb-card,
.py-card {
  padding-bottom: 1.25rem !important;
}

.pl-card,
.px-card {
  padding-left: 1.25rem !important;
}

.p-6 {
  padding: 4rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4rem !important;
}

.p-7 {
  padding: 5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 5rem !important;
}

.pr-7,
.px-7 {
  padding-right: 5rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 5rem !important;
}

.pl-7,
.px-7 {
  padding-left: 5rem !important;
}

.p-8 {
  padding: 6rem !important;
}

.pt-8,
.py-8 {
  padding-top: 6rem !important;
}

.pr-8,
.px-8 {
  padding-right: 6rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 6rem !important;
}

.pl-8,
.px-8 {
  padding-left: 6rem !important;
}

.p-9 {
  padding: 7rem !important;
}

.pt-9,
.py-9 {
  padding-top: 7rem !important;
}

.pr-9,
.px-9 {
  padding-right: 7rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 7rem !important;
}

.pl-9,
.px-9 {
  padding-left: 7rem !important;
}

.p-10 {
  padding: 8rem !important;
}

.pt-10,
.py-10 {
  padding-top: 8rem !important;
}

.pr-10,
.px-10 {
  padding-right: 8rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 8rem !important;
}

.pl-10,
.px-10 {
  padding-left: 8rem !important;
}

.p-20 {
  padding: 16rem !important;
}

.pt-20,
.py-20 {
  padding-top: 16rem !important;
}

.pr-20,
.px-20 {
  padding-right: 16rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 16rem !important;
}

.pl-20,
.px-20 {
  padding-left: 16rem !important;
}

.p-30 {
  padding: 24rem !important;
}

.pt-30,
.py-30 {
  padding-top: 24rem !important;
}

.pr-30,
.px-30 {
  padding-right: 24rem !important;
}

.pb-30,
.py-30 {
  padding-bottom: 24rem !important;
}

.pl-30,
.px-30 {
  padding-left: 24rem !important;
}

.p-min {
  padding: 0.0625rem !important;
}

.pt-min,
.py-min {
  padding-top: 0.0625rem !important;
}

.pr-min,
.px-min {
  padding-right: 0.0625rem !important;
}

.pb-min,
.py-min {
  padding-bottom: 0.0625rem !important;
}

.pl-min,
.px-min {
  padding-left: 0.0625rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-ncard {
  margin: -1.25rem !important;
}

.mt-ncard,
.my-ncard {
  margin-top: -1.25rem !important;
}

.mr-ncard,
.mx-ncard {
  margin-right: -1.25rem !important;
}

.mb-ncard,
.my-ncard {
  margin-bottom: -1.25rem !important;
}

.ml-ncard,
.mx-ncard {
  margin-left: -1.25rem !important;
}

.m-n6 {
  margin: -4rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -4rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important;
}

.m-n7 {
  margin: -5rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -5rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important;
}

.m-n8 {
  margin: -6rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -6rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -6rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -6rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -6rem !important;
}

.m-n9 {
  margin: -7rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -7rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -7rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -7rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -7rem !important;
}

.m-n10 {
  margin: -8rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -8rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -8rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -8rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -8rem !important;
}

.m-n20 {
  margin: -16rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -16rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -16rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -16rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -16rem !important;
}

.m-n30 {
  margin: -24rem !important;
}

.mt-n30,
.my-n30 {
  margin-top: -24rem !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -24rem !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -24rem !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -24rem !important;
}

.m-nmin {
  margin: -0.0625rem !important;
}

.mt-nmin,
.my-nmin {
  margin-top: -0.0625rem !important;
}

.mr-nmin,
.mx-nmin {
  margin-right: -0.0625rem !important;
}

.mb-nmin,
.my-nmin {
  margin-bottom: -0.0625rem !important;
}

.ml-nmin,
.mx-nmin {
  margin-left: -0.0625rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-card {
    margin: 1.25rem !important;
  }
  .mt-sm-card,
  .my-sm-card {
    margin-top: 1.25rem !important;
  }
  .mr-sm-card,
  .mx-sm-card {
    margin-right: 1.25rem !important;
  }
  .mb-sm-card,
  .my-sm-card {
    margin-bottom: 1.25rem !important;
  }
  .ml-sm-card,
  .mx-sm-card {
    margin-left: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 4rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .m-sm-7 {
    margin: 5rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .m-sm-8 {
    margin: 6rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 6rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 6rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 6rem !important;
  }
  .m-sm-9 {
    margin: 7rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 7rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 7rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 7rem !important;
  }
  .m-sm-10 {
    margin: 8rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 8rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 8rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 8rem !important;
  }
  .m-sm-20 {
    margin: 16rem !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 16rem !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 16rem !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 16rem !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 16rem !important;
  }
  .m-sm-30 {
    margin: 24rem !important;
  }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 24rem !important;
  }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 24rem !important;
  }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 24rem !important;
  }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 24rem !important;
  }
  .m-sm-min {
    margin: 0.0625rem !important;
  }
  .mt-sm-min,
  .my-sm-min {
    margin-top: 0.0625rem !important;
  }
  .mr-sm-min,
  .mx-sm-min {
    margin-right: 0.0625rem !important;
  }
  .mb-sm-min,
  .my-sm-min {
    margin-bottom: 0.0625rem !important;
  }
  .ml-sm-min,
  .mx-sm-min {
    margin-left: 0.0625rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-card {
    padding: 1.25rem !important;
  }
  .pt-sm-card,
  .py-sm-card {
    padding-top: 1.25rem !important;
  }
  .pr-sm-card,
  .px-sm-card {
    padding-right: 1.25rem !important;
  }
  .pb-sm-card,
  .py-sm-card {
    padding-bottom: 1.25rem !important;
  }
  .pl-sm-card,
  .px-sm-card {
    padding-left: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 4rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important;
  }
  .p-sm-7 {
    padding: 5rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important;
  }
  .p-sm-8 {
    padding: 6rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 6rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 6rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 6rem !important;
  }
  .p-sm-9 {
    padding: 7rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 7rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 7rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 7rem !important;
  }
  .p-sm-10 {
    padding: 8rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 8rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 8rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 8rem !important;
  }
  .p-sm-20 {
    padding: 16rem !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 16rem !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 16rem !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 16rem !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 16rem !important;
  }
  .p-sm-30 {
    padding: 24rem !important;
  }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 24rem !important;
  }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 24rem !important;
  }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 24rem !important;
  }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 24rem !important;
  }
  .p-sm-min {
    padding: 0.0625rem !important;
  }
  .pt-sm-min,
  .py-sm-min {
    padding-top: 0.0625rem !important;
  }
  .pr-sm-min,
  .px-sm-min {
    padding-right: 0.0625rem !important;
  }
  .pb-sm-min,
  .py-sm-min {
    padding-bottom: 0.0625rem !important;
  }
  .pl-sm-min,
  .px-sm-min {
    padding-left: 0.0625rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-ncard {
    margin: -1.25rem !important;
  }
  .mt-sm-ncard,
  .my-sm-ncard {
    margin-top: -1.25rem !important;
  }
  .mr-sm-ncard,
  .mx-sm-ncard {
    margin-right: -1.25rem !important;
  }
  .mb-sm-ncard,
  .my-sm-ncard {
    margin-bottom: -1.25rem !important;
  }
  .ml-sm-ncard,
  .mx-sm-ncard {
    margin-left: -1.25rem !important;
  }
  .m-sm-n6 {
    margin: -4rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important;
  }
  .m-sm-n7 {
    margin: -5rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem !important;
  }
  .m-sm-n8 {
    margin: -6rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -6rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -6rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -6rem !important;
  }
  .m-sm-n9 {
    margin: -7rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -7rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -7rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -7rem !important;
  }
  .m-sm-n10 {
    margin: -8rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -8rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -8rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -8rem !important;
  }
  .m-sm-n20 {
    margin: -16rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -16rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -16rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -16rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -16rem !important;
  }
  .m-sm-n30 {
    margin: -24rem !important;
  }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -24rem !important;
  }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -24rem !important;
  }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -24rem !important;
  }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -24rem !important;
  }
  .m-sm-nmin {
    margin: -0.0625rem !important;
  }
  .mt-sm-nmin,
  .my-sm-nmin {
    margin-top: -0.0625rem !important;
  }
  .mr-sm-nmin,
  .mx-sm-nmin {
    margin-right: -0.0625rem !important;
  }
  .mb-sm-nmin,
  .my-sm-nmin {
    margin-bottom: -0.0625rem !important;
  }
  .ml-sm-nmin,
  .mx-sm-nmin {
    margin-left: -0.0625rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-card {
    margin: 1.25rem !important;
  }
  .mt-md-card,
  .my-md-card {
    margin-top: 1.25rem !important;
  }
  .mr-md-card,
  .mx-md-card {
    margin-right: 1.25rem !important;
  }
  .mb-md-card,
  .my-md-card {
    margin-bottom: 1.25rem !important;
  }
  .ml-md-card,
  .mx-md-card {
    margin-left: 1.25rem !important;
  }
  .m-md-6 {
    margin: 4rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important;
  }
  .m-md-7 {
    margin: 5rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important;
  }
  .m-md-8 {
    margin: 6rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 6rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 6rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 6rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 6rem !important;
  }
  .m-md-9 {
    margin: 7rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 7rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 7rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 7rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 7rem !important;
  }
  .m-md-10 {
    margin: 8rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 8rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 8rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 8rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 8rem !important;
  }
  .m-md-20 {
    margin: 16rem !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 16rem !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 16rem !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 16rem !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 16rem !important;
  }
  .m-md-30 {
    margin: 24rem !important;
  }
  .mt-md-30,
  .my-md-30 {
    margin-top: 24rem !important;
  }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 24rem !important;
  }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 24rem !important;
  }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 24rem !important;
  }
  .m-md-min {
    margin: 0.0625rem !important;
  }
  .mt-md-min,
  .my-md-min {
    margin-top: 0.0625rem !important;
  }
  .mr-md-min,
  .mx-md-min {
    margin-right: 0.0625rem !important;
  }
  .mb-md-min,
  .my-md-min {
    margin-bottom: 0.0625rem !important;
  }
  .ml-md-min,
  .mx-md-min {
    margin-left: 0.0625rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-card {
    padding: 1.25rem !important;
  }
  .pt-md-card,
  .py-md-card {
    padding-top: 1.25rem !important;
  }
  .pr-md-card,
  .px-md-card {
    padding-right: 1.25rem !important;
  }
  .pb-md-card,
  .py-md-card {
    padding-bottom: 1.25rem !important;
  }
  .pl-md-card,
  .px-md-card {
    padding-left: 1.25rem !important;
  }
  .p-md-6 {
    padding: 4rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important;
  }
  .p-md-7 {
    padding: 5rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important;
  }
  .p-md-8 {
    padding: 6rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 6rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 6rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 6rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 6rem !important;
  }
  .p-md-9 {
    padding: 7rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 7rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 7rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 7rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 7rem !important;
  }
  .p-md-10 {
    padding: 8rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 8rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 8rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 8rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 8rem !important;
  }
  .p-md-20 {
    padding: 16rem !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 16rem !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 16rem !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 16rem !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 16rem !important;
  }
  .p-md-30 {
    padding: 24rem !important;
  }
  .pt-md-30,
  .py-md-30 {
    padding-top: 24rem !important;
  }
  .pr-md-30,
  .px-md-30 {
    padding-right: 24rem !important;
  }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 24rem !important;
  }
  .pl-md-30,
  .px-md-30 {
    padding-left: 24rem !important;
  }
  .p-md-min {
    padding: 0.0625rem !important;
  }
  .pt-md-min,
  .py-md-min {
    padding-top: 0.0625rem !important;
  }
  .pr-md-min,
  .px-md-min {
    padding-right: 0.0625rem !important;
  }
  .pb-md-min,
  .py-md-min {
    padding-bottom: 0.0625rem !important;
  }
  .pl-md-min,
  .px-md-min {
    padding-left: 0.0625rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-ncard {
    margin: -1.25rem !important;
  }
  .mt-md-ncard,
  .my-md-ncard {
    margin-top: -1.25rem !important;
  }
  .mr-md-ncard,
  .mx-md-ncard {
    margin-right: -1.25rem !important;
  }
  .mb-md-ncard,
  .my-md-ncard {
    margin-bottom: -1.25rem !important;
  }
  .ml-md-ncard,
  .mx-md-ncard {
    margin-left: -1.25rem !important;
  }
  .m-md-n6 {
    margin: -4rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important;
  }
  .m-md-n7 {
    margin: -5rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem !important;
  }
  .m-md-n8 {
    margin: -6rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -6rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -6rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -6rem !important;
  }
  .m-md-n9 {
    margin: -7rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -7rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -7rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -7rem !important;
  }
  .m-md-n10 {
    margin: -8rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -8rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -8rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -8rem !important;
  }
  .m-md-n20 {
    margin: -16rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -16rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -16rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -16rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -16rem !important;
  }
  .m-md-n30 {
    margin: -24rem !important;
  }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -24rem !important;
  }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -24rem !important;
  }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -24rem !important;
  }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -24rem !important;
  }
  .m-md-nmin {
    margin: -0.0625rem !important;
  }
  .mt-md-nmin,
  .my-md-nmin {
    margin-top: -0.0625rem !important;
  }
  .mr-md-nmin,
  .mx-md-nmin {
    margin-right: -0.0625rem !important;
  }
  .mb-md-nmin,
  .my-md-nmin {
    margin-bottom: -0.0625rem !important;
  }
  .ml-md-nmin,
  .mx-md-nmin {
    margin-left: -0.0625rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-card {
    margin: 1.25rem !important;
  }
  .mt-lg-card,
  .my-lg-card {
    margin-top: 1.25rem !important;
  }
  .mr-lg-card,
  .mx-lg-card {
    margin-right: 1.25rem !important;
  }
  .mb-lg-card,
  .my-lg-card {
    margin-bottom: 1.25rem !important;
  }
  .ml-lg-card,
  .mx-lg-card {
    margin-left: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 4rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .m-lg-7 {
    margin: 5rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .m-lg-8 {
    margin: 6rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 6rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 6rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 6rem !important;
  }
  .m-lg-9 {
    margin: 7rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 7rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 7rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 7rem !important;
  }
  .m-lg-10 {
    margin: 8rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 8rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 8rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 8rem !important;
  }
  .m-lg-20 {
    margin: 16rem !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 16rem !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 16rem !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 16rem !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 16rem !important;
  }
  .m-lg-30 {
    margin: 24rem !important;
  }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 24rem !important;
  }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 24rem !important;
  }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 24rem !important;
  }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 24rem !important;
  }
  .m-lg-min {
    margin: 0.0625rem !important;
  }
  .mt-lg-min,
  .my-lg-min {
    margin-top: 0.0625rem !important;
  }
  .mr-lg-min,
  .mx-lg-min {
    margin-right: 0.0625rem !important;
  }
  .mb-lg-min,
  .my-lg-min {
    margin-bottom: 0.0625rem !important;
  }
  .ml-lg-min,
  .mx-lg-min {
    margin-left: 0.0625rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-card {
    padding: 1.25rem !important;
  }
  .pt-lg-card,
  .py-lg-card {
    padding-top: 1.25rem !important;
  }
  .pr-lg-card,
  .px-lg-card {
    padding-right: 1.25rem !important;
  }
  .pb-lg-card,
  .py-lg-card {
    padding-bottom: 1.25rem !important;
  }
  .pl-lg-card,
  .px-lg-card {
    padding-left: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 4rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important;
  }
  .p-lg-7 {
    padding: 5rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important;
  }
  .p-lg-8 {
    padding: 6rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 6rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 6rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 6rem !important;
  }
  .p-lg-9 {
    padding: 7rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 7rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 7rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 7rem !important;
  }
  .p-lg-10 {
    padding: 8rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 8rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 8rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 8rem !important;
  }
  .p-lg-20 {
    padding: 16rem !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 16rem !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 16rem !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 16rem !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 16rem !important;
  }
  .p-lg-30 {
    padding: 24rem !important;
  }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 24rem !important;
  }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 24rem !important;
  }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 24rem !important;
  }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 24rem !important;
  }
  .p-lg-min {
    padding: 0.0625rem !important;
  }
  .pt-lg-min,
  .py-lg-min {
    padding-top: 0.0625rem !important;
  }
  .pr-lg-min,
  .px-lg-min {
    padding-right: 0.0625rem !important;
  }
  .pb-lg-min,
  .py-lg-min {
    padding-bottom: 0.0625rem !important;
  }
  .pl-lg-min,
  .px-lg-min {
    padding-left: 0.0625rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-ncard {
    margin: -1.25rem !important;
  }
  .mt-lg-ncard,
  .my-lg-ncard {
    margin-top: -1.25rem !important;
  }
  .mr-lg-ncard,
  .mx-lg-ncard {
    margin-right: -1.25rem !important;
  }
  .mb-lg-ncard,
  .my-lg-ncard {
    margin-bottom: -1.25rem !important;
  }
  .ml-lg-ncard,
  .mx-lg-ncard {
    margin-left: -1.25rem !important;
  }
  .m-lg-n6 {
    margin: -4rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important;
  }
  .m-lg-n7 {
    margin: -5rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem !important;
  }
  .m-lg-n8 {
    margin: -6rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -6rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -6rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -6rem !important;
  }
  .m-lg-n9 {
    margin: -7rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -7rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -7rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -7rem !important;
  }
  .m-lg-n10 {
    margin: -8rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -8rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -8rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -8rem !important;
  }
  .m-lg-n20 {
    margin: -16rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -16rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -16rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -16rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -16rem !important;
  }
  .m-lg-n30 {
    margin: -24rem !important;
  }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -24rem !important;
  }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -24rem !important;
  }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -24rem !important;
  }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -24rem !important;
  }
  .m-lg-nmin {
    margin: -0.0625rem !important;
  }
  .mt-lg-nmin,
  .my-lg-nmin {
    margin-top: -0.0625rem !important;
  }
  .mr-lg-nmin,
  .mx-lg-nmin {
    margin-right: -0.0625rem !important;
  }
  .mb-lg-nmin,
  .my-lg-nmin {
    margin-bottom: -0.0625rem !important;
  }
  .ml-lg-nmin,
  .mx-lg-nmin {
    margin-left: -0.0625rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-card {
    margin: 1.25rem !important;
  }
  .mt-xl-card,
  .my-xl-card {
    margin-top: 1.25rem !important;
  }
  .mr-xl-card,
  .mx-xl-card {
    margin-right: 1.25rem !important;
  }
  .mb-xl-card,
  .my-xl-card {
    margin-bottom: 1.25rem !important;
  }
  .ml-xl-card,
  .mx-xl-card {
    margin-left: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 4rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .m-xl-7 {
    margin: 5rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .m-xl-8 {
    margin: 6rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 6rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 6rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 6rem !important;
  }
  .m-xl-9 {
    margin: 7rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 7rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 7rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 7rem !important;
  }
  .m-xl-10 {
    margin: 8rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 8rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 8rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 8rem !important;
  }
  .m-xl-20 {
    margin: 16rem !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 16rem !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 16rem !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 16rem !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 16rem !important;
  }
  .m-xl-30 {
    margin: 24rem !important;
  }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 24rem !important;
  }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 24rem !important;
  }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 24rem !important;
  }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 24rem !important;
  }
  .m-xl-min {
    margin: 0.0625rem !important;
  }
  .mt-xl-min,
  .my-xl-min {
    margin-top: 0.0625rem !important;
  }
  .mr-xl-min,
  .mx-xl-min {
    margin-right: 0.0625rem !important;
  }
  .mb-xl-min,
  .my-xl-min {
    margin-bottom: 0.0625rem !important;
  }
  .ml-xl-min,
  .mx-xl-min {
    margin-left: 0.0625rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-card {
    padding: 1.25rem !important;
  }
  .pt-xl-card,
  .py-xl-card {
    padding-top: 1.25rem !important;
  }
  .pr-xl-card,
  .px-xl-card {
    padding-right: 1.25rem !important;
  }
  .pb-xl-card,
  .py-xl-card {
    padding-bottom: 1.25rem !important;
  }
  .pl-xl-card,
  .px-xl-card {
    padding-left: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 4rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important;
  }
  .p-xl-7 {
    padding: 5rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important;
  }
  .p-xl-8 {
    padding: 6rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 6rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 6rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 6rem !important;
  }
  .p-xl-9 {
    padding: 7rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 7rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 7rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 7rem !important;
  }
  .p-xl-10 {
    padding: 8rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 8rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 8rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 8rem !important;
  }
  .p-xl-20 {
    padding: 16rem !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 16rem !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 16rem !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 16rem !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 16rem !important;
  }
  .p-xl-30 {
    padding: 24rem !important;
  }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 24rem !important;
  }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 24rem !important;
  }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 24rem !important;
  }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 24rem !important;
  }
  .p-xl-min {
    padding: 0.0625rem !important;
  }
  .pt-xl-min,
  .py-xl-min {
    padding-top: 0.0625rem !important;
  }
  .pr-xl-min,
  .px-xl-min {
    padding-right: 0.0625rem !important;
  }
  .pb-xl-min,
  .py-xl-min {
    padding-bottom: 0.0625rem !important;
  }
  .pl-xl-min,
  .px-xl-min {
    padding-left: 0.0625rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-ncard {
    margin: -1.25rem !important;
  }
  .mt-xl-ncard,
  .my-xl-ncard {
    margin-top: -1.25rem !important;
  }
  .mr-xl-ncard,
  .mx-xl-ncard {
    margin-right: -1.25rem !important;
  }
  .mb-xl-ncard,
  .my-xl-ncard {
    margin-bottom: -1.25rem !important;
  }
  .ml-xl-ncard,
  .mx-xl-ncard {
    margin-left: -1.25rem !important;
  }
  .m-xl-n6 {
    margin: -4rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important;
  }
  .m-xl-n7 {
    margin: -5rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem !important;
  }
  .m-xl-n8 {
    margin: -6rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -6rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -6rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -6rem !important;
  }
  .m-xl-n9 {
    margin: -7rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -7rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -7rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -7rem !important;
  }
  .m-xl-n10 {
    margin: -8rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -8rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -8rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -8rem !important;
  }
  .m-xl-n20 {
    margin: -16rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -16rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -16rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -16rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -16rem !important;
  }
  .m-xl-n30 {
    margin: -24rem !important;
  }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -24rem !important;
  }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -24rem !important;
  }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -24rem !important;
  }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -24rem !important;
  }
  .m-xl-nmin {
    margin: -0.0625rem !important;
  }
  .mt-xl-nmin,
  .my-xl-nmin {
    margin-top: -0.0625rem !important;
  }
  .mr-xl-nmin,
  .mx-xl-nmin {
    margin-right: -0.0625rem !important;
  }
  .mb-xl-nmin,
  .my-xl-nmin {
    margin-bottom: -0.0625rem !important;
  }
  .ml-xl-nmin,
  .mx-xl-nmin {
    margin-left: -0.0625rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify, .text-justify-not-xs,
.text-justify-not-sm,
.text-justify-not-md,
.text-justify-not-lg {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left, .text-left-not-xs,
.text-left-not-sm,
.text-left-not-md,
.text-left-not-lg {
  text-align: left !important;
}

.text-right, .text-right-not-xs,
.text-right-not-sm,
.text-right-not-md,
.text-right-not-lg {
  text-align: right !important;
}

.text-center, .text-center-not-xs,
.text-center-not-sm,
.text-center-not-md,
.text-center-not-lg {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold, .bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white, .card-inverse {
  color: #fff !important;
}

.text-success {
  color: #70b865 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #4c8f42 !important;
}

.text-info {
  color: #81d3eb !important;
}

a.text-info:hover, a.text-info:focus {
  color: #3fbce1 !important;
}

.text-warning {
  color: #f19e1f !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b8740c !important;
}

.text-danger {
  color: #a95c42 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #723e2d !important;
}

.text-light {
  color: #dee2e6 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #b2bcc5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #495057 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge, .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*
 * > ALERTS
 */
/*
 * > BACKGROUND COLORS
 */
/*
 * > BADGES
 */
/*
 * > BUTTONS
 */
/*
 * > CALLOUTS
 */
/*
* > COLOR CLASSES
*/
/*
* >> Background Color
*/
.bg-blue, .panel-primary .panel-heading, .bg-primary, .card-primary {
  color: #fff;
  background-color: #0c234b;
}

.bg-blue h1, .panel-primary .panel-heading h1, .bg-primary h1, .card-primary h1,
.bg-blue h2,
.panel-primary .panel-heading h2,
.bg-primary h2,
.card-primary h2,
.bg-blue h3,
.panel-primary .panel-heading h3,
.bg-primary h3,
.card-primary h3,
.bg-blue h4,
.panel-primary .panel-heading h4,
.bg-primary h4,
.card-primary h4,
.bg-blue h5,
.panel-primary .panel-heading h5,
.bg-primary h5,
.card-primary h5,
.bg-blue h6,
.panel-primary .panel-heading h6,
.bg-primary h6,
.card-primary h6,
.bg-blue .h1,
.panel-primary .panel-heading .h1,
.bg-primary .h1,
.card-primary .h1,
.bg-blue .text-size-h1,
.panel-primary .panel-heading .text-size-h1,
.bg-primary .text-size-h1,
.card-primary .text-size-h1,
.bg-blue .h2,
.panel-primary .panel-heading .h2,
.bg-primary .h2,
.card-primary .h2,
.bg-blue .text-size-h2,
.panel-primary .panel-heading .text-size-h2,
.bg-primary .text-size-h2,
.card-primary .text-size-h2,
.bg-blue .h3,
.panel-primary .panel-heading .h3,
.bg-primary .h3,
.card-primary .h3,
.bg-blue .text-size-h3,
.panel-primary .panel-heading .text-size-h3,
.bg-primary .text-size-h3,
.card-primary .text-size-h3,
.bg-blue .h4,
.panel-primary .panel-heading .h4,
.bg-primary .h4,
.card-primary .h4,
.bg-blue .text-size-h4,
.panel-primary .panel-heading .text-size-h4,
.bg-primary .text-size-h4,
.card-primary .text-size-h4,
.bg-blue .h5,
.panel-primary .panel-heading .h5,
.bg-primary .h5,
.card-primary .h5,
.bg-blue .text-size-h5,
.panel-primary .panel-heading .text-size-h5,
.bg-primary .text-size-h5,
.card-primary .text-size-h5,
.bg-blue .h6,
.panel-primary .panel-heading .h6,
.bg-primary .h6,
.card-primary .h6,
.bg-blue .text-size-h6,
.panel-primary .panel-heading .text-size-h6,
.bg-primary .text-size-h6,
.card-primary .text-size-h6 {
  color: inherit;
}

.bg-blue a:not(.btn):not(.nav-link), .panel-primary .panel-heading a:not(.btn):not(.nav-link), .bg-primary a:not(.btn):not(.nav-link), .card-primary a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-blue .ul-triangles li::before, .panel-primary .panel-heading .ul-triangles li::before, .bg-primary .ul-triangles li::before, .card-primary .ul-triangles li::before, .bg-blue ul.triangle li::before, .panel-primary .panel-heading ul.triangle li::before, .bg-primary ul.triangle li::before, .card-primary ul.triangle li::before {
  border-left-color: #fff;
}

.bg-red {
  color: #fff;
  background-color: #ab0520;
}

.bg-red h1,
.bg-red h2,
.bg-red h3,
.bg-red h4,
.bg-red h5,
.bg-red h6,
.bg-red .h1,
.bg-red .text-size-h1,
.bg-red .h2,
.bg-red .text-size-h2,
.bg-red .h3,
.bg-red .text-size-h3,
.bg-red .h4,
.bg-red .text-size-h4,
.bg-red .h5,
.bg-red .text-size-h5,
.bg-red .h6,
.bg-red .text-size-h6 {
  color: inherit;
}

.bg-red a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-red .ul-triangles li::before, .bg-red ul.triangle li::before {
  border-left-color: #fff;
}

.bg-white,
.panel, .well.well-hollow, .modal-bg-dark .modal-content {
  color: #000;
  background-color: #fff;
}

.bg-white h1, .panel h1, .well.well-hollow h1, .modal-bg-dark .modal-content h1,
.bg-white h2,
.panel h2,
.well.well-hollow h2,
.modal-bg-dark .modal-content h2,
.bg-white h3,
.panel h3,
.well.well-hollow h3,
.modal-bg-dark .modal-content h3,
.bg-white h4,
.panel h4,
.well.well-hollow h4,
.modal-bg-dark .modal-content h4,
.bg-white h5,
.panel h5,
.well.well-hollow h5,
.modal-bg-dark .modal-content h5,
.bg-white h6,
.panel h6,
.well.well-hollow h6,
.modal-bg-dark .modal-content h6,
.bg-white .h1,
.panel .h1,
.well.well-hollow .h1,
.modal-bg-dark .modal-content .h1,
.bg-white .text-size-h1,
.panel .text-size-h1,
.well.well-hollow .text-size-h1,
.modal-bg-dark .modal-content .text-size-h1,
.bg-white .h2,
.panel .h2,
.well.well-hollow .h2,
.modal-bg-dark .modal-content .h2,
.bg-white .text-size-h2,
.panel .text-size-h2,
.well.well-hollow .text-size-h2,
.modal-bg-dark .modal-content .text-size-h2,
.bg-white .h3,
.panel .h3,
.well.well-hollow .h3,
.modal-bg-dark .modal-content .h3,
.bg-white .text-size-h3,
.panel .text-size-h3,
.well.well-hollow .text-size-h3,
.modal-bg-dark .modal-content .text-size-h3,
.bg-white .h4,
.panel .h4,
.well.well-hollow .h4,
.modal-bg-dark .modal-content .h4,
.bg-white .text-size-h4,
.panel .text-size-h4,
.well.well-hollow .text-size-h4,
.modal-bg-dark .modal-content .text-size-h4,
.bg-white .h5,
.panel .h5,
.well.well-hollow .h5,
.modal-bg-dark .modal-content .h5,
.bg-white .text-size-h5,
.panel .text-size-h5,
.well.well-hollow .text-size-h5,
.modal-bg-dark .modal-content .text-size-h5,
.bg-white .h6,
.panel .h6,
.well.well-hollow .h6,
.modal-bg-dark .modal-content .h6,
.bg-white .text-size-h6,
.panel .text-size-h6,
.well.well-hollow .text-size-h6,
.modal-bg-dark .modal-content .text-size-h6 {
  color: inherit;
}

.bg-white .ul-triangles li::before, .panel .ul-triangles li::before, .well.well-hollow .ul-triangles li::before, .modal-bg-dark .modal-content .ul-triangles li::before, .bg-white ul.triangle li::before, .panel ul.triangle li::before, .well.well-hollow ul.triangle li::before, .modal-bg-dark .modal-content ul.triangle li::before {
  border-left-color: #000;
}

.bg-bloom {
  color: #000;
  background-color: #ef4056;
}

.bg-bloom h1,
.bg-bloom h2,
.bg-bloom h3,
.bg-bloom h4,
.bg-bloom h5,
.bg-bloom h6,
.bg-bloom .h1,
.bg-bloom .text-size-h1,
.bg-bloom .h2,
.bg-bloom .text-size-h2,
.bg-bloom .h3,
.bg-bloom .text-size-h3,
.bg-bloom .h4,
.bg-bloom .text-size-h4,
.bg-bloom .h5,
.bg-bloom .text-size-h5,
.bg-bloom .h6,
.bg-bloom .text-size-h6 {
  color: inherit;
}

.bg-bloom .ul-triangles li::before, .bg-bloom ul.triangle li::before {
  border-left-color: #000;
}

.bg-bloom a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-chili {
  color: #fff;
  background-color: #8b0015;
}

.bg-chili h1,
.bg-chili h2,
.bg-chili h3,
.bg-chili h4,
.bg-chili h5,
.bg-chili h6,
.bg-chili .h1,
.bg-chili .text-size-h1,
.bg-chili .h2,
.bg-chili .text-size-h2,
.bg-chili .h3,
.bg-chili .text-size-h3,
.bg-chili .h4,
.bg-chili .text-size-h4,
.bg-chili .h5,
.bg-chili .text-size-h5,
.bg-chili .h6,
.bg-chili .text-size-h6 {
  color: inherit;
}

.bg-chili a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-chili .ul-triangles li::before, .bg-chili ul.triangle li::before {
  border-left-color: #fff;
}

.bg-sky {
  color: #000;
  background-color: #81d3eb;
}

.bg-sky h1,
.bg-sky h2,
.bg-sky h3,
.bg-sky h4,
.bg-sky h5,
.bg-sky h6,
.bg-sky .h1,
.bg-sky .text-size-h1,
.bg-sky .h2,
.bg-sky .text-size-h2,
.bg-sky .h3,
.bg-sky .text-size-h3,
.bg-sky .h4,
.bg-sky .text-size-h4,
.bg-sky .h5,
.bg-sky .text-size-h5,
.bg-sky .h6,
.bg-sky .text-size-h6 {
  color: inherit;
}

.bg-sky .ul-triangles li::before, .bg-sky ul.triangle li::before {
  border-left-color: #000;
}

.bg-sky a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-oasis {
  color: #000;
  background-color: #378dbd;
}

.bg-oasis h1,
.bg-oasis h2,
.bg-oasis h3,
.bg-oasis h4,
.bg-oasis h5,
.bg-oasis h6,
.bg-oasis .h1,
.bg-oasis .text-size-h1,
.bg-oasis .h2,
.bg-oasis .text-size-h2,
.bg-oasis .h3,
.bg-oasis .text-size-h3,
.bg-oasis .h4,
.bg-oasis .text-size-h4,
.bg-oasis .h5,
.bg-oasis .text-size-h5,
.bg-oasis .h6,
.bg-oasis .text-size-h6 {
  color: inherit;
}

.bg-oasis .ul-triangles li::before, .bg-oasis ul.triangle li::before {
  border-left-color: #000;
}

.bg-oasis a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-azurite {
  color: #fff;
  background-color: #1e5288;
}

.bg-azurite h1,
.bg-azurite h2,
.bg-azurite h3,
.bg-azurite h4,
.bg-azurite h5,
.bg-azurite h6,
.bg-azurite .h1,
.bg-azurite .text-size-h1,
.bg-azurite .h2,
.bg-azurite .text-size-h2,
.bg-azurite .h3,
.bg-azurite .text-size-h3,
.bg-azurite .h4,
.bg-azurite .text-size-h4,
.bg-azurite .h5,
.bg-azurite .text-size-h5,
.bg-azurite .h6,
.bg-azurite .text-size-h6 {
  color: inherit;
}

.bg-azurite a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-azurite .ul-triangles li::before, .bg-azurite ul.triangle li::before {
  border-left-color: #fff;
}

.bg-midnight {
  color: #fff;
  background-color: #001c48;
}

.bg-midnight h1,
.bg-midnight h2,
.bg-midnight h3,
.bg-midnight h4,
.bg-midnight h5,
.bg-midnight h6,
.bg-midnight .h1,
.bg-midnight .text-size-h1,
.bg-midnight .h2,
.bg-midnight .text-size-h2,
.bg-midnight .h3,
.bg-midnight .text-size-h3,
.bg-midnight .h4,
.bg-midnight .text-size-h4,
.bg-midnight .h5,
.bg-midnight .text-size-h5,
.bg-midnight .h6,
.bg-midnight .text-size-h6 {
  color: inherit;
}

.bg-midnight a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-midnight .ul-triangles li::before, .bg-midnight ul.triangle li::before {
  border-left-color: #fff;
}

.bg-cool-gray {
  color: #000;
  background-color: #e2e9eb;
}

.bg-cool-gray h1,
.bg-cool-gray h2,
.bg-cool-gray h3,
.bg-cool-gray h4,
.bg-cool-gray h5,
.bg-cool-gray h6,
.bg-cool-gray .h1,
.bg-cool-gray .text-size-h1,
.bg-cool-gray .h2,
.bg-cool-gray .text-size-h2,
.bg-cool-gray .h3,
.bg-cool-gray .text-size-h3,
.bg-cool-gray .h4,
.bg-cool-gray .text-size-h4,
.bg-cool-gray .h5,
.bg-cool-gray .text-size-h5,
.bg-cool-gray .h6,
.bg-cool-gray .text-size-h6 {
  color: inherit;
}

.bg-cool-gray .ul-triangles li::before, .bg-cool-gray ul.triangle li::before {
  border-left-color: #000;
}

.bg-warm-gray {
  color: #000;
  background-color: #f4ede5;
}

.bg-warm-gray h1,
.bg-warm-gray h2,
.bg-warm-gray h3,
.bg-warm-gray h4,
.bg-warm-gray h5,
.bg-warm-gray h6,
.bg-warm-gray .h1,
.bg-warm-gray .text-size-h1,
.bg-warm-gray .h2,
.bg-warm-gray .text-size-h2,
.bg-warm-gray .h3,
.bg-warm-gray .text-size-h3,
.bg-warm-gray .h4,
.bg-warm-gray .text-size-h4,
.bg-warm-gray .h5,
.bg-warm-gray .text-size-h5,
.bg-warm-gray .h6,
.bg-warm-gray .text-size-h6 {
  color: inherit;
}

.bg-warm-gray .ul-triangles li::before, .bg-warm-gray ul.triangle li::before {
  border-left-color: #000;
}

.bg-leaf {
  color: #000;
  background-color: #70b865;
}

.bg-leaf h1,
.bg-leaf h2,
.bg-leaf h3,
.bg-leaf h4,
.bg-leaf h5,
.bg-leaf h6,
.bg-leaf .h1,
.bg-leaf .text-size-h1,
.bg-leaf .h2,
.bg-leaf .text-size-h2,
.bg-leaf .h3,
.bg-leaf .text-size-h3,
.bg-leaf .h4,
.bg-leaf .text-size-h4,
.bg-leaf .h5,
.bg-leaf .text-size-h5,
.bg-leaf .h6,
.bg-leaf .text-size-h6 {
  color: inherit;
}

.bg-leaf .ul-triangles li::before, .bg-leaf ul.triangle li::before {
  border-left-color: #000;
}

.bg-leaf a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-river {
  color: #fff;
  background-color: #007d84;
}

.bg-river h1,
.bg-river h2,
.bg-river h3,
.bg-river h4,
.bg-river h5,
.bg-river h6,
.bg-river .h1,
.bg-river .text-size-h1,
.bg-river .h2,
.bg-river .text-size-h2,
.bg-river .h3,
.bg-river .text-size-h3,
.bg-river .h4,
.bg-river .text-size-h4,
.bg-river .h5,
.bg-river .text-size-h5,
.bg-river .h6,
.bg-river .text-size-h6 {
  color: inherit;
}

.bg-river a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-river .ul-triangles li::before, .bg-river ul.triangle li::before {
  border-left-color: #fff;
}

.bg-silver {
  color: #000;
  background-color: #9eabae;
}

.bg-silver h1,
.bg-silver h2,
.bg-silver h3,
.bg-silver h4,
.bg-silver h5,
.bg-silver h6,
.bg-silver .h1,
.bg-silver .text-size-h1,
.bg-silver .h2,
.bg-silver .text-size-h2,
.bg-silver .h3,
.bg-silver .text-size-h3,
.bg-silver .h4,
.bg-silver .text-size-h4,
.bg-silver .h5,
.bg-silver .text-size-h5,
.bg-silver .h6,
.bg-silver .text-size-h6 {
  color: inherit;
}

.bg-silver .ul-triangles li::before, .bg-silver ul.triangle li::before {
  border-left-color: #000;
}

.bg-silver a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-mesa {
  color: #fff;
  background-color: #a95c42;
}

.bg-mesa h1,
.bg-mesa h2,
.bg-mesa h3,
.bg-mesa h4,
.bg-mesa h5,
.bg-mesa h6,
.bg-mesa .h1,
.bg-mesa .text-size-h1,
.bg-mesa .h2,
.bg-mesa .text-size-h2,
.bg-mesa .h3,
.bg-mesa .text-size-h3,
.bg-mesa .h4,
.bg-mesa .text-size-h4,
.bg-mesa .h5,
.bg-mesa .text-size-h5,
.bg-mesa .h6,
.bg-mesa .text-size-h6 {
  color: inherit;
}

.bg-mesa a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-mesa .ul-triangles li::before, .bg-mesa ul.triangle li::before {
  border-left-color: #fff;
}

.bg-ash {
  color: #fff;
  background-color: #403635;
}

.bg-ash h1,
.bg-ash h2,
.bg-ash h3,
.bg-ash h4,
.bg-ash h5,
.bg-ash h6,
.bg-ash .h1,
.bg-ash .text-size-h1,
.bg-ash .h2,
.bg-ash .text-size-h2,
.bg-ash .h3,
.bg-ash .text-size-h3,
.bg-ash .h4,
.bg-ash .text-size-h4,
.bg-ash .h5,
.bg-ash .text-size-h5,
.bg-ash .h6,
.bg-ash .text-size-h6 {
  color: inherit;
}

.bg-ash a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-ash .ul-triangles li::before, .bg-ash ul.triangle li::before {
  border-left-color: #fff;
}

.bg-sage {
  color: #fff;
  background-color: #4a634e;
}

.bg-sage h1,
.bg-sage h2,
.bg-sage h3,
.bg-sage h4,
.bg-sage h5,
.bg-sage h6,
.bg-sage .h1,
.bg-sage .text-size-h1,
.bg-sage .h2,
.bg-sage .text-size-h2,
.bg-sage .h3,
.bg-sage .text-size-h3,
.bg-sage .h4,
.bg-sage .text-size-h4,
.bg-sage .h5,
.bg-sage .text-size-h5,
.bg-sage .h6,
.bg-sage .text-size-h6 {
  color: inherit;
}

.bg-sage a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-sage .ul-triangles li::before, .bg-sage ul.triangle li::before {
  border-left-color: #fff;
}

.bg-black {
  color: #fff;
  background-color: #000;
}

.bg-black h1,
.bg-black h2,
.bg-black h3,
.bg-black h4,
.bg-black h5,
.bg-black h6,
.bg-black .h1,
.bg-black .text-size-h1,
.bg-black .h2,
.bg-black .text-size-h2,
.bg-black .h3,
.bg-black .text-size-h3,
.bg-black .h4,
.bg-black .text-size-h4,
.bg-black .h5,
.bg-black .text-size-h5,
.bg-black .h6,
.bg-black .text-size-h6 {
  color: inherit;
}

.bg-black a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-black .ul-triangles li::before, .bg-black ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-blue {
  color: #fff;
  background-color: rgba(12, 35, 75, 0.8);
}

.bg-transparent-blue h1,
.bg-transparent-blue h2,
.bg-transparent-blue h3,
.bg-transparent-blue h4,
.bg-transparent-blue h5,
.bg-transparent-blue h6,
.bg-transparent-blue .h1,
.bg-transparent-blue .text-size-h1,
.bg-transparent-blue .h2,
.bg-transparent-blue .text-size-h2,
.bg-transparent-blue .h3,
.bg-transparent-blue .text-size-h3,
.bg-transparent-blue .h4,
.bg-transparent-blue .text-size-h4,
.bg-transparent-blue .h5,
.bg-transparent-blue .text-size-h5,
.bg-transparent-blue .h6,
.bg-transparent-blue .text-size-h6 {
  color: inherit;
}

.bg-transparent-blue a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-blue .ul-triangles li::before, .bg-transparent-blue ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-red {
  color: #fff;
  background-color: rgba(171, 5, 32, 0.8);
}

.bg-transparent-red h1,
.bg-transparent-red h2,
.bg-transparent-red h3,
.bg-transparent-red h4,
.bg-transparent-red h5,
.bg-transparent-red h6,
.bg-transparent-red .h1,
.bg-transparent-red .text-size-h1,
.bg-transparent-red .h2,
.bg-transparent-red .text-size-h2,
.bg-transparent-red .h3,
.bg-transparent-red .text-size-h3,
.bg-transparent-red .h4,
.bg-transparent-red .text-size-h4,
.bg-transparent-red .h5,
.bg-transparent-red .text-size-h5,
.bg-transparent-red .h6,
.bg-transparent-red .text-size-h6 {
  color: inherit;
}

.bg-transparent-red a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-red .ul-triangles li::before, .bg-transparent-red ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-white {
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-transparent-white h1,
.bg-transparent-white h2,
.bg-transparent-white h3,
.bg-transparent-white h4,
.bg-transparent-white h5,
.bg-transparent-white h6,
.bg-transparent-white .h1,
.bg-transparent-white .text-size-h1,
.bg-transparent-white .h2,
.bg-transparent-white .text-size-h2,
.bg-transparent-white .h3,
.bg-transparent-white .text-size-h3,
.bg-transparent-white .h4,
.bg-transparent-white .text-size-h4,
.bg-transparent-white .h5,
.bg-transparent-white .text-size-h5,
.bg-transparent-white .h6,
.bg-transparent-white .text-size-h6 {
  color: inherit;
}

.bg-transparent-white .ul-triangles li::before, .bg-transparent-white ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-bloom {
  color: #000;
  background-color: rgba(239, 64, 86, 0.8);
}

.bg-transparent-bloom h1,
.bg-transparent-bloom h2,
.bg-transparent-bloom h3,
.bg-transparent-bloom h4,
.bg-transparent-bloom h5,
.bg-transparent-bloom h6,
.bg-transparent-bloom .h1,
.bg-transparent-bloom .text-size-h1,
.bg-transparent-bloom .h2,
.bg-transparent-bloom .text-size-h2,
.bg-transparent-bloom .h3,
.bg-transparent-bloom .text-size-h3,
.bg-transparent-bloom .h4,
.bg-transparent-bloom .text-size-h4,
.bg-transparent-bloom .h5,
.bg-transparent-bloom .text-size-h5,
.bg-transparent-bloom .h6,
.bg-transparent-bloom .text-size-h6 {
  color: inherit;
}

.bg-transparent-bloom .ul-triangles li::before, .bg-transparent-bloom ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-bloom a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-chili {
  color: #fff;
  background-color: rgba(139, 0, 21, 0.8);
}

.bg-transparent-chili h1,
.bg-transparent-chili h2,
.bg-transparent-chili h3,
.bg-transparent-chili h4,
.bg-transparent-chili h5,
.bg-transparent-chili h6,
.bg-transparent-chili .h1,
.bg-transparent-chili .text-size-h1,
.bg-transparent-chili .h2,
.bg-transparent-chili .text-size-h2,
.bg-transparent-chili .h3,
.bg-transparent-chili .text-size-h3,
.bg-transparent-chili .h4,
.bg-transparent-chili .text-size-h4,
.bg-transparent-chili .h5,
.bg-transparent-chili .text-size-h5,
.bg-transparent-chili .h6,
.bg-transparent-chili .text-size-h6 {
  color: inherit;
}

.bg-transparent-chili a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-chili .ul-triangles li::before, .bg-transparent-chili ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-sky {
  color: #000;
  background-color: rgba(129, 211, 235, 0.8);
}

.bg-transparent-sky h1,
.bg-transparent-sky h2,
.bg-transparent-sky h3,
.bg-transparent-sky h4,
.bg-transparent-sky h5,
.bg-transparent-sky h6,
.bg-transparent-sky .h1,
.bg-transparent-sky .text-size-h1,
.bg-transparent-sky .h2,
.bg-transparent-sky .text-size-h2,
.bg-transparent-sky .h3,
.bg-transparent-sky .text-size-h3,
.bg-transparent-sky .h4,
.bg-transparent-sky .text-size-h4,
.bg-transparent-sky .h5,
.bg-transparent-sky .text-size-h5,
.bg-transparent-sky .h6,
.bg-transparent-sky .text-size-h6 {
  color: inherit;
}

.bg-transparent-sky .ul-triangles li::before, .bg-transparent-sky ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-sky a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-oasis {
  color: #000;
  background-color: rgba(55, 141, 189, 0.8);
}

.bg-transparent-oasis h1,
.bg-transparent-oasis h2,
.bg-transparent-oasis h3,
.bg-transparent-oasis h4,
.bg-transparent-oasis h5,
.bg-transparent-oasis h6,
.bg-transparent-oasis .h1,
.bg-transparent-oasis .text-size-h1,
.bg-transparent-oasis .h2,
.bg-transparent-oasis .text-size-h2,
.bg-transparent-oasis .h3,
.bg-transparent-oasis .text-size-h3,
.bg-transparent-oasis .h4,
.bg-transparent-oasis .text-size-h4,
.bg-transparent-oasis .h5,
.bg-transparent-oasis .text-size-h5,
.bg-transparent-oasis .h6,
.bg-transparent-oasis .text-size-h6 {
  color: inherit;
}

.bg-transparent-oasis .ul-triangles li::before, .bg-transparent-oasis ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-oasis a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-azurite {
  color: #fff;
  background-color: rgba(30, 82, 136, 0.8);
}

.bg-transparent-azurite h1,
.bg-transparent-azurite h2,
.bg-transparent-azurite h3,
.bg-transparent-azurite h4,
.bg-transparent-azurite h5,
.bg-transparent-azurite h6,
.bg-transparent-azurite .h1,
.bg-transparent-azurite .text-size-h1,
.bg-transparent-azurite .h2,
.bg-transparent-azurite .text-size-h2,
.bg-transparent-azurite .h3,
.bg-transparent-azurite .text-size-h3,
.bg-transparent-azurite .h4,
.bg-transparent-azurite .text-size-h4,
.bg-transparent-azurite .h5,
.bg-transparent-azurite .text-size-h5,
.bg-transparent-azurite .h6,
.bg-transparent-azurite .text-size-h6 {
  color: inherit;
}

.bg-transparent-azurite a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-azurite .ul-triangles li::before, .bg-transparent-azurite ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-midnight {
  color: #fff;
  background-color: rgba(0, 28, 72, 0.8);
}

.bg-transparent-midnight h1,
.bg-transparent-midnight h2,
.bg-transparent-midnight h3,
.bg-transparent-midnight h4,
.bg-transparent-midnight h5,
.bg-transparent-midnight h6,
.bg-transparent-midnight .h1,
.bg-transparent-midnight .text-size-h1,
.bg-transparent-midnight .h2,
.bg-transparent-midnight .text-size-h2,
.bg-transparent-midnight .h3,
.bg-transparent-midnight .text-size-h3,
.bg-transparent-midnight .h4,
.bg-transparent-midnight .text-size-h4,
.bg-transparent-midnight .h5,
.bg-transparent-midnight .text-size-h5,
.bg-transparent-midnight .h6,
.bg-transparent-midnight .text-size-h6 {
  color: inherit;
}

.bg-transparent-midnight a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-midnight .ul-triangles li::before, .bg-transparent-midnight ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-cool-gray {
  color: #000;
  background-color: rgba(226, 233, 235, 0.8);
}

.bg-transparent-cool-gray h1,
.bg-transparent-cool-gray h2,
.bg-transparent-cool-gray h3,
.bg-transparent-cool-gray h4,
.bg-transparent-cool-gray h5,
.bg-transparent-cool-gray h6,
.bg-transparent-cool-gray .h1,
.bg-transparent-cool-gray .text-size-h1,
.bg-transparent-cool-gray .h2,
.bg-transparent-cool-gray .text-size-h2,
.bg-transparent-cool-gray .h3,
.bg-transparent-cool-gray .text-size-h3,
.bg-transparent-cool-gray .h4,
.bg-transparent-cool-gray .text-size-h4,
.bg-transparent-cool-gray .h5,
.bg-transparent-cool-gray .text-size-h5,
.bg-transparent-cool-gray .h6,
.bg-transparent-cool-gray .text-size-h6 {
  color: inherit;
}

.bg-transparent-cool-gray .ul-triangles li::before, .bg-transparent-cool-gray ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-warm-gray {
  color: #000;
  background-color: rgba(244, 237, 229, 0.8);
}

.bg-transparent-warm-gray h1,
.bg-transparent-warm-gray h2,
.bg-transparent-warm-gray h3,
.bg-transparent-warm-gray h4,
.bg-transparent-warm-gray h5,
.bg-transparent-warm-gray h6,
.bg-transparent-warm-gray .h1,
.bg-transparent-warm-gray .text-size-h1,
.bg-transparent-warm-gray .h2,
.bg-transparent-warm-gray .text-size-h2,
.bg-transparent-warm-gray .h3,
.bg-transparent-warm-gray .text-size-h3,
.bg-transparent-warm-gray .h4,
.bg-transparent-warm-gray .text-size-h4,
.bg-transparent-warm-gray .h5,
.bg-transparent-warm-gray .text-size-h5,
.bg-transparent-warm-gray .h6,
.bg-transparent-warm-gray .text-size-h6 {
  color: inherit;
}

.bg-transparent-warm-gray .ul-triangles li::before, .bg-transparent-warm-gray ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-leaf {
  color: #000;
  background-color: rgba(112, 184, 101, 0.8);
}

.bg-transparent-leaf h1,
.bg-transparent-leaf h2,
.bg-transparent-leaf h3,
.bg-transparent-leaf h4,
.bg-transparent-leaf h5,
.bg-transparent-leaf h6,
.bg-transparent-leaf .h1,
.bg-transparent-leaf .text-size-h1,
.bg-transparent-leaf .h2,
.bg-transparent-leaf .text-size-h2,
.bg-transparent-leaf .h3,
.bg-transparent-leaf .text-size-h3,
.bg-transparent-leaf .h4,
.bg-transparent-leaf .text-size-h4,
.bg-transparent-leaf .h5,
.bg-transparent-leaf .text-size-h5,
.bg-transparent-leaf .h6,
.bg-transparent-leaf .text-size-h6 {
  color: inherit;
}

.bg-transparent-leaf .ul-triangles li::before, .bg-transparent-leaf ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-leaf a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-river {
  color: #fff;
  background-color: rgba(0, 125, 132, 0.8);
}

.bg-transparent-river h1,
.bg-transparent-river h2,
.bg-transparent-river h3,
.bg-transparent-river h4,
.bg-transparent-river h5,
.bg-transparent-river h6,
.bg-transparent-river .h1,
.bg-transparent-river .text-size-h1,
.bg-transparent-river .h2,
.bg-transparent-river .text-size-h2,
.bg-transparent-river .h3,
.bg-transparent-river .text-size-h3,
.bg-transparent-river .h4,
.bg-transparent-river .text-size-h4,
.bg-transparent-river .h5,
.bg-transparent-river .text-size-h5,
.bg-transparent-river .h6,
.bg-transparent-river .text-size-h6 {
  color: inherit;
}

.bg-transparent-river a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-river .ul-triangles li::before, .bg-transparent-river ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-silver {
  color: #000;
  background-color: rgba(158, 171, 174, 0.8);
}

.bg-transparent-silver h1,
.bg-transparent-silver h2,
.bg-transparent-silver h3,
.bg-transparent-silver h4,
.bg-transparent-silver h5,
.bg-transparent-silver h6,
.bg-transparent-silver .h1,
.bg-transparent-silver .text-size-h1,
.bg-transparent-silver .h2,
.bg-transparent-silver .text-size-h2,
.bg-transparent-silver .h3,
.bg-transparent-silver .text-size-h3,
.bg-transparent-silver .h4,
.bg-transparent-silver .text-size-h4,
.bg-transparent-silver .h5,
.bg-transparent-silver .text-size-h5,
.bg-transparent-silver .h6,
.bg-transparent-silver .text-size-h6 {
  color: inherit;
}

.bg-transparent-silver .ul-triangles li::before, .bg-transparent-silver ul.triangle li::before {
  border-left-color: #000;
}

.bg-transparent-silver a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-mesa {
  color: #fff;
  background-color: rgba(169, 92, 66, 0.8);
}

.bg-transparent-mesa h1,
.bg-transparent-mesa h2,
.bg-transparent-mesa h3,
.bg-transparent-mesa h4,
.bg-transparent-mesa h5,
.bg-transparent-mesa h6,
.bg-transparent-mesa .h1,
.bg-transparent-mesa .text-size-h1,
.bg-transparent-mesa .h2,
.bg-transparent-mesa .text-size-h2,
.bg-transparent-mesa .h3,
.bg-transparent-mesa .text-size-h3,
.bg-transparent-mesa .h4,
.bg-transparent-mesa .text-size-h4,
.bg-transparent-mesa .h5,
.bg-transparent-mesa .text-size-h5,
.bg-transparent-mesa .h6,
.bg-transparent-mesa .text-size-h6 {
  color: inherit;
}

.bg-transparent-mesa a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-mesa .ul-triangles li::before, .bg-transparent-mesa ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-ash {
  color: #fff;
  background-color: rgba(64, 54, 53, 0.8);
}

.bg-transparent-ash h1,
.bg-transparent-ash h2,
.bg-transparent-ash h3,
.bg-transparent-ash h4,
.bg-transparent-ash h5,
.bg-transparent-ash h6,
.bg-transparent-ash .h1,
.bg-transparent-ash .text-size-h1,
.bg-transparent-ash .h2,
.bg-transparent-ash .text-size-h2,
.bg-transparent-ash .h3,
.bg-transparent-ash .text-size-h3,
.bg-transparent-ash .h4,
.bg-transparent-ash .text-size-h4,
.bg-transparent-ash .h5,
.bg-transparent-ash .text-size-h5,
.bg-transparent-ash .h6,
.bg-transparent-ash .text-size-h6 {
  color: inherit;
}

.bg-transparent-ash a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-ash .ul-triangles li::before, .bg-transparent-ash ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-sage {
  color: #fff;
  background-color: rgba(74, 99, 78, 0.8);
}

.bg-transparent-sage h1,
.bg-transparent-sage h2,
.bg-transparent-sage h3,
.bg-transparent-sage h4,
.bg-transparent-sage h5,
.bg-transparent-sage h6,
.bg-transparent-sage .h1,
.bg-transparent-sage .text-size-h1,
.bg-transparent-sage .h2,
.bg-transparent-sage .text-size-h2,
.bg-transparent-sage .h3,
.bg-transparent-sage .text-size-h3,
.bg-transparent-sage .h4,
.bg-transparent-sage .text-size-h4,
.bg-transparent-sage .h5,
.bg-transparent-sage .text-size-h5,
.bg-transparent-sage .h6,
.bg-transparent-sage .text-size-h6 {
  color: inherit;
}

.bg-transparent-sage a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-sage .ul-triangles li::before, .bg-transparent-sage ul.triangle li::before {
  border-left-color: #fff;
}

.bg-transparent-black {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-transparent-black h1,
.bg-transparent-black h2,
.bg-transparent-black h3,
.bg-transparent-black h4,
.bg-transparent-black h5,
.bg-transparent-black h6,
.bg-transparent-black .h1,
.bg-transparent-black .text-size-h1,
.bg-transparent-black .h2,
.bg-transparent-black .text-size-h2,
.bg-transparent-black .h3,
.bg-transparent-black .text-size-h3,
.bg-transparent-black .h4,
.bg-transparent-black .text-size-h4,
.bg-transparent-black .h5,
.bg-transparent-black .text-size-h5,
.bg-transparent-black .h6,
.bg-transparent-black .text-size-h6 {
  color: inherit;
}

.bg-transparent-black a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-transparent-black .ul-triangles li::before, .bg-transparent-black ul.triangle li::before {
  border-left-color: #fff;
}

.bg-gradient-blue {
  background-image: linear-gradient(180deg, transparent, #0c234b);
}

.bg-gradient-red {
  background-image: linear-gradient(180deg, transparent, #ab0520);
}

.bg-gradient-white {
  background-image: linear-gradient(180deg, transparent, #fff);
}

.bg-gradient-bloom {
  background-image: linear-gradient(180deg, transparent, #ef4056);
}

.bg-gradient-chili {
  background-image: linear-gradient(180deg, transparent, #8b0015);
}

.bg-gradient-sky {
  background-image: linear-gradient(180deg, transparent, #81d3eb);
}

.bg-gradient-oasis {
  background-image: linear-gradient(180deg, transparent, #378dbd);
}

.bg-gradient-azurite {
  background-image: linear-gradient(180deg, transparent, #1e5288);
}

.bg-gradient-midnight {
  background-image: linear-gradient(180deg, transparent, #001c48);
}

.bg-gradient-cool-gray {
  background-image: linear-gradient(180deg, transparent, #e2e9eb);
}

.bg-gradient-warm-gray {
  background-image: linear-gradient(180deg, transparent, #f4ede5);
}

.bg-gradient-leaf {
  background-image: linear-gradient(180deg, transparent, #70b865);
}

.bg-gradient-river {
  background-image: linear-gradient(180deg, transparent, #007d84);
}

.bg-gradient-silver {
  background-image: linear-gradient(180deg, transparent, #9eabae);
}

.bg-gradient-mesa {
  background-image: linear-gradient(180deg, transparent, #a95c42);
}

.bg-gradient-ash {
  background-image: linear-gradient(180deg, transparent, #403635);
}

.bg-gradient-sage {
  background-image: linear-gradient(180deg, transparent, #4a634e);
}

.bg-gradient-black {
  background-image: linear-gradient(180deg, transparent, #000);
}

.bg-success, .panel-success .panel-heading, .card-success {
  color: #000;
  background-color: #70b865;
}

.bg-success h1, .panel-success .panel-heading h1, .card-success h1,
.bg-success h2,
.panel-success .panel-heading h2,
.card-success h2,
.bg-success h3,
.panel-success .panel-heading h3,
.card-success h3,
.bg-success h4,
.panel-success .panel-heading h4,
.card-success h4,
.bg-success h5,
.panel-success .panel-heading h5,
.card-success h5,
.bg-success h6,
.panel-success .panel-heading h6,
.card-success h6,
.bg-success .h1,
.panel-success .panel-heading .h1,
.card-success .h1,
.bg-success .text-size-h1,
.panel-success .panel-heading .text-size-h1,
.card-success .text-size-h1,
.bg-success .h2,
.panel-success .panel-heading .h2,
.card-success .h2,
.bg-success .text-size-h2,
.panel-success .panel-heading .text-size-h2,
.card-success .text-size-h2,
.bg-success .h3,
.panel-success .panel-heading .h3,
.card-success .h3,
.bg-success .text-size-h3,
.panel-success .panel-heading .text-size-h3,
.card-success .text-size-h3,
.bg-success .h4,
.panel-success .panel-heading .h4,
.card-success .h4,
.bg-success .text-size-h4,
.panel-success .panel-heading .text-size-h4,
.card-success .text-size-h4,
.bg-success .h5,
.panel-success .panel-heading .h5,
.card-success .h5,
.bg-success .text-size-h5,
.panel-success .panel-heading .text-size-h5,
.card-success .text-size-h5,
.bg-success .h6,
.panel-success .panel-heading .h6,
.card-success .h6,
.bg-success .text-size-h6,
.panel-success .panel-heading .text-size-h6,
.card-success .text-size-h6 {
  color: inherit;
}

.bg-success .ul-triangles li::before, .panel-success .panel-heading .ul-triangles li::before, .card-success .ul-triangles li::before, .bg-success ul.triangle li::before, .panel-success .panel-heading ul.triangle li::before, .card-success ul.triangle li::before {
  border-left-color: #000;
}

.bg-success a:not(.btn):not(.nav-link), .panel-success .panel-heading a:not(.btn):not(.nav-link), .card-success a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-info, .panel-info .panel-heading, .card-info {
  color: #000;
  background-color: #81d3eb;
}

.bg-info h1, .panel-info .panel-heading h1, .card-info h1,
.bg-info h2,
.panel-info .panel-heading h2,
.card-info h2,
.bg-info h3,
.panel-info .panel-heading h3,
.card-info h3,
.bg-info h4,
.panel-info .panel-heading h4,
.card-info h4,
.bg-info h5,
.panel-info .panel-heading h5,
.card-info h5,
.bg-info h6,
.panel-info .panel-heading h6,
.card-info h6,
.bg-info .h1,
.panel-info .panel-heading .h1,
.card-info .h1,
.bg-info .text-size-h1,
.panel-info .panel-heading .text-size-h1,
.card-info .text-size-h1,
.bg-info .h2,
.panel-info .panel-heading .h2,
.card-info .h2,
.bg-info .text-size-h2,
.panel-info .panel-heading .text-size-h2,
.card-info .text-size-h2,
.bg-info .h3,
.panel-info .panel-heading .h3,
.card-info .h3,
.bg-info .text-size-h3,
.panel-info .panel-heading .text-size-h3,
.card-info .text-size-h3,
.bg-info .h4,
.panel-info .panel-heading .h4,
.card-info .h4,
.bg-info .text-size-h4,
.panel-info .panel-heading .text-size-h4,
.card-info .text-size-h4,
.bg-info .h5,
.panel-info .panel-heading .h5,
.card-info .h5,
.bg-info .text-size-h5,
.panel-info .panel-heading .text-size-h5,
.card-info .text-size-h5,
.bg-info .h6,
.panel-info .panel-heading .h6,
.card-info .h6,
.bg-info .text-size-h6,
.panel-info .panel-heading .text-size-h6,
.card-info .text-size-h6 {
  color: inherit;
}

.bg-info .ul-triangles li::before, .panel-info .panel-heading .ul-triangles li::before, .card-info .ul-triangles li::before, .bg-info ul.triangle li::before, .panel-info .panel-heading ul.triangle li::before, .card-info ul.triangle li::before {
  border-left-color: #000;
}

.bg-info a:not(.btn):not(.nav-link), .panel-info .panel-heading a:not(.btn):not(.nav-link), .card-info a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-warning, .panel-warning .panel-heading, .card-warning {
  color: #000;
  background-color: #f19e1f;
}

.bg-warning h1, .panel-warning .panel-heading h1, .card-warning h1,
.bg-warning h2,
.panel-warning .panel-heading h2,
.card-warning h2,
.bg-warning h3,
.panel-warning .panel-heading h3,
.card-warning h3,
.bg-warning h4,
.panel-warning .panel-heading h4,
.card-warning h4,
.bg-warning h5,
.panel-warning .panel-heading h5,
.card-warning h5,
.bg-warning h6,
.panel-warning .panel-heading h6,
.card-warning h6,
.bg-warning .h1,
.panel-warning .panel-heading .h1,
.card-warning .h1,
.bg-warning .text-size-h1,
.panel-warning .panel-heading .text-size-h1,
.card-warning .text-size-h1,
.bg-warning .h2,
.panel-warning .panel-heading .h2,
.card-warning .h2,
.bg-warning .text-size-h2,
.panel-warning .panel-heading .text-size-h2,
.card-warning .text-size-h2,
.bg-warning .h3,
.panel-warning .panel-heading .h3,
.card-warning .h3,
.bg-warning .text-size-h3,
.panel-warning .panel-heading .text-size-h3,
.card-warning .text-size-h3,
.bg-warning .h4,
.panel-warning .panel-heading .h4,
.card-warning .h4,
.bg-warning .text-size-h4,
.panel-warning .panel-heading .text-size-h4,
.card-warning .text-size-h4,
.bg-warning .h5,
.panel-warning .panel-heading .h5,
.card-warning .h5,
.bg-warning .text-size-h5,
.panel-warning .panel-heading .text-size-h5,
.card-warning .text-size-h5,
.bg-warning .h6,
.panel-warning .panel-heading .h6,
.card-warning .h6,
.bg-warning .text-size-h6,
.panel-warning .panel-heading .text-size-h6,
.card-warning .text-size-h6 {
  color: inherit;
}

.bg-warning .ul-triangles li::before, .panel-warning .panel-heading .ul-triangles li::before, .card-warning .ul-triangles li::before, .bg-warning ul.triangle li::before, .panel-warning .panel-heading ul.triangle li::before, .card-warning ul.triangle li::before {
  border-left-color: #000;
}

.bg-warning a:not(.btn):not(.nav-link), .panel-warning .panel-heading a:not(.btn):not(.nav-link), .card-warning a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-danger, .panel-danger .panel-heading, .card-danger {
  color: #fff;
  background-color: #a95c42;
}

.bg-danger h1, .panel-danger .panel-heading h1, .card-danger h1,
.bg-danger h2,
.panel-danger .panel-heading h2,
.card-danger h2,
.bg-danger h3,
.panel-danger .panel-heading h3,
.card-danger h3,
.bg-danger h4,
.panel-danger .panel-heading h4,
.card-danger h4,
.bg-danger h5,
.panel-danger .panel-heading h5,
.card-danger h5,
.bg-danger h6,
.panel-danger .panel-heading h6,
.card-danger h6,
.bg-danger .h1,
.panel-danger .panel-heading .h1,
.card-danger .h1,
.bg-danger .text-size-h1,
.panel-danger .panel-heading .text-size-h1,
.card-danger .text-size-h1,
.bg-danger .h2,
.panel-danger .panel-heading .h2,
.card-danger .h2,
.bg-danger .text-size-h2,
.panel-danger .panel-heading .text-size-h2,
.card-danger .text-size-h2,
.bg-danger .h3,
.panel-danger .panel-heading .h3,
.card-danger .h3,
.bg-danger .text-size-h3,
.panel-danger .panel-heading .text-size-h3,
.card-danger .text-size-h3,
.bg-danger .h4,
.panel-danger .panel-heading .h4,
.card-danger .h4,
.bg-danger .text-size-h4,
.panel-danger .panel-heading .text-size-h4,
.card-danger .text-size-h4,
.bg-danger .h5,
.panel-danger .panel-heading .h5,
.card-danger .h5,
.bg-danger .text-size-h5,
.panel-danger .panel-heading .text-size-h5,
.card-danger .text-size-h5,
.bg-danger .h6,
.panel-danger .panel-heading .h6,
.card-danger .h6,
.bg-danger .text-size-h6,
.panel-danger .panel-heading .text-size-h6,
.card-danger .text-size-h6 {
  color: inherit;
}

.bg-danger a:not(.btn):not(.nav-link), .panel-danger .panel-heading a:not(.btn):not(.nav-link), .card-danger a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-danger .ul-triangles li::before, .panel-danger .panel-heading .ul-triangles li::before, .card-danger .ul-triangles li::before, .bg-danger ul.triangle li::before, .panel-danger .panel-heading ul.triangle li::before, .card-danger ul.triangle li::before {
  border-left-color: #fff;
}

.bg-light,
.panel .panel-footer, .panel-default .panel-heading, .well {
  color: #000;
  background-color: #dee2e6;
}

.bg-light h1, .panel .panel-footer h1, .panel-default .panel-heading h1, .well h1,
.bg-light h2,
.panel .panel-footer h2,
.panel-default .panel-heading h2,
.well h2,
.bg-light h3,
.panel .panel-footer h3,
.panel-default .panel-heading h3,
.well h3,
.bg-light h4,
.panel .panel-footer h4,
.panel-default .panel-heading h4,
.well h4,
.bg-light h5,
.panel .panel-footer h5,
.panel-default .panel-heading h5,
.well h5,
.bg-light h6,
.panel .panel-footer h6,
.panel-default .panel-heading h6,
.well h6,
.bg-light .h1,
.panel .panel-footer .h1,
.panel-default .panel-heading .h1,
.well .h1,
.bg-light .text-size-h1,
.panel .panel-footer .text-size-h1,
.panel-default .panel-heading .text-size-h1,
.well .text-size-h1,
.bg-light .h2,
.panel .panel-footer .h2,
.panel-default .panel-heading .h2,
.well .h2,
.bg-light .text-size-h2,
.panel .panel-footer .text-size-h2,
.panel-default .panel-heading .text-size-h2,
.well .text-size-h2,
.bg-light .h3,
.panel .panel-footer .h3,
.panel-default .panel-heading .h3,
.well .h3,
.bg-light .text-size-h3,
.panel .panel-footer .text-size-h3,
.panel-default .panel-heading .text-size-h3,
.well .text-size-h3,
.bg-light .h4,
.panel .panel-footer .h4,
.panel-default .panel-heading .h4,
.well .h4,
.bg-light .text-size-h4,
.panel .panel-footer .text-size-h4,
.panel-default .panel-heading .text-size-h4,
.well .text-size-h4,
.bg-light .h5,
.panel .panel-footer .h5,
.panel-default .panel-heading .h5,
.well .h5,
.bg-light .text-size-h5,
.panel .panel-footer .text-size-h5,
.panel-default .panel-heading .text-size-h5,
.well .text-size-h5,
.bg-light .h6,
.panel .panel-footer .h6,
.panel-default .panel-heading .h6,
.well .h6,
.bg-light .text-size-h6,
.panel .panel-footer .text-size-h6,
.panel-default .panel-heading .text-size-h6,
.well .text-size-h6 {
  color: inherit;
}

.bg-light .ul-triangles li::before, .panel .panel-footer .ul-triangles li::before, .panel-default .panel-heading .ul-triangles li::before, .well .ul-triangles li::before, .bg-light ul.triangle li::before, .panel .panel-footer ul.triangle li::before, .panel-default .panel-heading ul.triangle li::before, .well ul.triangle li::before {
  border-left-color: #000;
}

.bg-dark, .callout-dark, .card-inverse, .modal-bg-dark {
  color: #fff;
  background-color: #343a40;
}

.bg-dark h1, .callout-dark h1, .card-inverse h1, .modal-bg-dark h1,
.bg-dark h2,
.callout-dark h2,
.card-inverse h2,
.modal-bg-dark h2,
.bg-dark h3,
.callout-dark h3,
.card-inverse h3,
.modal-bg-dark h3,
.bg-dark h4,
.callout-dark h4,
.card-inverse h4,
.modal-bg-dark h4,
.bg-dark h5,
.callout-dark h5,
.card-inverse h5,
.modal-bg-dark h5,
.bg-dark h6,
.callout-dark h6,
.card-inverse h6,
.modal-bg-dark h6,
.bg-dark .h1,
.callout-dark .h1,
.card-inverse .h1,
.modal-bg-dark .h1,
.bg-dark .text-size-h1,
.callout-dark .text-size-h1,
.card-inverse .text-size-h1,
.modal-bg-dark .text-size-h1,
.bg-dark .h2,
.callout-dark .h2,
.card-inverse .h2,
.modal-bg-dark .h2,
.bg-dark .text-size-h2,
.callout-dark .text-size-h2,
.card-inverse .text-size-h2,
.modal-bg-dark .text-size-h2,
.bg-dark .h3,
.callout-dark .h3,
.card-inverse .h3,
.modal-bg-dark .h3,
.bg-dark .text-size-h3,
.callout-dark .text-size-h3,
.card-inverse .text-size-h3,
.modal-bg-dark .text-size-h3,
.bg-dark .h4,
.callout-dark .h4,
.card-inverse .h4,
.modal-bg-dark .h4,
.bg-dark .text-size-h4,
.callout-dark .text-size-h4,
.card-inverse .text-size-h4,
.modal-bg-dark .text-size-h4,
.bg-dark .h5,
.callout-dark .h5,
.card-inverse .h5,
.modal-bg-dark .h5,
.bg-dark .text-size-h5,
.callout-dark .text-size-h5,
.card-inverse .text-size-h5,
.modal-bg-dark .text-size-h5,
.bg-dark .h6,
.callout-dark .h6,
.card-inverse .h6,
.modal-bg-dark .h6,
.bg-dark .text-size-h6,
.callout-dark .text-size-h6,
.card-inverse .text-size-h6,
.modal-bg-dark .text-size-h6 {
  color: inherit;
}

.bg-dark a:not(.btn):not(.nav-link), .callout-dark a:not(.btn):not(.nav-link), .card-inverse a:not(.btn):not(.nav-link), .modal-bg-dark a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-dark .ul-triangles li::before, .callout-dark .ul-triangles li::before, .card-inverse .ul-triangles li::before, .modal-bg-dark .ul-triangles li::before, .bg-dark ul.triangle li::before, .callout-dark ul.triangle li::before, .card-inverse ul.triangle li::before, .modal-bg-dark ul.triangle li::before {
  border-left-color: #fff;
}

.bg-gray-100, .bg-silver-tint {
  color: #000;
  background-color: #f8f9fa;
}

.bg-gray-100 h1, .bg-silver-tint h1,
.bg-gray-100 h2,
.bg-silver-tint h2,
.bg-gray-100 h3,
.bg-silver-tint h3,
.bg-gray-100 h4,
.bg-silver-tint h4,
.bg-gray-100 h5,
.bg-silver-tint h5,
.bg-gray-100 h6,
.bg-silver-tint h6,
.bg-gray-100 .h1,
.bg-silver-tint .h1,
.bg-gray-100 .text-size-h1,
.bg-silver-tint .text-size-h1,
.bg-gray-100 .h2,
.bg-silver-tint .h2,
.bg-gray-100 .text-size-h2,
.bg-silver-tint .text-size-h2,
.bg-gray-100 .h3,
.bg-silver-tint .h3,
.bg-gray-100 .text-size-h3,
.bg-silver-tint .text-size-h3,
.bg-gray-100 .h4,
.bg-silver-tint .h4,
.bg-gray-100 .text-size-h4,
.bg-silver-tint .text-size-h4,
.bg-gray-100 .h5,
.bg-silver-tint .h5,
.bg-gray-100 .text-size-h5,
.bg-silver-tint .text-size-h5,
.bg-gray-100 .h6,
.bg-silver-tint .h6,
.bg-gray-100 .text-size-h6,
.bg-silver-tint .text-size-h6 {
  color: inherit;
}

.bg-gray-100 .ul-triangles li::before, .bg-silver-tint .ul-triangles li::before, .bg-gray-100 ul.triangle li::before, .bg-silver-tint ul.triangle li::before {
  border-left-color: #000;
}

.bg-gray-200 {
  color: #000;
  background-color: #e9ecef;
}

.bg-gray-200 h1,
.bg-gray-200 h2,
.bg-gray-200 h3,
.bg-gray-200 h4,
.bg-gray-200 h5,
.bg-gray-200 h6,
.bg-gray-200 .h1,
.bg-gray-200 .text-size-h1,
.bg-gray-200 .h2,
.bg-gray-200 .text-size-h2,
.bg-gray-200 .h3,
.bg-gray-200 .text-size-h3,
.bg-gray-200 .h4,
.bg-gray-200 .text-size-h4,
.bg-gray-200 .h5,
.bg-gray-200 .text-size-h5,
.bg-gray-200 .h6,
.bg-gray-200 .text-size-h6 {
  color: inherit;
}

.bg-gray-200 .ul-triangles li::before, .bg-gray-200 ul.triangle li::before {
  border-left-color: #000;
}

.bg-gray-300 {
  color: #000;
  background-color: #dee2e6;
}

.bg-gray-300 h1,
.bg-gray-300 h2,
.bg-gray-300 h3,
.bg-gray-300 h4,
.bg-gray-300 h5,
.bg-gray-300 h6,
.bg-gray-300 .h1,
.bg-gray-300 .text-size-h1,
.bg-gray-300 .h2,
.bg-gray-300 .text-size-h2,
.bg-gray-300 .h3,
.bg-gray-300 .text-size-h3,
.bg-gray-300 .h4,
.bg-gray-300 .text-size-h4,
.bg-gray-300 .h5,
.bg-gray-300 .text-size-h5,
.bg-gray-300 .h6,
.bg-gray-300 .text-size-h6 {
  color: inherit;
}

.bg-gray-300 .ul-triangles li::before, .bg-gray-300 ul.triangle li::before {
  border-left-color: #000;
}

.bg-gray-400 {
  color: #000;
  background-color: #ced4da;
}

.bg-gray-400 h1,
.bg-gray-400 h2,
.bg-gray-400 h3,
.bg-gray-400 h4,
.bg-gray-400 h5,
.bg-gray-400 h6,
.bg-gray-400 .h1,
.bg-gray-400 .text-size-h1,
.bg-gray-400 .h2,
.bg-gray-400 .text-size-h2,
.bg-gray-400 .h3,
.bg-gray-400 .text-size-h3,
.bg-gray-400 .h4,
.bg-gray-400 .text-size-h4,
.bg-gray-400 .h5,
.bg-gray-400 .text-size-h5,
.bg-gray-400 .h6,
.bg-gray-400 .text-size-h6 {
  color: inherit;
}

.bg-gray-400 .ul-triangles li::before, .bg-gray-400 ul.triangle li::before {
  border-left-color: #000;
}

.bg-gray-500 {
  color: #000;
  background-color: #adb5bd;
}

.bg-gray-500 h1,
.bg-gray-500 h2,
.bg-gray-500 h3,
.bg-gray-500 h4,
.bg-gray-500 h5,
.bg-gray-500 h6,
.bg-gray-500 .h1,
.bg-gray-500 .text-size-h1,
.bg-gray-500 .h2,
.bg-gray-500 .text-size-h2,
.bg-gray-500 .h3,
.bg-gray-500 .text-size-h3,
.bg-gray-500 .h4,
.bg-gray-500 .text-size-h4,
.bg-gray-500 .h5,
.bg-gray-500 .text-size-h5,
.bg-gray-500 .h6,
.bg-gray-500 .text-size-h6 {
  color: inherit;
}

.bg-gray-500 .ul-triangles li::before, .bg-gray-500 ul.triangle li::before {
  border-left-color: #000;
}

.bg-gray-600 {
  color: #fff;
  background-color: #6c757d;
}

.bg-gray-600 h1,
.bg-gray-600 h2,
.bg-gray-600 h3,
.bg-gray-600 h4,
.bg-gray-600 h5,
.bg-gray-600 h6,
.bg-gray-600 .h1,
.bg-gray-600 .text-size-h1,
.bg-gray-600 .h2,
.bg-gray-600 .text-size-h2,
.bg-gray-600 .h3,
.bg-gray-600 .text-size-h3,
.bg-gray-600 .h4,
.bg-gray-600 .text-size-h4,
.bg-gray-600 .h5,
.bg-gray-600 .text-size-h5,
.bg-gray-600 .h6,
.bg-gray-600 .text-size-h6 {
  color: inherit;
}

.bg-gray-600 a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-gray-600 .ul-triangles li::before, .bg-gray-600 ul.triangle li::before {
  border-left-color: #fff;
}

.bg-gray-700 {
  color: #fff;
  background-color: #495057;
}

.bg-gray-700 h1,
.bg-gray-700 h2,
.bg-gray-700 h3,
.bg-gray-700 h4,
.bg-gray-700 h5,
.bg-gray-700 h6,
.bg-gray-700 .h1,
.bg-gray-700 .text-size-h1,
.bg-gray-700 .h2,
.bg-gray-700 .text-size-h2,
.bg-gray-700 .h3,
.bg-gray-700 .text-size-h3,
.bg-gray-700 .h4,
.bg-gray-700 .text-size-h4,
.bg-gray-700 .h5,
.bg-gray-700 .text-size-h5,
.bg-gray-700 .h6,
.bg-gray-700 .text-size-h6 {
  color: inherit;
}

.bg-gray-700 a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-gray-700 .ul-triangles li::before, .bg-gray-700 ul.triangle li::before {
  border-left-color: #fff;
}

.bg-gray-800 {
  color: #fff;
  background-color: #343a40;
}

.bg-gray-800 h1,
.bg-gray-800 h2,
.bg-gray-800 h3,
.bg-gray-800 h4,
.bg-gray-800 h5,
.bg-gray-800 h6,
.bg-gray-800 .h1,
.bg-gray-800 .text-size-h1,
.bg-gray-800 .h2,
.bg-gray-800 .text-size-h2,
.bg-gray-800 .h3,
.bg-gray-800 .text-size-h3,
.bg-gray-800 .h4,
.bg-gray-800 .text-size-h4,
.bg-gray-800 .h5,
.bg-gray-800 .text-size-h5,
.bg-gray-800 .h6,
.bg-gray-800 .text-size-h6 {
  color: inherit;
}

.bg-gray-800 a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-gray-800 .ul-triangles li::before, .bg-gray-800 ul.triangle li::before {
  border-left-color: #fff;
}

.bg-gray-900 {
  color: #fff;
  background-color: #212529;
}

.bg-gray-900 h1,
.bg-gray-900 h2,
.bg-gray-900 h3,
.bg-gray-900 h4,
.bg-gray-900 h5,
.bg-gray-900 h6,
.bg-gray-900 .h1,
.bg-gray-900 .text-size-h1,
.bg-gray-900 .h2,
.bg-gray-900 .text-size-h2,
.bg-gray-900 .h3,
.bg-gray-900 .text-size-h3,
.bg-gray-900 .h4,
.bg-gray-900 .text-size-h4,
.bg-gray-900 .h5,
.bg-gray-900 .text-size-h5,
.bg-gray-900 .h6,
.bg-gray-900 .text-size-h6 {
  color: inherit;
}

.bg-gray-900 a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-gray-900 .ul-triangles li::before, .bg-gray-900 ul.triangle li::before {
  border-left-color: #fff;
}

.bg-light,
.panel .panel-footer, .panel-default .panel-heading, .well {
  color: #000;
  background-color: #dee2e6;
}

.bg-light h1, .panel .panel-footer h1, .panel-default .panel-heading h1, .well h1,
.bg-light h2,
.panel .panel-footer h2,
.panel-default .panel-heading h2,
.well h2,
.bg-light h3,
.panel .panel-footer h3,
.panel-default .panel-heading h3,
.well h3,
.bg-light h4,
.panel .panel-footer h4,
.panel-default .panel-heading h4,
.well h4,
.bg-light h5,
.panel .panel-footer h5,
.panel-default .panel-heading h5,
.well h5,
.bg-light h6,
.panel .panel-footer h6,
.panel-default .panel-heading h6,
.well h6,
.bg-light .h1,
.panel .panel-footer .h1,
.panel-default .panel-heading .h1,
.well .h1,
.bg-light .text-size-h1,
.panel .panel-footer .text-size-h1,
.panel-default .panel-heading .text-size-h1,
.well .text-size-h1,
.bg-light .h2,
.panel .panel-footer .h2,
.panel-default .panel-heading .h2,
.well .h2,
.bg-light .text-size-h2,
.panel .panel-footer .text-size-h2,
.panel-default .panel-heading .text-size-h2,
.well .text-size-h2,
.bg-light .h3,
.panel .panel-footer .h3,
.panel-default .panel-heading .h3,
.well .h3,
.bg-light .text-size-h3,
.panel .panel-footer .text-size-h3,
.panel-default .panel-heading .text-size-h3,
.well .text-size-h3,
.bg-light .h4,
.panel .panel-footer .h4,
.panel-default .panel-heading .h4,
.well .h4,
.bg-light .text-size-h4,
.panel .panel-footer .text-size-h4,
.panel-default .panel-heading .text-size-h4,
.well .text-size-h4,
.bg-light .h5,
.panel .panel-footer .h5,
.panel-default .panel-heading .h5,
.well .h5,
.bg-light .text-size-h5,
.panel .panel-footer .text-size-h5,
.panel-default .panel-heading .text-size-h5,
.well .text-size-h5,
.bg-light .h6,
.panel .panel-footer .h6,
.panel-default .panel-heading .h6,
.well .h6,
.bg-light .text-size-h6,
.panel .panel-footer .text-size-h6,
.panel-default .panel-heading .text-size-h6,
.well .text-size-h6 {
  color: inherit;
}

.bg-light .ul-triangles li::before, .panel .panel-footer .ul-triangles li::before, .panel-default .panel-heading .ul-triangles li::before, .well .ul-triangles li::before, .bg-light ul.triangle li::before, .panel .panel-footer ul.triangle li::before, .panel-default .panel-heading ul.triangle li::before, .well ul.triangle li::before {
  border-left-color: #000;
}

.bg-dark, .callout-dark, .card-inverse, .modal-bg-dark {
  color: #fff;
  background-color: #343a40;
}

.bg-dark h1, .callout-dark h1, .card-inverse h1, .modal-bg-dark h1,
.bg-dark h2,
.callout-dark h2,
.card-inverse h2,
.modal-bg-dark h2,
.bg-dark h3,
.callout-dark h3,
.card-inverse h3,
.modal-bg-dark h3,
.bg-dark h4,
.callout-dark h4,
.card-inverse h4,
.modal-bg-dark h4,
.bg-dark h5,
.callout-dark h5,
.card-inverse h5,
.modal-bg-dark h5,
.bg-dark h6,
.callout-dark h6,
.card-inverse h6,
.modal-bg-dark h6,
.bg-dark .h1,
.callout-dark .h1,
.card-inverse .h1,
.modal-bg-dark .h1,
.bg-dark .text-size-h1,
.callout-dark .text-size-h1,
.card-inverse .text-size-h1,
.modal-bg-dark .text-size-h1,
.bg-dark .h2,
.callout-dark .h2,
.card-inverse .h2,
.modal-bg-dark .h2,
.bg-dark .text-size-h2,
.callout-dark .text-size-h2,
.card-inverse .text-size-h2,
.modal-bg-dark .text-size-h2,
.bg-dark .h3,
.callout-dark .h3,
.card-inverse .h3,
.modal-bg-dark .h3,
.bg-dark .text-size-h3,
.callout-dark .text-size-h3,
.card-inverse .text-size-h3,
.modal-bg-dark .text-size-h3,
.bg-dark .h4,
.callout-dark .h4,
.card-inverse .h4,
.modal-bg-dark .h4,
.bg-dark .text-size-h4,
.callout-dark .text-size-h4,
.card-inverse .text-size-h4,
.modal-bg-dark .text-size-h4,
.bg-dark .h5,
.callout-dark .h5,
.card-inverse .h5,
.modal-bg-dark .h5,
.bg-dark .text-size-h5,
.callout-dark .text-size-h5,
.card-inverse .text-size-h5,
.modal-bg-dark .text-size-h5,
.bg-dark .h6,
.callout-dark .h6,
.card-inverse .h6,
.modal-bg-dark .h6,
.bg-dark .text-size-h6,
.callout-dark .text-size-h6,
.card-inverse .text-size-h6,
.modal-bg-dark .text-size-h6 {
  color: inherit;
}

.bg-dark a:not(.btn):not(.nav-link), .callout-dark a:not(.btn):not(.nav-link), .card-inverse a:not(.btn):not(.nav-link), .modal-bg-dark a:not(.btn):not(.nav-link) {
  color: inherit;
}

.bg-dark .ul-triangles li::before, .callout-dark .ul-triangles li::before, .card-inverse .ul-triangles li::before, .modal-bg-dark .ul-triangles li::before, .bg-dark ul.triangle li::before, .callout-dark ul.triangle li::before, .card-inverse ul.triangle li::before, .modal-bg-dark ul.triangle li::before {
  border-left-color: #fff;
}

.bg-dark .close, .callout-dark .close, .card-inverse .close, .modal-bg-dark .close {
  color: #fff;
}

/*
* >> Text Color
*/
.text-blue, .text-primary {
  color: #0c234b !important;
}

.text-red {
  color: #ab0520 !important;
}

.text-white, .card-inverse {
  color: #fff !important;
}

.text-bloom {
  color: #ef4056 !important;
}

.text-chili {
  color: #8b0015 !important;
}

.text-sky {
  color: #81d3eb !important;
}

.text-oasis {
  color: #378dbd !important;
}

.text-azurite {
  color: #1e5288 !important;
}

.text-midnight {
  color: #001c48 !important;
}

.text-cool-gray {
  color: #e2e9eb !important;
}

.text-warm-gray {
  color: #f4ede5 !important;
}

.text-leaf {
  color: #70b865 !important;
}

.text-river {
  color: #007d84 !important;
}

.text-silver {
  color: #9eabae !important;
}

.text-mesa {
  color: #a95c42 !important;
}

.text-ash {
  color: #403635 !important;
}

.text-sage {
  color: #4a634e !important;
}

.text-black {
  color: #000 !important;
}

.text-success {
  color: #70b865 !important;
}

.text-info {
  color: #1e5288 !important;
}

.text-warning {
  color: #f19e1f !important;
}

.text-danger {
  color: #a95c42 !important;
}

.text-light {
  color: #dee2e6 !important;
}

.text-dark {
  color: #343a40 !important;
}

.text-dark-silver {
  color: #49595e !important;
}

/*
* >> Border Colors
*/
.border-blue, .card-primary-outline {
  border-color: #0c234b !important;
}

.border-red {
  border-color: #ab0520 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-bloom {
  border-color: #ef4056 !important;
}

.border-chili {
  border-color: #8b0015 !important;
}

.border-sky {
  border-color: #81d3eb !important;
}

.border-oasis {
  border-color: #378dbd !important;
}

.border-azurite {
  border-color: #1e5288 !important;
}

.border-midnight {
  border-color: #001c48 !important;
}

.border-cool-gray {
  border-color: #e2e9eb !important;
}

.border-warm-gray {
  border-color: #f4ede5 !important;
}

.border-leaf {
  border-color: #70b865 !important;
}

.border-river {
  border-color: #007d84 !important;
}

.border-silver {
  border-color: #9eabae !important;
}

.border-mesa {
  border-color: #a95c42 !important;
}

.border-ash {
  border-color: #403635 !important;
}

.border-sage {
  border-color: #4a634e !important;
}

.border-black {
  border-color: #000 !important;
}

/*
* > TYPOGRAPHY
*/
.heading-style, h1,
h2,
.h1, .text-size-h1,
.h2, .text-size-h2 {
  font-family: proxima-nova, calibri, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #49595e;
}

.sans {
  font-family: proxima-nova, calibri, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
}

.text-hyphen {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.ul-triangles, ul.triangle {
  overflow: hidden;
}

.ul-triangles li, ul.triangle li {
  position: relative;
  padding-left: 0;
  list-style: none;
}

.ul-triangles li::before, ul.triangle li::before {
  position: absolute;
  top: 8px;
  left: -.8em;
  width: 0;
  height: 0;
  content: "";
  border-top: 4px solid transparent;
  border-right: 0;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #0c234b;
}

.arizona-header {
  position: relative;
}

.block-a-line-logo {
  width: auto;
  height: 36px;
  margin: 5px 20px 0 10px;
}

.arizona-line-logo {
  width: 211px;
  height: 16px;
  margin: 17px 20px 17px 10px;
}

@media (min-width: 576px) {
  .arizona-line-logo {
    width: 266.41px;
    height: 19.8px;
    margin: 15.11px 20px 15.1px 10px;
  }
}

.arizona-logo {
  display: inline-block;
  float: left;
  max-width: 80%;
}

.redbar-buttons {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  max-height: 50px;
}

.btn-redbar .icon-text {
  display: block;
  margin: -5px;
  font-size: 10px;
  line-height: 10px;
}

.btn-redbar .icon {
  margin: -5px;
  font-size: 24px;
  line-height: 24px;
}

.btn.btn-redbar {
  width: 60px;
  height: 50px;
  padding: 0;
  color: #fff;
  background-color: #ab0520;
  border: 2px solid #ab0520;
}

.btn.btn-redbar:focus, .btn.btn-redbar.focus {
  background-color: #8b0015;
  border: 2px solid #8b0015;
  outline: 0;
  box-shadow: none;
}

[class*="split-"] {
  display: block;
}

.split-2-col {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.split-3-col {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}

.split-4-col {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}

@media (min-width: 576px) {
  .split-sm-2-col {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .split-sm-3-col {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .split-sm-4-col {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 768px) {
  .split-md-2-col {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .split-md-3-col {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .split-md-4-col {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 992px) {
  .split-lg-2-col {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .split-lg-3-col {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .split-lg-4-col {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

.border-thick {
  border-width: .25rem !important;
}

.figure-caption {
  padding-top: .5em;
  padding-bottom: .9375em;
  border-bottom: 1px solid #e9ecef;
}

/*
* > BLOCKQUOTE
*/
.blockquote {
  font-size: 1.25rem;
  padding: .75rem 1.5rem;
  margin-bottom: 1.5rem;
  border-color: #0c234b;
  border-style: solid;
  border-width: 0 0 0 5px;
}

.blockquote.blockquote-reverse {
  border-width: 0 5px 0 0;
}

/*
* > BODY
*/
body {
  color: #403635;
}

/*
* > HEADINGS
*/
h1,
h2,
.h1,
.text-size-h1, .h2, .text-size-h2 {
  margin: 1.042em 0 0.667em;
}

h3,
h4,
h5,
h6, .h3, .text-size-h3, .h4, .text-size-h4, .h5, .text-size-h5, .h6, .text-size-h6 {
  margin: 1.042em 0 0.667em;
  font-family: proxima-nova, calibri, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #49595e;
}

/*
* > LINKS
*/
a {
  font-weight: 700;
  text-decoration: underline;
}

.mailto {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.badge-success, .label-success {
  color: #001c48;
  background-color: #70b865;
}

.badge-info, .label-info {
  color: #001c48;
  background-color: #81d3eb;
}

.badge-warning, .label-warning {
  color: #000;
  background-color: #f19e1f;
}

.badge-danger, .label-danger {
  color: #fff;
  background-color: #a95c42;
}

.badge-light, .label-muted {
  color: #001c48;
  background-color: #dee2e6;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-red, .label-default {
  color: #fff;
  background-color: #8b0015;
}

.badge-red.badge-link:hover, .badge-link.label-default:hover {
  color: #fff;
  background-color: #ab0520;
}

.badge-blue, .label-primary {
  color: #fff;
  background-color: #0c234b;
}

.badge-blue.badge-link:hover, .badge-link.label-primary:hover {
  color: #fff;
  background-color: #1e5288;
}

.btn .badge-light, .btn .label-muted {
  color: #001c48;
  background-color: #e2e9eb;
}

.badge-pill {
  border-radius: 0.25rem;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fff;
}

.breadcrumb a {
  padding: 1px 0;
  font-weight: 500;
  color: #1e5288;
}

.breadcrumb a:hover {
  color: #001c48;
}

.breadcrumb-item-home::before {
  float: left;
  padding-right: 0.5rem;
  font-family: "Material Icons Sharp";
  color: #6c757d;
  content: "home";
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  font-family: "Material Icons Sharp";
  color: #6c757d;
  content: "chevron_right";
}

.btn {
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: .04em;
  white-space: normal;
  border-width: 2px;
}

.btn-success {
  color: #001c48;
}

.btn-info {
  color: #001c48;
}

.btn-warning {
  color: #000;
}

.btn-danger {
  color: #fff;
}

.btn-light {
  color: #001c48;
}

.btn-dark {
  color: #fff;
}

.btn-link {
  color: #8b0015;
}

.btn-red, .btn-default {
  color: #fff;
  background-color: #8b0015;
}

.btn-red:hover, .btn-default:hover {
  color: #fff;
  background-color: #ab0520;
}

.btn-red.active, .active.btn-default, .btn-red:active, .btn-default:active {
  background-color: #a50019;
}

.btn-blue, .btn-primary {
  color: #fff;
  background-color: #0c234b;
}

.btn-blue:hover, .btn-primary:hover {
  color: #fff;
  background-color: #1e5288;
}

.btn-blue.active, .active.btn-primary, .btn-blue:active, .btn-primary:active {
  background-color: #133877;
}

.btn-outline-red, .btn-hollow-default {
  color: #8b0015;
  border-color: #8b0015;
}

.btn-outline-red:hover, .btn-hollow-default:hover {
  color: #fff;
  background-color: #8b0015;
}

.btn-outline-blue, .btn-hollow-primary {
  color: #0c234b;
  border-color: #0c234b;
}

.btn-outline-blue:hover, .btn-hollow-primary:hover {
  color: #fff;
  background-color: #0c234b;
}

.btn-outline-white, .btn-hollow-reverse {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover, .btn-hollow-reverse:hover {
  color: #343a40;
  background-color: #fff;
}

.btn-success:hover,
.btn-info:hover,
.btn-outline-info:hover {
  color: #001c48;
}

.btn-warning:hover {
  color: #000;
}

.btn-outline-info {
  color: #1e5288;
}

.btn-outline-warning {
  color: #403635;
}

.btn-outline-warning:hover {
  color: #000;
}

.btn-outline-light {
  color: #403635;
}

.btn-arrow::after {
  content: "\00a0»";
}

.btn-group-justified {
  display: -ms-flexbox;
  display: flex;
}

.btn-group-justified .btn {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.btn-group-block .dropdown-menu {
  min-width: 100%;
}

.card .card-header, .well .card-header, .panel .card-header, .thumbnail .card-header {
  background-color: #e9ecef;
}

.card[class*=" bg-"] .card-header, .well[class*=" bg-"] .card-header, .panel[class*=" bg-"] .card-header, .thumbnail[class*=" bg-"] .card-header {
  background-color: rgba(0, 0, 0, 0.075);
}

.bg-success .card-header, .panel-success .panel-heading .card-header, .card-success .card-header {
  color: #001c48;
}

.bg-info .card-header, .panel-info .panel-heading .card-header, .card-info .card-header {
  color: #001c48;
}

.card-clickable {
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-color: #81d3eb;
}

.card-clickable .card-body, .card-clickable .panel .panel-heading, .panel .card-clickable .panel-heading,
.card-clickable .panel .panel-footer, .panel .card-clickable .panel-footer, .card-clickable .panel .panel-body, .panel .card-clickable .panel-body, .card-clickable .thumbnail .caption, .thumbnail .card-clickable .caption, .card-clickable .well {
  padding: 2.5rem;
  color: inherit;
  text-decoration: none;
}

.card-clickable .card-clickable-link {
  color: #8b0015;
}

.card-clickable:hover {
  text-decoration: none;
}

.card-borderless:hover .card-clickable-link {
  text-decoration: underline;
}

.card-landing-grid {
  border-style: solid;
  border-width: 2px 0 0 2px;
  box-shadow: 3px 3px 0 0 rgba(129, 211, 235, 0.5);
}

.card-landing-grid.landing-oasis {
  box-shadow: 3px 3px 0 0 rgba(55, 141, 189, 0.5);
}

.card-landing-grid.landing-azurite {
  box-shadow: 3px 3px 0 0 rgba(30, 82, 136, 0.5);
}

.card-landing-grid.landing-blue {
  box-shadow: 3px 3px 0 0 rgba(12, 35, 75, 0.5);
}

.card-landing-grid.landing-midnight {
  box-shadow: 3px 3px 0 0 rgba(0, 28, 72, 0.5);
}

.card-landing-grid.landing-leaf {
  box-shadow: 3px 3px 0 0 rgba(112, 184, 101, 0.5);
}

.card-landing-grid.landing-river {
  box-shadow: 3px 3px 0 0 rgba(0, 125, 132, 0.5);
}

.card-landing-grid.landing-bloom {
  box-shadow: 3px 3px 0 0 rgba(239, 64, 86, 0.5);
}

.card-landing-grid.landing-red {
  box-shadow: 3px 3px 0 0 rgba(171, 5, 32, 0.5);
}

.card-landing-grid.landing-chili {
  box-shadow: 3px 3px 0 0 rgba(139, 0, 21, 0.5);
}

.card-landing-grid.landing-ash {
  box-shadow: 3px 3px 0 0 rgba(64, 54, 53, 0.5);
}

.card-landing-grid.landing-silver {
  box-shadow: 3px 3px 0 0 rgba(158, 171, 174, 0.5);
}

.dropdown-menu {
  color: #e2e9eb;
  background-color: #0c234b;
}

.dropdown-item {
  color: #fff;
  text-decoration: none;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: #1e5288;
}

.dropdown-item.active, .dropdown-item:active {
  color: #0c234b;
  background-color: #fff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
}

.nav-pills .nav-link,
.nav-tabs .nav-link,
.nav-link {
  color: #1e5288;
  text-decoration: none;
}

.nav-pills .nav-link:hover, .nav-pills .nav-link:focus,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-link:hover,
.nav-link:focus {
  color: #001c48;
}

.nav-pills .nav-link.disabled,
.nav-tabs .nav-link.disabled,
.nav-link.disabled {
  color: #495057;
}

.nav-pills .nav-link.active, .nav-pills .nav-link:active, .nav-pills .nav-link.is-active,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:active,
.nav-tabs .nav-link.is-active,
.nav-link.active,
.nav-link:active,
.nav-link.is-active {
  color: #001c48;
}

.nav-pills .nav-link.active, .nav-pills .nav-link:active, .nav-pills .nav-link.is-active,
.nav-link.active,
.nav-link:active,
.nav-link.is-active {
  background-color: #e9ecef;
}

.nav-pills .show > .nav-link {
  color: #001c48;
  background-color: #e9ecef;
}

/* stylelint-disable selector-max-type, selector-max-compound-selectors, selector-max-combinators */
.nav.nav-pills.flex-column > li {
  margin: -1px 0 0;
  border: solid 1px #e9ecef;
}

.nav.nav-pills.flex-column > li a:hover,
.nav.nav-pills.flex-column > li a:focus {
  background-color: #e9ecef;
}

.nav.nav-pills.flex-column > li ul > li {
  border: none;
}

.nav.nav-pills.flex-column > li ul > li a {
  padding-left: 25px;
  font-weight: 400;
  color: #001c48;
}

.nav.nav-pills.flex-column > li ul > li ul > li a {
  padding-left: 35px;
}

.nav.nav-pills.flex-column > li ul > li ul > li ul > li a {
  padding-left: 45px;
}

.nav.nav-pills.flex-column > li ul > li ul > li ul > li ul > li a {
  padding-left: 55px;
}

.nav.nav-pills.flex-column > li ul > li ul > li ul > li ul > li ul > li a {
  padding-left: 65px;
}

/* stylelint-enable selector-max-type, selector-max-compound-selectors, selector-max-combinators */
.nav-tabs {
  border-bottom-color: #e9ecef;
}

.nav-tabs .nav-item + .nav-item {
  margin-left: .5rem;
}

.nav-tabs .nav-link {
  background-color: #e9ecef;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link:active, .nav-tabs .nav-link.is-active {
  background-color: #fff;
  border-color: #e9ecef #e9ecef #fff;
}

.nav-tabs.nav-tabs-lg {
  -ms-flex-direction: column;
  flex-direction: column;
}

.nav-tabs.nav-tabs-lg .nav-item + .nav-item {
  margin-left: 0;
}

.nav-tabs.nav-tabs-lg .nav-link {
  text-transform: none;
  background-color: #fff;
  border-width: 0;
}

.nav-tabs.nav-tabs-lg .nav-link.active, .nav-tabs.nav-tabs-lg .nav-link:active, .nav-tabs.nav-tabs-lg .nav-link.is-active {
  background-color: #e9ecef;
}

.nav-utility {
  margin: 1rem 0 1.5rem 0;
}

.nav-utility .nav-item {
  padding: 0 0.6rem;
}

.nav-utility .nav-link {
  padding: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #49595e;
  border-bottom: 2px solid transparent;
}

.nav-utility .nav-link:hover, .nav-utility .nav-link:focus {
  border-bottom: 2px solid #49595e;
}

@media (min-width: 992px) {
  .nav-tabs.nav-tabs-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .nav-tabs.nav-tabs-lg .nav-item + .nav-item {
    margin-left: .5rem;
  }
  .nav-tabs.nav-tabs-lg .nav-link {
    padding: 1.25em 1.75em;
    background-color: #e9ecef;
    border: 1px solid #e9ecef;
  }
  .nav-tabs.nav-tabs-lg .nav-link.active, .nav-tabs.nav-tabs-lg .nav-link:active, .nav-tabs.nav-tabs-lg .nav-link.is-active {
    background-color: #fff;
    border-color: #e9ecef #e9ecef #fff;
  }
}

.navbar {
  padding: 0 1rem;
}

.navbar-nav .nav-link {
  font-weight: 600;
  text-transform: none;
  background-color: inherit;
  border: none;
}

.navbar-nav .nav-link,
.navbar-brand {
  padding: 1.25rem 1rem;
}

.navbar-nav-scroll {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.navbar-light, .navbar-default {
  border-color: #e9ecef;
  border-style: solid;
  border-width: 1px 0;
}

.navbar-light .nav-item .nav-link, .navbar-default .nav-item .nav-link {
  color: #1e5288;
}

.navbar-light .nav-item .nav-link:hover, .navbar-default .nav-item .nav-link:hover {
  color: #001c48;
}

.navbar-light .nav-item:hover, .navbar-default .nav-item:hover {
  color: #001c48;
  background-color: #f8f9fa;
}

.navbar-light .nav-item.active .nav-link, .navbar-default .nav-item.active .nav-link, .navbar-light .nav-item:active .nav-link, .navbar-default .nav-item:active .nav-link {
  color: #001c48;
  background-color: #e9ecef;
}

.offcanvas-toggle {
  display: none;
}

.offcanvas-toggle-body-freeze {
  overflow-y: hidden;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: .75;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: .75;
  }
}

.menu-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1999;
  background-color: #403635;
  opacity: .75;
}

.menu-backdrop.show {
  cursor: pointer;
  -webkit-animation: .3s linear fadein;
  animation: .3s linear fadein;
}

.navbar-offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 325px;
  max-width: 90%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  background-color: #0c234b;
  border: none;
  /* stylelint-disable */
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  -webkit-transform: translateX(100vw);
  transform: translateX(100vw);
}

.navbar-offcanvas.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  /* Account for horizontal padding on navbar */
}

.navbar-offcanvas .navbar-offcanvas-header {
  display: block;
  width: 100%;
}

.navbar-offcanvas .navbar-offcanvas-home {
  font-weight: 400;
  color: #fff;
  text-decoration: none;
}

.navbar-offcanvas .navbar-offcanvas-search .form-control {
  height: auto;
  margin: 0;
  border: none;
}

.navbar-offcanvas .navbar-offcanvas-search .input-group input.form-control {
  height: 60px;
  padding-left: 20px;
}

.navbar-offcanvas .navbar-offcanvas-search .input-group .input-group-append > button.btn-search {
  height: 60px;
  width: 60px;
  font-size: 24px;
  border: 0;
  padding: 0;
}

.navbar-offcanvas .navbar-nav {
  width: 100%;
  overflow-y: auto;
}

.navbar-offcanvas .navbar-nav .show > .nav-link {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.navbar-offcanvas .navbar-nav .show > .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.navbar-offcanvas .nav-item-parent .nav-link {
  text-transform: none;
}

.navbar-offcanvas .nav-item.active {
  color: #fff;
  background-color: #1e5288;
}

.navbar-offcanvas .nav-item.show .dropdown-toggle,
.navbar-offcanvas .nav-item.show .dropdown-toggle:hover,
.navbar-offcanvas .nav-item.show .dropdown-toggle:focus {
  background-color: rgba(255, 255, 255, 0.15);
  cursor: pointer;
}

.navbar-offcanvas .nav-item.show .dropdown-toggle::after {
  content: "\e15b";
}

.navbar-offcanvas .nav-item .nav-link {
  padding: 12px 20px;
  color: #fff;
  text-align: left;
  width: 100%;
  min-height: 48px;
}

.navbar-offcanvas .nav-item .nav-link.disabled {
  font-weight: 400;
  color: #adb5bd;
}

.navbar-offcanvas .nav-item .nav-link.disabled:hover {
  background-color: #0c234b !important;
}

.navbar-offcanvas .nav-item .nav-link:hover, .navbar-offcanvas .nav-item .nav-link:focus {
  color: #fff;
  background-color: #1e5288;
}

.navbar-offcanvas .dropdown-menu {
  padding: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.15);
  border: none;
}

.navbar-offcanvas .dropdown-menu .dropdown-item {
  padding: 14px 20px 14px 35px;
  white-space: normal;
}

.navbar-offcanvas .dropdown-toggle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  white-space: normal;
}

.navbar-offcanvas .dropdown-toggle::after {
  font-size: 1rem;
  vertical-align: unset;
  content: "\e145";
  border: none;
  margin-left: 20px;
  font-family: 'Material Icons Sharp';
}

.navbar-offcanvas .dropdown-divider, .navbar-offcanvas .divider,
.navbar-offcanvas .nav-divider {
  margin: 0;
}

.container .navbar-offcanvas.open {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  /* Account for horizontal padding on container */
}

.btn-menu {
  width: 60px;
  height: 50px;
  border: 0;
  padding: 0;
  font-size: 10px;
  background-color: #ab0520;
}

.btn-menu:hover {
  background-color: #8b0015;
}

.btn-menu span.material-icons-sharp {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.btn-menu-offcanvas-nav {
  width: 60px;
  height: 50px;
  border: 0;
  padding: 0;
  font-size: 10px;
}

.btn-menu-offcanvas-nav span.material-icons-sharp {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

@media (min-width: 992px) {
  .navbar-offcanvas {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 1rem;
    padding: initial;
    overflow-y: initial;
    background-color: initial;
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
    -webkit-transform: initial;
    transform: initial;
    top: initial;
    right: initial;
    z-index: initial;
    -ms-flex-direction: initial;
    flex-direction: initial;
    width: initial;
    height: initial;
    transition: initial;
  }
  .navbar-offcanvas .navbar .container, .navbar .navbar-offcanvas .container,
  .navbar-offcanvas .navbar .container-fluid, .navbar .navbar-offcanvas .container-fluid, .navbar-offcanvas .navbar .container-sm, .navbar .navbar-offcanvas .container-sm, .navbar-offcanvas .navbar .container-md, .navbar .navbar-offcanvas .container-md, .navbar-offcanvas .navbar .container-lg, .navbar .navbar-offcanvas .container-lg, .navbar-offcanvas .navbar .container-xl, .navbar .navbar-offcanvas .container-xl {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .navbar-offcanvas.open {
    -webkit-transform: initial;
    transform: initial;
  }
  .navbar-offcanvas .navbar-offcanvas-header {
    display: none;
  }
  .navbar-offcanvas .navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    width: initial;
    overflow-y: initial;
  }
  .navbar-offcanvas .navbar-nav .nav-link {
    padding: 0.5rem 1rem;
  }
  .navbar-offcanvas .navbar-nav .nav-link:hover, .navbar-offcanvas .navbar-nav .nav-link:focus {
    text-decoration: none;
  }
  .navbar-offcanvas .navbar-nav .nav-link.disabled {
    color: #495057;
    pointer-events: none;
    cursor: default;
  }
  .navbar-offcanvas .nav-item .nav-link {
    padding: 1.25rem 1rem;
    color: #1e5288;
  }
  .navbar-offcanvas .nav-item .nav-link:hover {
    color: #001c48;
    background-color: #f8f9fa;
  }
  .navbar-offcanvas .nav-item.active, .navbar-offcanvas .nav-item:active {
    color: #001c48;
    background-color: #e9ecef;
  }
  .navbar-offcanvas .nav-item:hover {
    color: #001c48;
    background-color: #f8f9fa;
  }
  .navbar-offcanvas .nav-item.show .dropdown-toggle,
  .navbar-offcanvas .nav-item.show .dropdown-toggle:hover,
  .navbar-offcanvas .nav-item.show .dropdown-toggle:focus {
    background-color: #f8f9fa;
    color: rgba(0, 0, 0, 0.9);
  }
  .navbar-offcanvas .nav-item.show .dropdown-toggle::after {
    content: "";
  }
  .navbar-offcanvas .dropdown-menu {
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    background-color: #0c234b;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: absolute;
  }
  .navbar-offcanvas .dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem;
    white-space: nowrap;
  }
  .navbar-offcanvas .dropdown-toggle {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .navbar-offcanvas .dropdown-toggle::after {
    font-size: inherit;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    margin-left: 0.225em;
    display: inline-table;
  }
  .menu-backdrop {
    display: none;
  }
}

.background-wrapper {
  width: 100%;
  padding: 3rem 0;
}

.bg-triangles-top-left {
  background-image: url("img/triangles-top-left.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto;
  background-blend-mode: multiply;
}

.bg-red.bg-triangles-top-left {
  background-image: url("img/triangles-top-left-red.svg");
  background-blend-mode: initial;
}

.bg-blue.bg-triangles-top-left, .panel-primary .bg-triangles-top-left.panel-heading, .bg-triangles-top-left.bg-primary, .bg-triangles-top-left.card-primary {
  background-image: url("img/triangles-top-left-blue.svg");
  background-blend-mode: initial;
}

.bg-triangles-top-right {
  background-image: url("img/triangles-top-right.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;
  background-blend-mode: multiply;
}

.bg-red.bg-triangles-top-right {
  background-image: url("img/triangles-top-right-red.svg");
  background-blend-mode: initial;
}

.bg-blue.bg-triangles-top-right, .panel-primary .bg-triangles-top-right.panel-heading, .bg-triangles-top-right.bg-primary, .bg-triangles-top-right.card-primary {
  background-image: url("img/triangles-top-right-blue.svg");
  background-blend-mode: initial;
}

.bg-triangles-centered {
  background-image: url("img/triangles-centered.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto;
  background-blend-mode: multiply;
}

.bg-red.bg-triangles-centered {
  background-image: url("img/triangles-centered-red.svg");
  background-blend-mode: initial;
}

.bg-blue.bg-triangles-centered, .panel-primary .bg-triangles-centered.panel-heading, .bg-triangles-centered.bg-primary, .bg-triangles-centered.card-primary {
  background-image: url("img/triangles-centered-blue.svg");
  background-blend-mode: initial;
}

.bg-trilines {
  background-image: url("img/trilines.svg");
  background-size: auto 305px;
  background-position: top right;
  background-position: top 0 right -1em;
  background-repeat: no-repeat;
}

.bg-trilines p,
.bg-trilines li,
.bg-trilines hr {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-trilines.bg-blue p, .panel-primary .bg-trilines.panel-heading p, .bg-trilines.bg-primary p, .bg-trilines.card-primary p,
.bg-trilines.bg-blue li,
.panel-primary .bg-trilines.panel-heading li,
.bg-trilines.bg-primary li,
.bg-trilines.card-primary li,
.bg-trilines.bg-blue hr,
.panel-primary .bg-trilines.panel-heading hr,
.bg-trilines.bg-primary hr,
.bg-trilines.card-primary hr {
  background-color: rgba(12, 35, 75, 0.7);
}

.bg-trilines.bg-red p,
.bg-trilines.bg-red li,
.bg-trilines.bg-red hr {
  background-color: rgba(171, 5, 32, 0.7);
}

.bg-trilines.bg-white p, .bg-trilines.panel p, .bg-trilines.well.well-hollow p, .modal-bg-dark .bg-trilines.modal-content p,
.bg-trilines.bg-white li,
.bg-trilines.panel li,
.bg-trilines.well.well-hollow li,
.modal-bg-dark .bg-trilines.modal-content li,
.bg-trilines.bg-white hr,
.bg-trilines.panel hr,
.bg-trilines.well.well-hollow hr,
.modal-bg-dark .bg-trilines.modal-content hr {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-trilines.bg-bloom p,
.bg-trilines.bg-bloom li,
.bg-trilines.bg-bloom hr {
  background-color: rgba(239, 64, 86, 0.7);
}

.bg-trilines.bg-chili p,
.bg-trilines.bg-chili li,
.bg-trilines.bg-chili hr {
  background-color: rgba(139, 0, 21, 0.7);
}

.bg-trilines.bg-sky p,
.bg-trilines.bg-sky li,
.bg-trilines.bg-sky hr {
  background-color: rgba(129, 211, 235, 0.7);
}

.bg-trilines.bg-oasis p,
.bg-trilines.bg-oasis li,
.bg-trilines.bg-oasis hr {
  background-color: rgba(55, 141, 189, 0.7);
}

.bg-trilines.bg-azurite p,
.bg-trilines.bg-azurite li,
.bg-trilines.bg-azurite hr {
  background-color: rgba(30, 82, 136, 0.7);
}

.bg-trilines.bg-midnight p,
.bg-trilines.bg-midnight li,
.bg-trilines.bg-midnight hr {
  background-color: rgba(0, 28, 72, 0.7);
}

.bg-trilines.bg-cool-gray p,
.bg-trilines.bg-cool-gray li,
.bg-trilines.bg-cool-gray hr {
  background-color: rgba(226, 233, 235, 0.7);
}

.bg-trilines.bg-warm-gray p,
.bg-trilines.bg-warm-gray li,
.bg-trilines.bg-warm-gray hr {
  background-color: rgba(244, 237, 229, 0.7);
}

.bg-trilines.bg-leaf p,
.bg-trilines.bg-leaf li,
.bg-trilines.bg-leaf hr {
  background-color: rgba(112, 184, 101, 0.7);
}

.bg-trilines.bg-river p,
.bg-trilines.bg-river li,
.bg-trilines.bg-river hr {
  background-color: rgba(0, 125, 132, 0.7);
}

.bg-trilines.bg-silver p,
.bg-trilines.bg-silver li,
.bg-trilines.bg-silver hr {
  background-color: rgba(158, 171, 174, 0.7);
}

.bg-trilines.bg-mesa p,
.bg-trilines.bg-mesa li,
.bg-trilines.bg-mesa hr {
  background-color: rgba(169, 92, 66, 0.7);
}

.bg-trilines.bg-ash p,
.bg-trilines.bg-ash li,
.bg-trilines.bg-ash hr {
  background-color: rgba(64, 54, 53, 0.7);
}

.bg-trilines.bg-sage p,
.bg-trilines.bg-sage li,
.bg-trilines.bg-sage hr {
  background-color: rgba(74, 99, 78, 0.7);
}

.bg-trilines.bg-black p,
.bg-trilines.bg-black li,
.bg-trilines.bg-black hr {
  background-color: rgba(0, 0, 0, 0.7);
}

.hover:hover .hover-text-underline,
.hover:focus .hover-text-underline {
  text-decoration: underline;
}

.hover:hover img.hover-img-zoom-in,
.hover:focus img.hover-img-zoom-in {
  transition: ease .25s;
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
}

.callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.callout p:last-child {
  margin-bottom: 0;
}

.callout + .callout {
  margin-top: -.25rem;
}

.callout-blue {
  border-left-color: #0c234b;
}

.callout-blue h2,
.callout-blue h3,
.callout-blue h4,
.callout-blue h5,
.callout-blue h6,
.callout-blue .h2,
.callout-blue .text-size-h2,
.callout-blue .h3,
.callout-blue .text-size-h3,
.callout-blue .h4,
.callout-blue .text-size-h4,
.callout-blue .h5,
.callout-blue .text-size-h5,
.callout-blue .h6,
.callout-blue .text-size-h6 {
  color: #0c234b;
}

.callout-red {
  border-left-color: #ab0520;
}

.callout-red h2,
.callout-red h3,
.callout-red h4,
.callout-red h5,
.callout-red h6,
.callout-red .h2,
.callout-red .text-size-h2,
.callout-red .h3,
.callout-red .text-size-h3,
.callout-red .h4,
.callout-red .text-size-h4,
.callout-red .h5,
.callout-red .text-size-h5,
.callout-red .h6,
.callout-red .text-size-h6 {
  color: #ab0520;
}

.callout-white {
  border-left-color: #fff;
}

.callout-white h2,
.callout-white h3,
.callout-white h4,
.callout-white h5,
.callout-white h6,
.callout-white .h2,
.callout-white .text-size-h2,
.callout-white .h3,
.callout-white .text-size-h3,
.callout-white .h4,
.callout-white .text-size-h4,
.callout-white .h5,
.callout-white .text-size-h5,
.callout-white .h6,
.callout-white .text-size-h6 {
  color: #fff;
}

.callout-bloom {
  border-left-color: #ef4056;
}

.callout-bloom h2,
.callout-bloom h3,
.callout-bloom h4,
.callout-bloom h5,
.callout-bloom h6,
.callout-bloom .h2,
.callout-bloom .text-size-h2,
.callout-bloom .h3,
.callout-bloom .text-size-h3,
.callout-bloom .h4,
.callout-bloom .text-size-h4,
.callout-bloom .h5,
.callout-bloom .text-size-h5,
.callout-bloom .h6,
.callout-bloom .text-size-h6 {
  color: #ef4056;
}

.callout-chili {
  border-left-color: #8b0015;
}

.callout-chili h2,
.callout-chili h3,
.callout-chili h4,
.callout-chili h5,
.callout-chili h6,
.callout-chili .h2,
.callout-chili .text-size-h2,
.callout-chili .h3,
.callout-chili .text-size-h3,
.callout-chili .h4,
.callout-chili .text-size-h4,
.callout-chili .h5,
.callout-chili .text-size-h5,
.callout-chili .h6,
.callout-chili .text-size-h6 {
  color: #8b0015;
}

.callout-sky {
  border-left-color: #81d3eb;
}

.callout-sky h2,
.callout-sky h3,
.callout-sky h4,
.callout-sky h5,
.callout-sky h6,
.callout-sky .h2,
.callout-sky .text-size-h2,
.callout-sky .h3,
.callout-sky .text-size-h3,
.callout-sky .h4,
.callout-sky .text-size-h4,
.callout-sky .h5,
.callout-sky .text-size-h5,
.callout-sky .h6,
.callout-sky .text-size-h6 {
  color: #81d3eb;
}

.callout-oasis {
  border-left-color: #378dbd;
}

.callout-oasis h2,
.callout-oasis h3,
.callout-oasis h4,
.callout-oasis h5,
.callout-oasis h6,
.callout-oasis .h2,
.callout-oasis .text-size-h2,
.callout-oasis .h3,
.callout-oasis .text-size-h3,
.callout-oasis .h4,
.callout-oasis .text-size-h4,
.callout-oasis .h5,
.callout-oasis .text-size-h5,
.callout-oasis .h6,
.callout-oasis .text-size-h6 {
  color: #378dbd;
}

.callout-azurite {
  border-left-color: #1e5288;
}

.callout-azurite h2,
.callout-azurite h3,
.callout-azurite h4,
.callout-azurite h5,
.callout-azurite h6,
.callout-azurite .h2,
.callout-azurite .text-size-h2,
.callout-azurite .h3,
.callout-azurite .text-size-h3,
.callout-azurite .h4,
.callout-azurite .text-size-h4,
.callout-azurite .h5,
.callout-azurite .text-size-h5,
.callout-azurite .h6,
.callout-azurite .text-size-h6 {
  color: #1e5288;
}

.callout-midnight {
  border-left-color: #001c48;
}

.callout-midnight h2,
.callout-midnight h3,
.callout-midnight h4,
.callout-midnight h5,
.callout-midnight h6,
.callout-midnight .h2,
.callout-midnight .text-size-h2,
.callout-midnight .h3,
.callout-midnight .text-size-h3,
.callout-midnight .h4,
.callout-midnight .text-size-h4,
.callout-midnight .h5,
.callout-midnight .text-size-h5,
.callout-midnight .h6,
.callout-midnight .text-size-h6 {
  color: #001c48;
}

.callout-cool-gray {
  border-left-color: #e2e9eb;
}

.callout-cool-gray h2,
.callout-cool-gray h3,
.callout-cool-gray h4,
.callout-cool-gray h5,
.callout-cool-gray h6,
.callout-cool-gray .h2,
.callout-cool-gray .text-size-h2,
.callout-cool-gray .h3,
.callout-cool-gray .text-size-h3,
.callout-cool-gray .h4,
.callout-cool-gray .text-size-h4,
.callout-cool-gray .h5,
.callout-cool-gray .text-size-h5,
.callout-cool-gray .h6,
.callout-cool-gray .text-size-h6 {
  color: #e2e9eb;
}

.callout-warm-gray {
  border-left-color: #f4ede5;
}

.callout-warm-gray h2,
.callout-warm-gray h3,
.callout-warm-gray h4,
.callout-warm-gray h5,
.callout-warm-gray h6,
.callout-warm-gray .h2,
.callout-warm-gray .text-size-h2,
.callout-warm-gray .h3,
.callout-warm-gray .text-size-h3,
.callout-warm-gray .h4,
.callout-warm-gray .text-size-h4,
.callout-warm-gray .h5,
.callout-warm-gray .text-size-h5,
.callout-warm-gray .h6,
.callout-warm-gray .text-size-h6 {
  color: #f4ede5;
}

.callout-leaf {
  border-left-color: #70b865;
}

.callout-leaf h2,
.callout-leaf h3,
.callout-leaf h4,
.callout-leaf h5,
.callout-leaf h6,
.callout-leaf .h2,
.callout-leaf .text-size-h2,
.callout-leaf .h3,
.callout-leaf .text-size-h3,
.callout-leaf .h4,
.callout-leaf .text-size-h4,
.callout-leaf .h5,
.callout-leaf .text-size-h5,
.callout-leaf .h6,
.callout-leaf .text-size-h6 {
  color: #70b865;
}

.callout-river {
  border-left-color: #007d84;
}

.callout-river h2,
.callout-river h3,
.callout-river h4,
.callout-river h5,
.callout-river h6,
.callout-river .h2,
.callout-river .text-size-h2,
.callout-river .h3,
.callout-river .text-size-h3,
.callout-river .h4,
.callout-river .text-size-h4,
.callout-river .h5,
.callout-river .text-size-h5,
.callout-river .h6,
.callout-river .text-size-h6 {
  color: #007d84;
}

.callout-silver {
  border-left-color: #9eabae;
}

.callout-silver h2,
.callout-silver h3,
.callout-silver h4,
.callout-silver h5,
.callout-silver h6,
.callout-silver .h2,
.callout-silver .text-size-h2,
.callout-silver .h3,
.callout-silver .text-size-h3,
.callout-silver .h4,
.callout-silver .text-size-h4,
.callout-silver .h5,
.callout-silver .text-size-h5,
.callout-silver .h6,
.callout-silver .text-size-h6 {
  color: #9eabae;
}

.callout-mesa {
  border-left-color: #a95c42;
}

.callout-mesa h2,
.callout-mesa h3,
.callout-mesa h4,
.callout-mesa h5,
.callout-mesa h6,
.callout-mesa .h2,
.callout-mesa .text-size-h2,
.callout-mesa .h3,
.callout-mesa .text-size-h3,
.callout-mesa .h4,
.callout-mesa .text-size-h4,
.callout-mesa .h5,
.callout-mesa .text-size-h5,
.callout-mesa .h6,
.callout-mesa .text-size-h6 {
  color: #a95c42;
}

.callout-ash {
  border-left-color: #403635;
}

.callout-ash h2,
.callout-ash h3,
.callout-ash h4,
.callout-ash h5,
.callout-ash h6,
.callout-ash .h2,
.callout-ash .text-size-h2,
.callout-ash .h3,
.callout-ash .text-size-h3,
.callout-ash .h4,
.callout-ash .text-size-h4,
.callout-ash .h5,
.callout-ash .text-size-h5,
.callout-ash .h6,
.callout-ash .text-size-h6 {
  color: #403635;
}

.callout-sage {
  border-left-color: #4a634e;
}

.callout-sage h2,
.callout-sage h3,
.callout-sage h4,
.callout-sage h5,
.callout-sage h6,
.callout-sage .h2,
.callout-sage .text-size-h2,
.callout-sage .h3,
.callout-sage .text-size-h3,
.callout-sage .h4,
.callout-sage .text-size-h4,
.callout-sage .h5,
.callout-sage .text-size-h5,
.callout-sage .h6,
.callout-sage .text-size-h6 {
  color: #4a634e;
}

.callout-black {
  border-left-color: #000;
}

.callout-black h2,
.callout-black h3,
.callout-black h4,
.callout-black h5,
.callout-black h6,
.callout-black .h2,
.callout-black .text-size-h2,
.callout-black .h3,
.callout-black .text-size-h3,
.callout-black .h4,
.callout-black .text-size-h4,
.callout-black .h5,
.callout-black .text-size-h5,
.callout-black .h6,
.callout-black .text-size-h6 {
  color: #000;
}

.callout-silver h1,
.callout-silver h2,
.callout-silver h3,
.callout-silver h4,
.callout-silver h5,
.callout-silver h6,
.callout-silver .h1,
.callout-silver .text-size-h1,
.callout-silver .h2,
.callout-silver .text-size-h2,
.callout-silver .h3,
.callout-silver .text-size-h3,
.callout-silver .h4,
.callout-silver .text-size-h4,
.callout-silver .h5,
.callout-silver .text-size-h5,
.callout-silver .h6,
.callout-silver .text-size-h6,
.callout-cool-gray h1,
.callout-cool-gray h2,
.callout-cool-gray h3,
.callout-cool-gray h4,
.callout-cool-gray h5,
.callout-cool-gray h6,
.callout-cool-gray .h1,
.callout-cool-gray .text-size-h1,
.callout-cool-gray .h2,
.callout-cool-gray .text-size-h2,
.callout-cool-gray .h3,
.callout-cool-gray .text-size-h3,
.callout-cool-gray .h4,
.callout-cool-gray .text-size-h4,
.callout-cool-gray .h5,
.callout-cool-gray .text-size-h5,
.callout-cool-gray .h6,
.callout-cool-gray .text-size-h6,
.callout-warm-gray h1,
.callout-warm-gray h2,
.callout-warm-gray h3,
.callout-warm-gray h4,
.callout-warm-gray h5,
.callout-warm-gray h6,
.callout-warm-gray .h1,
.callout-warm-gray .text-size-h1,
.callout-warm-gray .h2,
.callout-warm-gray .text-size-h2,
.callout-warm-gray .h3,
.callout-warm-gray .text-size-h3,
.callout-warm-gray .h4,
.callout-warm-gray .text-size-h4,
.callout-warm-gray .h5,
.callout-warm-gray .text-size-h5,
.callout-warm-gray .h6,
.callout-warm-gray .text-size-h6,
.callout-sky h1,
.callout-sky h2,
.callout-sky h3,
.callout-sky h4,
.callout-sky h5,
.callout-sky h6,
.callout-sky .h1,
.callout-sky .text-size-h1,
.callout-sky .h2,
.callout-sky .text-size-h2,
.callout-sky .h3,
.callout-sky .text-size-h3,
.callout-sky .h4,
.callout-sky .text-size-h4,
.callout-sky .h5,
.callout-sky .text-size-h5,
.callout-sky .h6,
.callout-sky .text-size-h6 {
  color: #49595e;
}

.callout-silver h1.text-blue, .callout-silver h1.text-primary,
.callout-silver h2.text-blue,
.callout-silver h2.text-primary,
.callout-silver h3.text-blue,
.callout-silver h3.text-primary,
.callout-silver h4.text-blue,
.callout-silver h4.text-primary,
.callout-silver h5.text-blue,
.callout-silver h5.text-primary,
.callout-silver h6.text-blue,
.callout-silver h6.text-primary,
.callout-silver .h1.text-blue,
.callout-silver .h1.text-primary,
.callout-silver .text-primary.text-size-h1,
.callout-silver .text-blue.text-size-h1,
.callout-silver .h2.text-blue,
.callout-silver .h2.text-primary,
.callout-silver .text-primary.text-size-h2,
.callout-silver .text-blue.text-size-h2,
.callout-silver .h3.text-blue,
.callout-silver .h3.text-primary,
.callout-silver .text-primary.text-size-h3,
.callout-silver .text-blue.text-size-h3,
.callout-silver .h4.text-blue,
.callout-silver .h4.text-primary,
.callout-silver .text-primary.text-size-h4,
.callout-silver .text-blue.text-size-h4,
.callout-silver .h5.text-blue,
.callout-silver .h5.text-primary,
.callout-silver .text-primary.text-size-h5,
.callout-silver .text-blue.text-size-h5,
.callout-silver .h6.text-blue,
.callout-silver .h6.text-primary,
.callout-silver .text-primary.text-size-h6,
.callout-silver .text-blue.text-size-h6,
.callout-cool-gray h1.text-blue,
.callout-cool-gray h1.text-primary,
.callout-cool-gray h2.text-blue,
.callout-cool-gray h2.text-primary,
.callout-cool-gray h3.text-blue,
.callout-cool-gray h3.text-primary,
.callout-cool-gray h4.text-blue,
.callout-cool-gray h4.text-primary,
.callout-cool-gray h5.text-blue,
.callout-cool-gray h5.text-primary,
.callout-cool-gray h6.text-blue,
.callout-cool-gray h6.text-primary,
.callout-cool-gray .h1.text-blue,
.callout-cool-gray .h1.text-primary,
.callout-cool-gray .text-primary.text-size-h1,
.callout-cool-gray .text-blue.text-size-h1,
.callout-cool-gray .h2.text-blue,
.callout-cool-gray .h2.text-primary,
.callout-cool-gray .text-primary.text-size-h2,
.callout-cool-gray .text-blue.text-size-h2,
.callout-cool-gray .h3.text-blue,
.callout-cool-gray .h3.text-primary,
.callout-cool-gray .text-primary.text-size-h3,
.callout-cool-gray .text-blue.text-size-h3,
.callout-cool-gray .h4.text-blue,
.callout-cool-gray .h4.text-primary,
.callout-cool-gray .text-primary.text-size-h4,
.callout-cool-gray .text-blue.text-size-h4,
.callout-cool-gray .h5.text-blue,
.callout-cool-gray .h5.text-primary,
.callout-cool-gray .text-primary.text-size-h5,
.callout-cool-gray .text-blue.text-size-h5,
.callout-cool-gray .h6.text-blue,
.callout-cool-gray .h6.text-primary,
.callout-cool-gray .text-primary.text-size-h6,
.callout-cool-gray .text-blue.text-size-h6,
.callout-warm-gray h1.text-blue,
.callout-warm-gray h1.text-primary,
.callout-warm-gray h2.text-blue,
.callout-warm-gray h2.text-primary,
.callout-warm-gray h3.text-blue,
.callout-warm-gray h3.text-primary,
.callout-warm-gray h4.text-blue,
.callout-warm-gray h4.text-primary,
.callout-warm-gray h5.text-blue,
.callout-warm-gray h5.text-primary,
.callout-warm-gray h6.text-blue,
.callout-warm-gray h6.text-primary,
.callout-warm-gray .h1.text-blue,
.callout-warm-gray .h1.text-primary,
.callout-warm-gray .text-primary.text-size-h1,
.callout-warm-gray .text-blue.text-size-h1,
.callout-warm-gray .h2.text-blue,
.callout-warm-gray .h2.text-primary,
.callout-warm-gray .text-primary.text-size-h2,
.callout-warm-gray .text-blue.text-size-h2,
.callout-warm-gray .h3.text-blue,
.callout-warm-gray .h3.text-primary,
.callout-warm-gray .text-primary.text-size-h3,
.callout-warm-gray .text-blue.text-size-h3,
.callout-warm-gray .h4.text-blue,
.callout-warm-gray .h4.text-primary,
.callout-warm-gray .text-primary.text-size-h4,
.callout-warm-gray .text-blue.text-size-h4,
.callout-warm-gray .h5.text-blue,
.callout-warm-gray .h5.text-primary,
.callout-warm-gray .text-primary.text-size-h5,
.callout-warm-gray .text-blue.text-size-h5,
.callout-warm-gray .h6.text-blue,
.callout-warm-gray .h6.text-primary,
.callout-warm-gray .text-primary.text-size-h6,
.callout-warm-gray .text-blue.text-size-h6,
.callout-sky h1.text-blue,
.callout-sky h1.text-primary,
.callout-sky h2.text-blue,
.callout-sky h2.text-primary,
.callout-sky h3.text-blue,
.callout-sky h3.text-primary,
.callout-sky h4.text-blue,
.callout-sky h4.text-primary,
.callout-sky h5.text-blue,
.callout-sky h5.text-primary,
.callout-sky h6.text-blue,
.callout-sky h6.text-primary,
.callout-sky .h1.text-blue,
.callout-sky .h1.text-primary,
.callout-sky .text-primary.text-size-h1,
.callout-sky .text-blue.text-size-h1,
.callout-sky .h2.text-blue,
.callout-sky .h2.text-primary,
.callout-sky .text-primary.text-size-h2,
.callout-sky .text-blue.text-size-h2,
.callout-sky .h3.text-blue,
.callout-sky .h3.text-primary,
.callout-sky .text-primary.text-size-h3,
.callout-sky .text-blue.text-size-h3,
.callout-sky .h4.text-blue,
.callout-sky .h4.text-primary,
.callout-sky .text-primary.text-size-h4,
.callout-sky .text-blue.text-size-h4,
.callout-sky .h5.text-blue,
.callout-sky .h5.text-primary,
.callout-sky .text-primary.text-size-h5,
.callout-sky .text-blue.text-size-h5,
.callout-sky .h6.text-blue,
.callout-sky .h6.text-primary,
.callout-sky .text-primary.text-size-h6,
.callout-sky .text-blue.text-size-h6 {
  color: #0c234b;
}

.callout-silver h1.text-red,
.callout-silver h2.text-red,
.callout-silver h3.text-red,
.callout-silver h4.text-red,
.callout-silver h5.text-red,
.callout-silver h6.text-red,
.callout-silver .h1.text-red,
.callout-silver .text-red.text-size-h1,
.callout-silver .h2.text-red,
.callout-silver .text-red.text-size-h2,
.callout-silver .h3.text-red,
.callout-silver .text-red.text-size-h3,
.callout-silver .h4.text-red,
.callout-silver .text-red.text-size-h4,
.callout-silver .h5.text-red,
.callout-silver .text-red.text-size-h5,
.callout-silver .h6.text-red,
.callout-silver .text-red.text-size-h6,
.callout-cool-gray h1.text-red,
.callout-cool-gray h2.text-red,
.callout-cool-gray h3.text-red,
.callout-cool-gray h4.text-red,
.callout-cool-gray h5.text-red,
.callout-cool-gray h6.text-red,
.callout-cool-gray .h1.text-red,
.callout-cool-gray .text-red.text-size-h1,
.callout-cool-gray .h2.text-red,
.callout-cool-gray .text-red.text-size-h2,
.callout-cool-gray .h3.text-red,
.callout-cool-gray .text-red.text-size-h3,
.callout-cool-gray .h4.text-red,
.callout-cool-gray .text-red.text-size-h4,
.callout-cool-gray .h5.text-red,
.callout-cool-gray .text-red.text-size-h5,
.callout-cool-gray .h6.text-red,
.callout-cool-gray .text-red.text-size-h6,
.callout-warm-gray h1.text-red,
.callout-warm-gray h2.text-red,
.callout-warm-gray h3.text-red,
.callout-warm-gray h4.text-red,
.callout-warm-gray h5.text-red,
.callout-warm-gray h6.text-red,
.callout-warm-gray .h1.text-red,
.callout-warm-gray .text-red.text-size-h1,
.callout-warm-gray .h2.text-red,
.callout-warm-gray .text-red.text-size-h2,
.callout-warm-gray .h3.text-red,
.callout-warm-gray .text-red.text-size-h3,
.callout-warm-gray .h4.text-red,
.callout-warm-gray .text-red.text-size-h4,
.callout-warm-gray .h5.text-red,
.callout-warm-gray .text-red.text-size-h5,
.callout-warm-gray .h6.text-red,
.callout-warm-gray .text-red.text-size-h6,
.callout-sky h1.text-red,
.callout-sky h2.text-red,
.callout-sky h3.text-red,
.callout-sky h4.text-red,
.callout-sky h5.text-red,
.callout-sky h6.text-red,
.callout-sky .h1.text-red,
.callout-sky .text-red.text-size-h1,
.callout-sky .h2.text-red,
.callout-sky .text-red.text-size-h2,
.callout-sky .h3.text-red,
.callout-sky .text-red.text-size-h3,
.callout-sky .h4.text-red,
.callout-sky .text-red.text-size-h4,
.callout-sky .h5.text-red,
.callout-sky .text-red.text-size-h5,
.callout-sky .h6.text-red,
.callout-sky .text-red.text-size-h6 {
  color: #ab0520;
}

.callout-silver h1.text-white, .callout-silver h1.card-inverse,
.callout-silver h2.text-white,
.callout-silver h2.card-inverse,
.callout-silver h3.text-white,
.callout-silver h3.card-inverse,
.callout-silver h4.text-white,
.callout-silver h4.card-inverse,
.callout-silver h5.text-white,
.callout-silver h5.card-inverse,
.callout-silver h6.text-white,
.callout-silver h6.card-inverse,
.callout-silver .h1.text-white,
.callout-silver .h1.card-inverse,
.callout-silver .card-inverse.text-size-h1,
.callout-silver .text-white.text-size-h1,
.callout-silver .h2.text-white,
.callout-silver .h2.card-inverse,
.callout-silver .card-inverse.text-size-h2,
.callout-silver .text-white.text-size-h2,
.callout-silver .h3.text-white,
.callout-silver .h3.card-inverse,
.callout-silver .card-inverse.text-size-h3,
.callout-silver .text-white.text-size-h3,
.callout-silver .h4.text-white,
.callout-silver .h4.card-inverse,
.callout-silver .card-inverse.text-size-h4,
.callout-silver .text-white.text-size-h4,
.callout-silver .h5.text-white,
.callout-silver .h5.card-inverse,
.callout-silver .card-inverse.text-size-h5,
.callout-silver .text-white.text-size-h5,
.callout-silver .h6.text-white,
.callout-silver .h6.card-inverse,
.callout-silver .card-inverse.text-size-h6,
.callout-silver .text-white.text-size-h6,
.callout-cool-gray h1.text-white,
.callout-cool-gray h1.card-inverse,
.callout-cool-gray h2.text-white,
.callout-cool-gray h2.card-inverse,
.callout-cool-gray h3.text-white,
.callout-cool-gray h3.card-inverse,
.callout-cool-gray h4.text-white,
.callout-cool-gray h4.card-inverse,
.callout-cool-gray h5.text-white,
.callout-cool-gray h5.card-inverse,
.callout-cool-gray h6.text-white,
.callout-cool-gray h6.card-inverse,
.callout-cool-gray .h1.text-white,
.callout-cool-gray .h1.card-inverse,
.callout-cool-gray .card-inverse.text-size-h1,
.callout-cool-gray .text-white.text-size-h1,
.callout-cool-gray .h2.text-white,
.callout-cool-gray .h2.card-inverse,
.callout-cool-gray .card-inverse.text-size-h2,
.callout-cool-gray .text-white.text-size-h2,
.callout-cool-gray .h3.text-white,
.callout-cool-gray .h3.card-inverse,
.callout-cool-gray .card-inverse.text-size-h3,
.callout-cool-gray .text-white.text-size-h3,
.callout-cool-gray .h4.text-white,
.callout-cool-gray .h4.card-inverse,
.callout-cool-gray .card-inverse.text-size-h4,
.callout-cool-gray .text-white.text-size-h4,
.callout-cool-gray .h5.text-white,
.callout-cool-gray .h5.card-inverse,
.callout-cool-gray .card-inverse.text-size-h5,
.callout-cool-gray .text-white.text-size-h5,
.callout-cool-gray .h6.text-white,
.callout-cool-gray .h6.card-inverse,
.callout-cool-gray .card-inverse.text-size-h6,
.callout-cool-gray .text-white.text-size-h6,
.callout-warm-gray h1.text-white,
.callout-warm-gray h1.card-inverse,
.callout-warm-gray h2.text-white,
.callout-warm-gray h2.card-inverse,
.callout-warm-gray h3.text-white,
.callout-warm-gray h3.card-inverse,
.callout-warm-gray h4.text-white,
.callout-warm-gray h4.card-inverse,
.callout-warm-gray h5.text-white,
.callout-warm-gray h5.card-inverse,
.callout-warm-gray h6.text-white,
.callout-warm-gray h6.card-inverse,
.callout-warm-gray .h1.text-white,
.callout-warm-gray .h1.card-inverse,
.callout-warm-gray .card-inverse.text-size-h1,
.callout-warm-gray .text-white.text-size-h1,
.callout-warm-gray .h2.text-white,
.callout-warm-gray .h2.card-inverse,
.callout-warm-gray .card-inverse.text-size-h2,
.callout-warm-gray .text-white.text-size-h2,
.callout-warm-gray .h3.text-white,
.callout-warm-gray .h3.card-inverse,
.callout-warm-gray .card-inverse.text-size-h3,
.callout-warm-gray .text-white.text-size-h3,
.callout-warm-gray .h4.text-white,
.callout-warm-gray .h4.card-inverse,
.callout-warm-gray .card-inverse.text-size-h4,
.callout-warm-gray .text-white.text-size-h4,
.callout-warm-gray .h5.text-white,
.callout-warm-gray .h5.card-inverse,
.callout-warm-gray .card-inverse.text-size-h5,
.callout-warm-gray .text-white.text-size-h5,
.callout-warm-gray .h6.text-white,
.callout-warm-gray .h6.card-inverse,
.callout-warm-gray .card-inverse.text-size-h6,
.callout-warm-gray .text-white.text-size-h6,
.callout-sky h1.text-white,
.callout-sky h1.card-inverse,
.callout-sky h2.text-white,
.callout-sky h2.card-inverse,
.callout-sky h3.text-white,
.callout-sky h3.card-inverse,
.callout-sky h4.text-white,
.callout-sky h4.card-inverse,
.callout-sky h5.text-white,
.callout-sky h5.card-inverse,
.callout-sky h6.text-white,
.callout-sky h6.card-inverse,
.callout-sky .h1.text-white,
.callout-sky .h1.card-inverse,
.callout-sky .card-inverse.text-size-h1,
.callout-sky .text-white.text-size-h1,
.callout-sky .h2.text-white,
.callout-sky .h2.card-inverse,
.callout-sky .card-inverse.text-size-h2,
.callout-sky .text-white.text-size-h2,
.callout-sky .h3.text-white,
.callout-sky .h3.card-inverse,
.callout-sky .card-inverse.text-size-h3,
.callout-sky .text-white.text-size-h3,
.callout-sky .h4.text-white,
.callout-sky .h4.card-inverse,
.callout-sky .card-inverse.text-size-h4,
.callout-sky .text-white.text-size-h4,
.callout-sky .h5.text-white,
.callout-sky .h5.card-inverse,
.callout-sky .card-inverse.text-size-h5,
.callout-sky .text-white.text-size-h5,
.callout-sky .h6.text-white,
.callout-sky .h6.card-inverse,
.callout-sky .card-inverse.text-size-h6,
.callout-sky .text-white.text-size-h6 {
  color: #fff;
}

.callout-silver h1.text-bloom,
.callout-silver h2.text-bloom,
.callout-silver h3.text-bloom,
.callout-silver h4.text-bloom,
.callout-silver h5.text-bloom,
.callout-silver h6.text-bloom,
.callout-silver .h1.text-bloom,
.callout-silver .text-bloom.text-size-h1,
.callout-silver .h2.text-bloom,
.callout-silver .text-bloom.text-size-h2,
.callout-silver .h3.text-bloom,
.callout-silver .text-bloom.text-size-h3,
.callout-silver .h4.text-bloom,
.callout-silver .text-bloom.text-size-h4,
.callout-silver .h5.text-bloom,
.callout-silver .text-bloom.text-size-h5,
.callout-silver .h6.text-bloom,
.callout-silver .text-bloom.text-size-h6,
.callout-cool-gray h1.text-bloom,
.callout-cool-gray h2.text-bloom,
.callout-cool-gray h3.text-bloom,
.callout-cool-gray h4.text-bloom,
.callout-cool-gray h5.text-bloom,
.callout-cool-gray h6.text-bloom,
.callout-cool-gray .h1.text-bloom,
.callout-cool-gray .text-bloom.text-size-h1,
.callout-cool-gray .h2.text-bloom,
.callout-cool-gray .text-bloom.text-size-h2,
.callout-cool-gray .h3.text-bloom,
.callout-cool-gray .text-bloom.text-size-h3,
.callout-cool-gray .h4.text-bloom,
.callout-cool-gray .text-bloom.text-size-h4,
.callout-cool-gray .h5.text-bloom,
.callout-cool-gray .text-bloom.text-size-h5,
.callout-cool-gray .h6.text-bloom,
.callout-cool-gray .text-bloom.text-size-h6,
.callout-warm-gray h1.text-bloom,
.callout-warm-gray h2.text-bloom,
.callout-warm-gray h3.text-bloom,
.callout-warm-gray h4.text-bloom,
.callout-warm-gray h5.text-bloom,
.callout-warm-gray h6.text-bloom,
.callout-warm-gray .h1.text-bloom,
.callout-warm-gray .text-bloom.text-size-h1,
.callout-warm-gray .h2.text-bloom,
.callout-warm-gray .text-bloom.text-size-h2,
.callout-warm-gray .h3.text-bloom,
.callout-warm-gray .text-bloom.text-size-h3,
.callout-warm-gray .h4.text-bloom,
.callout-warm-gray .text-bloom.text-size-h4,
.callout-warm-gray .h5.text-bloom,
.callout-warm-gray .text-bloom.text-size-h5,
.callout-warm-gray .h6.text-bloom,
.callout-warm-gray .text-bloom.text-size-h6,
.callout-sky h1.text-bloom,
.callout-sky h2.text-bloom,
.callout-sky h3.text-bloom,
.callout-sky h4.text-bloom,
.callout-sky h5.text-bloom,
.callout-sky h6.text-bloom,
.callout-sky .h1.text-bloom,
.callout-sky .text-bloom.text-size-h1,
.callout-sky .h2.text-bloom,
.callout-sky .text-bloom.text-size-h2,
.callout-sky .h3.text-bloom,
.callout-sky .text-bloom.text-size-h3,
.callout-sky .h4.text-bloom,
.callout-sky .text-bloom.text-size-h4,
.callout-sky .h5.text-bloom,
.callout-sky .text-bloom.text-size-h5,
.callout-sky .h6.text-bloom,
.callout-sky .text-bloom.text-size-h6 {
  color: #ef4056;
}

.callout-silver h1.text-chili,
.callout-silver h2.text-chili,
.callout-silver h3.text-chili,
.callout-silver h4.text-chili,
.callout-silver h5.text-chili,
.callout-silver h6.text-chili,
.callout-silver .h1.text-chili,
.callout-silver .text-chili.text-size-h1,
.callout-silver .h2.text-chili,
.callout-silver .text-chili.text-size-h2,
.callout-silver .h3.text-chili,
.callout-silver .text-chili.text-size-h3,
.callout-silver .h4.text-chili,
.callout-silver .text-chili.text-size-h4,
.callout-silver .h5.text-chili,
.callout-silver .text-chili.text-size-h5,
.callout-silver .h6.text-chili,
.callout-silver .text-chili.text-size-h6,
.callout-cool-gray h1.text-chili,
.callout-cool-gray h2.text-chili,
.callout-cool-gray h3.text-chili,
.callout-cool-gray h4.text-chili,
.callout-cool-gray h5.text-chili,
.callout-cool-gray h6.text-chili,
.callout-cool-gray .h1.text-chili,
.callout-cool-gray .text-chili.text-size-h1,
.callout-cool-gray .h2.text-chili,
.callout-cool-gray .text-chili.text-size-h2,
.callout-cool-gray .h3.text-chili,
.callout-cool-gray .text-chili.text-size-h3,
.callout-cool-gray .h4.text-chili,
.callout-cool-gray .text-chili.text-size-h4,
.callout-cool-gray .h5.text-chili,
.callout-cool-gray .text-chili.text-size-h5,
.callout-cool-gray .h6.text-chili,
.callout-cool-gray .text-chili.text-size-h6,
.callout-warm-gray h1.text-chili,
.callout-warm-gray h2.text-chili,
.callout-warm-gray h3.text-chili,
.callout-warm-gray h4.text-chili,
.callout-warm-gray h5.text-chili,
.callout-warm-gray h6.text-chili,
.callout-warm-gray .h1.text-chili,
.callout-warm-gray .text-chili.text-size-h1,
.callout-warm-gray .h2.text-chili,
.callout-warm-gray .text-chili.text-size-h2,
.callout-warm-gray .h3.text-chili,
.callout-warm-gray .text-chili.text-size-h3,
.callout-warm-gray .h4.text-chili,
.callout-warm-gray .text-chili.text-size-h4,
.callout-warm-gray .h5.text-chili,
.callout-warm-gray .text-chili.text-size-h5,
.callout-warm-gray .h6.text-chili,
.callout-warm-gray .text-chili.text-size-h6,
.callout-sky h1.text-chili,
.callout-sky h2.text-chili,
.callout-sky h3.text-chili,
.callout-sky h4.text-chili,
.callout-sky h5.text-chili,
.callout-sky h6.text-chili,
.callout-sky .h1.text-chili,
.callout-sky .text-chili.text-size-h1,
.callout-sky .h2.text-chili,
.callout-sky .text-chili.text-size-h2,
.callout-sky .h3.text-chili,
.callout-sky .text-chili.text-size-h3,
.callout-sky .h4.text-chili,
.callout-sky .text-chili.text-size-h4,
.callout-sky .h5.text-chili,
.callout-sky .text-chili.text-size-h5,
.callout-sky .h6.text-chili,
.callout-sky .text-chili.text-size-h6 {
  color: #8b0015;
}

.callout-silver h1.text-sky,
.callout-silver h2.text-sky,
.callout-silver h3.text-sky,
.callout-silver h4.text-sky,
.callout-silver h5.text-sky,
.callout-silver h6.text-sky,
.callout-silver .h1.text-sky,
.callout-silver .text-sky.text-size-h1,
.callout-silver .h2.text-sky,
.callout-silver .text-sky.text-size-h2,
.callout-silver .h3.text-sky,
.callout-silver .text-sky.text-size-h3,
.callout-silver .h4.text-sky,
.callout-silver .text-sky.text-size-h4,
.callout-silver .h5.text-sky,
.callout-silver .text-sky.text-size-h5,
.callout-silver .h6.text-sky,
.callout-silver .text-sky.text-size-h6,
.callout-cool-gray h1.text-sky,
.callout-cool-gray h2.text-sky,
.callout-cool-gray h3.text-sky,
.callout-cool-gray h4.text-sky,
.callout-cool-gray h5.text-sky,
.callout-cool-gray h6.text-sky,
.callout-cool-gray .h1.text-sky,
.callout-cool-gray .text-sky.text-size-h1,
.callout-cool-gray .h2.text-sky,
.callout-cool-gray .text-sky.text-size-h2,
.callout-cool-gray .h3.text-sky,
.callout-cool-gray .text-sky.text-size-h3,
.callout-cool-gray .h4.text-sky,
.callout-cool-gray .text-sky.text-size-h4,
.callout-cool-gray .h5.text-sky,
.callout-cool-gray .text-sky.text-size-h5,
.callout-cool-gray .h6.text-sky,
.callout-cool-gray .text-sky.text-size-h6,
.callout-warm-gray h1.text-sky,
.callout-warm-gray h2.text-sky,
.callout-warm-gray h3.text-sky,
.callout-warm-gray h4.text-sky,
.callout-warm-gray h5.text-sky,
.callout-warm-gray h6.text-sky,
.callout-warm-gray .h1.text-sky,
.callout-warm-gray .text-sky.text-size-h1,
.callout-warm-gray .h2.text-sky,
.callout-warm-gray .text-sky.text-size-h2,
.callout-warm-gray .h3.text-sky,
.callout-warm-gray .text-sky.text-size-h3,
.callout-warm-gray .h4.text-sky,
.callout-warm-gray .text-sky.text-size-h4,
.callout-warm-gray .h5.text-sky,
.callout-warm-gray .text-sky.text-size-h5,
.callout-warm-gray .h6.text-sky,
.callout-warm-gray .text-sky.text-size-h6,
.callout-sky h1.text-sky,
.callout-sky h2.text-sky,
.callout-sky h3.text-sky,
.callout-sky h4.text-sky,
.callout-sky h5.text-sky,
.callout-sky h6.text-sky,
.callout-sky .h1.text-sky,
.callout-sky .text-sky.text-size-h1,
.callout-sky .h2.text-sky,
.callout-sky .text-sky.text-size-h2,
.callout-sky .h3.text-sky,
.callout-sky .text-sky.text-size-h3,
.callout-sky .h4.text-sky,
.callout-sky .text-sky.text-size-h4,
.callout-sky .h5.text-sky,
.callout-sky .text-sky.text-size-h5,
.callout-sky .h6.text-sky,
.callout-sky .text-sky.text-size-h6 {
  color: #81d3eb;
}

.callout-silver h1.text-oasis,
.callout-silver h2.text-oasis,
.callout-silver h3.text-oasis,
.callout-silver h4.text-oasis,
.callout-silver h5.text-oasis,
.callout-silver h6.text-oasis,
.callout-silver .h1.text-oasis,
.callout-silver .text-oasis.text-size-h1,
.callout-silver .h2.text-oasis,
.callout-silver .text-oasis.text-size-h2,
.callout-silver .h3.text-oasis,
.callout-silver .text-oasis.text-size-h3,
.callout-silver .h4.text-oasis,
.callout-silver .text-oasis.text-size-h4,
.callout-silver .h5.text-oasis,
.callout-silver .text-oasis.text-size-h5,
.callout-silver .h6.text-oasis,
.callout-silver .text-oasis.text-size-h6,
.callout-cool-gray h1.text-oasis,
.callout-cool-gray h2.text-oasis,
.callout-cool-gray h3.text-oasis,
.callout-cool-gray h4.text-oasis,
.callout-cool-gray h5.text-oasis,
.callout-cool-gray h6.text-oasis,
.callout-cool-gray .h1.text-oasis,
.callout-cool-gray .text-oasis.text-size-h1,
.callout-cool-gray .h2.text-oasis,
.callout-cool-gray .text-oasis.text-size-h2,
.callout-cool-gray .h3.text-oasis,
.callout-cool-gray .text-oasis.text-size-h3,
.callout-cool-gray .h4.text-oasis,
.callout-cool-gray .text-oasis.text-size-h4,
.callout-cool-gray .h5.text-oasis,
.callout-cool-gray .text-oasis.text-size-h5,
.callout-cool-gray .h6.text-oasis,
.callout-cool-gray .text-oasis.text-size-h6,
.callout-warm-gray h1.text-oasis,
.callout-warm-gray h2.text-oasis,
.callout-warm-gray h3.text-oasis,
.callout-warm-gray h4.text-oasis,
.callout-warm-gray h5.text-oasis,
.callout-warm-gray h6.text-oasis,
.callout-warm-gray .h1.text-oasis,
.callout-warm-gray .text-oasis.text-size-h1,
.callout-warm-gray .h2.text-oasis,
.callout-warm-gray .text-oasis.text-size-h2,
.callout-warm-gray .h3.text-oasis,
.callout-warm-gray .text-oasis.text-size-h3,
.callout-warm-gray .h4.text-oasis,
.callout-warm-gray .text-oasis.text-size-h4,
.callout-warm-gray .h5.text-oasis,
.callout-warm-gray .text-oasis.text-size-h5,
.callout-warm-gray .h6.text-oasis,
.callout-warm-gray .text-oasis.text-size-h6,
.callout-sky h1.text-oasis,
.callout-sky h2.text-oasis,
.callout-sky h3.text-oasis,
.callout-sky h4.text-oasis,
.callout-sky h5.text-oasis,
.callout-sky h6.text-oasis,
.callout-sky .h1.text-oasis,
.callout-sky .text-oasis.text-size-h1,
.callout-sky .h2.text-oasis,
.callout-sky .text-oasis.text-size-h2,
.callout-sky .h3.text-oasis,
.callout-sky .text-oasis.text-size-h3,
.callout-sky .h4.text-oasis,
.callout-sky .text-oasis.text-size-h4,
.callout-sky .h5.text-oasis,
.callout-sky .text-oasis.text-size-h5,
.callout-sky .h6.text-oasis,
.callout-sky .text-oasis.text-size-h6 {
  color: #378dbd;
}

.callout-silver h1.text-azurite,
.callout-silver h2.text-azurite,
.callout-silver h3.text-azurite,
.callout-silver h4.text-azurite,
.callout-silver h5.text-azurite,
.callout-silver h6.text-azurite,
.callout-silver .h1.text-azurite,
.callout-silver .text-azurite.text-size-h1,
.callout-silver .h2.text-azurite,
.callout-silver .text-azurite.text-size-h2,
.callout-silver .h3.text-azurite,
.callout-silver .text-azurite.text-size-h3,
.callout-silver .h4.text-azurite,
.callout-silver .text-azurite.text-size-h4,
.callout-silver .h5.text-azurite,
.callout-silver .text-azurite.text-size-h5,
.callout-silver .h6.text-azurite,
.callout-silver .text-azurite.text-size-h6,
.callout-cool-gray h1.text-azurite,
.callout-cool-gray h2.text-azurite,
.callout-cool-gray h3.text-azurite,
.callout-cool-gray h4.text-azurite,
.callout-cool-gray h5.text-azurite,
.callout-cool-gray h6.text-azurite,
.callout-cool-gray .h1.text-azurite,
.callout-cool-gray .text-azurite.text-size-h1,
.callout-cool-gray .h2.text-azurite,
.callout-cool-gray .text-azurite.text-size-h2,
.callout-cool-gray .h3.text-azurite,
.callout-cool-gray .text-azurite.text-size-h3,
.callout-cool-gray .h4.text-azurite,
.callout-cool-gray .text-azurite.text-size-h4,
.callout-cool-gray .h5.text-azurite,
.callout-cool-gray .text-azurite.text-size-h5,
.callout-cool-gray .h6.text-azurite,
.callout-cool-gray .text-azurite.text-size-h6,
.callout-warm-gray h1.text-azurite,
.callout-warm-gray h2.text-azurite,
.callout-warm-gray h3.text-azurite,
.callout-warm-gray h4.text-azurite,
.callout-warm-gray h5.text-azurite,
.callout-warm-gray h6.text-azurite,
.callout-warm-gray .h1.text-azurite,
.callout-warm-gray .text-azurite.text-size-h1,
.callout-warm-gray .h2.text-azurite,
.callout-warm-gray .text-azurite.text-size-h2,
.callout-warm-gray .h3.text-azurite,
.callout-warm-gray .text-azurite.text-size-h3,
.callout-warm-gray .h4.text-azurite,
.callout-warm-gray .text-azurite.text-size-h4,
.callout-warm-gray .h5.text-azurite,
.callout-warm-gray .text-azurite.text-size-h5,
.callout-warm-gray .h6.text-azurite,
.callout-warm-gray .text-azurite.text-size-h6,
.callout-sky h1.text-azurite,
.callout-sky h2.text-azurite,
.callout-sky h3.text-azurite,
.callout-sky h4.text-azurite,
.callout-sky h5.text-azurite,
.callout-sky h6.text-azurite,
.callout-sky .h1.text-azurite,
.callout-sky .text-azurite.text-size-h1,
.callout-sky .h2.text-azurite,
.callout-sky .text-azurite.text-size-h2,
.callout-sky .h3.text-azurite,
.callout-sky .text-azurite.text-size-h3,
.callout-sky .h4.text-azurite,
.callout-sky .text-azurite.text-size-h4,
.callout-sky .h5.text-azurite,
.callout-sky .text-azurite.text-size-h5,
.callout-sky .h6.text-azurite,
.callout-sky .text-azurite.text-size-h6 {
  color: #1e5288;
}

.callout-silver h1.text-midnight,
.callout-silver h2.text-midnight,
.callout-silver h3.text-midnight,
.callout-silver h4.text-midnight,
.callout-silver h5.text-midnight,
.callout-silver h6.text-midnight,
.callout-silver .h1.text-midnight,
.callout-silver .text-midnight.text-size-h1,
.callout-silver .h2.text-midnight,
.callout-silver .text-midnight.text-size-h2,
.callout-silver .h3.text-midnight,
.callout-silver .text-midnight.text-size-h3,
.callout-silver .h4.text-midnight,
.callout-silver .text-midnight.text-size-h4,
.callout-silver .h5.text-midnight,
.callout-silver .text-midnight.text-size-h5,
.callout-silver .h6.text-midnight,
.callout-silver .text-midnight.text-size-h6,
.callout-cool-gray h1.text-midnight,
.callout-cool-gray h2.text-midnight,
.callout-cool-gray h3.text-midnight,
.callout-cool-gray h4.text-midnight,
.callout-cool-gray h5.text-midnight,
.callout-cool-gray h6.text-midnight,
.callout-cool-gray .h1.text-midnight,
.callout-cool-gray .text-midnight.text-size-h1,
.callout-cool-gray .h2.text-midnight,
.callout-cool-gray .text-midnight.text-size-h2,
.callout-cool-gray .h3.text-midnight,
.callout-cool-gray .text-midnight.text-size-h3,
.callout-cool-gray .h4.text-midnight,
.callout-cool-gray .text-midnight.text-size-h4,
.callout-cool-gray .h5.text-midnight,
.callout-cool-gray .text-midnight.text-size-h5,
.callout-cool-gray .h6.text-midnight,
.callout-cool-gray .text-midnight.text-size-h6,
.callout-warm-gray h1.text-midnight,
.callout-warm-gray h2.text-midnight,
.callout-warm-gray h3.text-midnight,
.callout-warm-gray h4.text-midnight,
.callout-warm-gray h5.text-midnight,
.callout-warm-gray h6.text-midnight,
.callout-warm-gray .h1.text-midnight,
.callout-warm-gray .text-midnight.text-size-h1,
.callout-warm-gray .h2.text-midnight,
.callout-warm-gray .text-midnight.text-size-h2,
.callout-warm-gray .h3.text-midnight,
.callout-warm-gray .text-midnight.text-size-h3,
.callout-warm-gray .h4.text-midnight,
.callout-warm-gray .text-midnight.text-size-h4,
.callout-warm-gray .h5.text-midnight,
.callout-warm-gray .text-midnight.text-size-h5,
.callout-warm-gray .h6.text-midnight,
.callout-warm-gray .text-midnight.text-size-h6,
.callout-sky h1.text-midnight,
.callout-sky h2.text-midnight,
.callout-sky h3.text-midnight,
.callout-sky h4.text-midnight,
.callout-sky h5.text-midnight,
.callout-sky h6.text-midnight,
.callout-sky .h1.text-midnight,
.callout-sky .text-midnight.text-size-h1,
.callout-sky .h2.text-midnight,
.callout-sky .text-midnight.text-size-h2,
.callout-sky .h3.text-midnight,
.callout-sky .text-midnight.text-size-h3,
.callout-sky .h4.text-midnight,
.callout-sky .text-midnight.text-size-h4,
.callout-sky .h5.text-midnight,
.callout-sky .text-midnight.text-size-h5,
.callout-sky .h6.text-midnight,
.callout-sky .text-midnight.text-size-h6 {
  color: #001c48;
}

.callout-silver h1.text-cool-gray,
.callout-silver h2.text-cool-gray,
.callout-silver h3.text-cool-gray,
.callout-silver h4.text-cool-gray,
.callout-silver h5.text-cool-gray,
.callout-silver h6.text-cool-gray,
.callout-silver .h1.text-cool-gray,
.callout-silver .text-cool-gray.text-size-h1,
.callout-silver .h2.text-cool-gray,
.callout-silver .text-cool-gray.text-size-h2,
.callout-silver .h3.text-cool-gray,
.callout-silver .text-cool-gray.text-size-h3,
.callout-silver .h4.text-cool-gray,
.callout-silver .text-cool-gray.text-size-h4,
.callout-silver .h5.text-cool-gray,
.callout-silver .text-cool-gray.text-size-h5,
.callout-silver .h6.text-cool-gray,
.callout-silver .text-cool-gray.text-size-h6,
.callout-cool-gray h1.text-cool-gray,
.callout-cool-gray h2.text-cool-gray,
.callout-cool-gray h3.text-cool-gray,
.callout-cool-gray h4.text-cool-gray,
.callout-cool-gray h5.text-cool-gray,
.callout-cool-gray h6.text-cool-gray,
.callout-cool-gray .h1.text-cool-gray,
.callout-cool-gray .text-cool-gray.text-size-h1,
.callout-cool-gray .h2.text-cool-gray,
.callout-cool-gray .text-cool-gray.text-size-h2,
.callout-cool-gray .h3.text-cool-gray,
.callout-cool-gray .text-cool-gray.text-size-h3,
.callout-cool-gray .h4.text-cool-gray,
.callout-cool-gray .text-cool-gray.text-size-h4,
.callout-cool-gray .h5.text-cool-gray,
.callout-cool-gray .text-cool-gray.text-size-h5,
.callout-cool-gray .h6.text-cool-gray,
.callout-cool-gray .text-cool-gray.text-size-h6,
.callout-warm-gray h1.text-cool-gray,
.callout-warm-gray h2.text-cool-gray,
.callout-warm-gray h3.text-cool-gray,
.callout-warm-gray h4.text-cool-gray,
.callout-warm-gray h5.text-cool-gray,
.callout-warm-gray h6.text-cool-gray,
.callout-warm-gray .h1.text-cool-gray,
.callout-warm-gray .text-cool-gray.text-size-h1,
.callout-warm-gray .h2.text-cool-gray,
.callout-warm-gray .text-cool-gray.text-size-h2,
.callout-warm-gray .h3.text-cool-gray,
.callout-warm-gray .text-cool-gray.text-size-h3,
.callout-warm-gray .h4.text-cool-gray,
.callout-warm-gray .text-cool-gray.text-size-h4,
.callout-warm-gray .h5.text-cool-gray,
.callout-warm-gray .text-cool-gray.text-size-h5,
.callout-warm-gray .h6.text-cool-gray,
.callout-warm-gray .text-cool-gray.text-size-h6,
.callout-sky h1.text-cool-gray,
.callout-sky h2.text-cool-gray,
.callout-sky h3.text-cool-gray,
.callout-sky h4.text-cool-gray,
.callout-sky h5.text-cool-gray,
.callout-sky h6.text-cool-gray,
.callout-sky .h1.text-cool-gray,
.callout-sky .text-cool-gray.text-size-h1,
.callout-sky .h2.text-cool-gray,
.callout-sky .text-cool-gray.text-size-h2,
.callout-sky .h3.text-cool-gray,
.callout-sky .text-cool-gray.text-size-h3,
.callout-sky .h4.text-cool-gray,
.callout-sky .text-cool-gray.text-size-h4,
.callout-sky .h5.text-cool-gray,
.callout-sky .text-cool-gray.text-size-h5,
.callout-sky .h6.text-cool-gray,
.callout-sky .text-cool-gray.text-size-h6 {
  color: #e2e9eb;
}

.callout-silver h1.text-warm-gray,
.callout-silver h2.text-warm-gray,
.callout-silver h3.text-warm-gray,
.callout-silver h4.text-warm-gray,
.callout-silver h5.text-warm-gray,
.callout-silver h6.text-warm-gray,
.callout-silver .h1.text-warm-gray,
.callout-silver .text-warm-gray.text-size-h1,
.callout-silver .h2.text-warm-gray,
.callout-silver .text-warm-gray.text-size-h2,
.callout-silver .h3.text-warm-gray,
.callout-silver .text-warm-gray.text-size-h3,
.callout-silver .h4.text-warm-gray,
.callout-silver .text-warm-gray.text-size-h4,
.callout-silver .h5.text-warm-gray,
.callout-silver .text-warm-gray.text-size-h5,
.callout-silver .h6.text-warm-gray,
.callout-silver .text-warm-gray.text-size-h6,
.callout-cool-gray h1.text-warm-gray,
.callout-cool-gray h2.text-warm-gray,
.callout-cool-gray h3.text-warm-gray,
.callout-cool-gray h4.text-warm-gray,
.callout-cool-gray h5.text-warm-gray,
.callout-cool-gray h6.text-warm-gray,
.callout-cool-gray .h1.text-warm-gray,
.callout-cool-gray .text-warm-gray.text-size-h1,
.callout-cool-gray .h2.text-warm-gray,
.callout-cool-gray .text-warm-gray.text-size-h2,
.callout-cool-gray .h3.text-warm-gray,
.callout-cool-gray .text-warm-gray.text-size-h3,
.callout-cool-gray .h4.text-warm-gray,
.callout-cool-gray .text-warm-gray.text-size-h4,
.callout-cool-gray .h5.text-warm-gray,
.callout-cool-gray .text-warm-gray.text-size-h5,
.callout-cool-gray .h6.text-warm-gray,
.callout-cool-gray .text-warm-gray.text-size-h6,
.callout-warm-gray h1.text-warm-gray,
.callout-warm-gray h2.text-warm-gray,
.callout-warm-gray h3.text-warm-gray,
.callout-warm-gray h4.text-warm-gray,
.callout-warm-gray h5.text-warm-gray,
.callout-warm-gray h6.text-warm-gray,
.callout-warm-gray .h1.text-warm-gray,
.callout-warm-gray .text-warm-gray.text-size-h1,
.callout-warm-gray .h2.text-warm-gray,
.callout-warm-gray .text-warm-gray.text-size-h2,
.callout-warm-gray .h3.text-warm-gray,
.callout-warm-gray .text-warm-gray.text-size-h3,
.callout-warm-gray .h4.text-warm-gray,
.callout-warm-gray .text-warm-gray.text-size-h4,
.callout-warm-gray .h5.text-warm-gray,
.callout-warm-gray .text-warm-gray.text-size-h5,
.callout-warm-gray .h6.text-warm-gray,
.callout-warm-gray .text-warm-gray.text-size-h6,
.callout-sky h1.text-warm-gray,
.callout-sky h2.text-warm-gray,
.callout-sky h3.text-warm-gray,
.callout-sky h4.text-warm-gray,
.callout-sky h5.text-warm-gray,
.callout-sky h6.text-warm-gray,
.callout-sky .h1.text-warm-gray,
.callout-sky .text-warm-gray.text-size-h1,
.callout-sky .h2.text-warm-gray,
.callout-sky .text-warm-gray.text-size-h2,
.callout-sky .h3.text-warm-gray,
.callout-sky .text-warm-gray.text-size-h3,
.callout-sky .h4.text-warm-gray,
.callout-sky .text-warm-gray.text-size-h4,
.callout-sky .h5.text-warm-gray,
.callout-sky .text-warm-gray.text-size-h5,
.callout-sky .h6.text-warm-gray,
.callout-sky .text-warm-gray.text-size-h6 {
  color: #f4ede5;
}

.callout-silver h1.text-leaf,
.callout-silver h2.text-leaf,
.callout-silver h3.text-leaf,
.callout-silver h4.text-leaf,
.callout-silver h5.text-leaf,
.callout-silver h6.text-leaf,
.callout-silver .h1.text-leaf,
.callout-silver .text-leaf.text-size-h1,
.callout-silver .h2.text-leaf,
.callout-silver .text-leaf.text-size-h2,
.callout-silver .h3.text-leaf,
.callout-silver .text-leaf.text-size-h3,
.callout-silver .h4.text-leaf,
.callout-silver .text-leaf.text-size-h4,
.callout-silver .h5.text-leaf,
.callout-silver .text-leaf.text-size-h5,
.callout-silver .h6.text-leaf,
.callout-silver .text-leaf.text-size-h6,
.callout-cool-gray h1.text-leaf,
.callout-cool-gray h2.text-leaf,
.callout-cool-gray h3.text-leaf,
.callout-cool-gray h4.text-leaf,
.callout-cool-gray h5.text-leaf,
.callout-cool-gray h6.text-leaf,
.callout-cool-gray .h1.text-leaf,
.callout-cool-gray .text-leaf.text-size-h1,
.callout-cool-gray .h2.text-leaf,
.callout-cool-gray .text-leaf.text-size-h2,
.callout-cool-gray .h3.text-leaf,
.callout-cool-gray .text-leaf.text-size-h3,
.callout-cool-gray .h4.text-leaf,
.callout-cool-gray .text-leaf.text-size-h4,
.callout-cool-gray .h5.text-leaf,
.callout-cool-gray .text-leaf.text-size-h5,
.callout-cool-gray .h6.text-leaf,
.callout-cool-gray .text-leaf.text-size-h6,
.callout-warm-gray h1.text-leaf,
.callout-warm-gray h2.text-leaf,
.callout-warm-gray h3.text-leaf,
.callout-warm-gray h4.text-leaf,
.callout-warm-gray h5.text-leaf,
.callout-warm-gray h6.text-leaf,
.callout-warm-gray .h1.text-leaf,
.callout-warm-gray .text-leaf.text-size-h1,
.callout-warm-gray .h2.text-leaf,
.callout-warm-gray .text-leaf.text-size-h2,
.callout-warm-gray .h3.text-leaf,
.callout-warm-gray .text-leaf.text-size-h3,
.callout-warm-gray .h4.text-leaf,
.callout-warm-gray .text-leaf.text-size-h4,
.callout-warm-gray .h5.text-leaf,
.callout-warm-gray .text-leaf.text-size-h5,
.callout-warm-gray .h6.text-leaf,
.callout-warm-gray .text-leaf.text-size-h6,
.callout-sky h1.text-leaf,
.callout-sky h2.text-leaf,
.callout-sky h3.text-leaf,
.callout-sky h4.text-leaf,
.callout-sky h5.text-leaf,
.callout-sky h6.text-leaf,
.callout-sky .h1.text-leaf,
.callout-sky .text-leaf.text-size-h1,
.callout-sky .h2.text-leaf,
.callout-sky .text-leaf.text-size-h2,
.callout-sky .h3.text-leaf,
.callout-sky .text-leaf.text-size-h3,
.callout-sky .h4.text-leaf,
.callout-sky .text-leaf.text-size-h4,
.callout-sky .h5.text-leaf,
.callout-sky .text-leaf.text-size-h5,
.callout-sky .h6.text-leaf,
.callout-sky .text-leaf.text-size-h6 {
  color: #70b865;
}

.callout-silver h1.text-river,
.callout-silver h2.text-river,
.callout-silver h3.text-river,
.callout-silver h4.text-river,
.callout-silver h5.text-river,
.callout-silver h6.text-river,
.callout-silver .h1.text-river,
.callout-silver .text-river.text-size-h1,
.callout-silver .h2.text-river,
.callout-silver .text-river.text-size-h2,
.callout-silver .h3.text-river,
.callout-silver .text-river.text-size-h3,
.callout-silver .h4.text-river,
.callout-silver .text-river.text-size-h4,
.callout-silver .h5.text-river,
.callout-silver .text-river.text-size-h5,
.callout-silver .h6.text-river,
.callout-silver .text-river.text-size-h6,
.callout-cool-gray h1.text-river,
.callout-cool-gray h2.text-river,
.callout-cool-gray h3.text-river,
.callout-cool-gray h4.text-river,
.callout-cool-gray h5.text-river,
.callout-cool-gray h6.text-river,
.callout-cool-gray .h1.text-river,
.callout-cool-gray .text-river.text-size-h1,
.callout-cool-gray .h2.text-river,
.callout-cool-gray .text-river.text-size-h2,
.callout-cool-gray .h3.text-river,
.callout-cool-gray .text-river.text-size-h3,
.callout-cool-gray .h4.text-river,
.callout-cool-gray .text-river.text-size-h4,
.callout-cool-gray .h5.text-river,
.callout-cool-gray .text-river.text-size-h5,
.callout-cool-gray .h6.text-river,
.callout-cool-gray .text-river.text-size-h6,
.callout-warm-gray h1.text-river,
.callout-warm-gray h2.text-river,
.callout-warm-gray h3.text-river,
.callout-warm-gray h4.text-river,
.callout-warm-gray h5.text-river,
.callout-warm-gray h6.text-river,
.callout-warm-gray .h1.text-river,
.callout-warm-gray .text-river.text-size-h1,
.callout-warm-gray .h2.text-river,
.callout-warm-gray .text-river.text-size-h2,
.callout-warm-gray .h3.text-river,
.callout-warm-gray .text-river.text-size-h3,
.callout-warm-gray .h4.text-river,
.callout-warm-gray .text-river.text-size-h4,
.callout-warm-gray .h5.text-river,
.callout-warm-gray .text-river.text-size-h5,
.callout-warm-gray .h6.text-river,
.callout-warm-gray .text-river.text-size-h6,
.callout-sky h1.text-river,
.callout-sky h2.text-river,
.callout-sky h3.text-river,
.callout-sky h4.text-river,
.callout-sky h5.text-river,
.callout-sky h6.text-river,
.callout-sky .h1.text-river,
.callout-sky .text-river.text-size-h1,
.callout-sky .h2.text-river,
.callout-sky .text-river.text-size-h2,
.callout-sky .h3.text-river,
.callout-sky .text-river.text-size-h3,
.callout-sky .h4.text-river,
.callout-sky .text-river.text-size-h4,
.callout-sky .h5.text-river,
.callout-sky .text-river.text-size-h5,
.callout-sky .h6.text-river,
.callout-sky .text-river.text-size-h6 {
  color: #007d84;
}

.callout-silver h1.text-silver,
.callout-silver h2.text-silver,
.callout-silver h3.text-silver,
.callout-silver h4.text-silver,
.callout-silver h5.text-silver,
.callout-silver h6.text-silver,
.callout-silver .h1.text-silver,
.callout-silver .text-silver.text-size-h1,
.callout-silver .h2.text-silver,
.callout-silver .text-silver.text-size-h2,
.callout-silver .h3.text-silver,
.callout-silver .text-silver.text-size-h3,
.callout-silver .h4.text-silver,
.callout-silver .text-silver.text-size-h4,
.callout-silver .h5.text-silver,
.callout-silver .text-silver.text-size-h5,
.callout-silver .h6.text-silver,
.callout-silver .text-silver.text-size-h6,
.callout-cool-gray h1.text-silver,
.callout-cool-gray h2.text-silver,
.callout-cool-gray h3.text-silver,
.callout-cool-gray h4.text-silver,
.callout-cool-gray h5.text-silver,
.callout-cool-gray h6.text-silver,
.callout-cool-gray .h1.text-silver,
.callout-cool-gray .text-silver.text-size-h1,
.callout-cool-gray .h2.text-silver,
.callout-cool-gray .text-silver.text-size-h2,
.callout-cool-gray .h3.text-silver,
.callout-cool-gray .text-silver.text-size-h3,
.callout-cool-gray .h4.text-silver,
.callout-cool-gray .text-silver.text-size-h4,
.callout-cool-gray .h5.text-silver,
.callout-cool-gray .text-silver.text-size-h5,
.callout-cool-gray .h6.text-silver,
.callout-cool-gray .text-silver.text-size-h6,
.callout-warm-gray h1.text-silver,
.callout-warm-gray h2.text-silver,
.callout-warm-gray h3.text-silver,
.callout-warm-gray h4.text-silver,
.callout-warm-gray h5.text-silver,
.callout-warm-gray h6.text-silver,
.callout-warm-gray .h1.text-silver,
.callout-warm-gray .text-silver.text-size-h1,
.callout-warm-gray .h2.text-silver,
.callout-warm-gray .text-silver.text-size-h2,
.callout-warm-gray .h3.text-silver,
.callout-warm-gray .text-silver.text-size-h3,
.callout-warm-gray .h4.text-silver,
.callout-warm-gray .text-silver.text-size-h4,
.callout-warm-gray .h5.text-silver,
.callout-warm-gray .text-silver.text-size-h5,
.callout-warm-gray .h6.text-silver,
.callout-warm-gray .text-silver.text-size-h6,
.callout-sky h1.text-silver,
.callout-sky h2.text-silver,
.callout-sky h3.text-silver,
.callout-sky h4.text-silver,
.callout-sky h5.text-silver,
.callout-sky h6.text-silver,
.callout-sky .h1.text-silver,
.callout-sky .text-silver.text-size-h1,
.callout-sky .h2.text-silver,
.callout-sky .text-silver.text-size-h2,
.callout-sky .h3.text-silver,
.callout-sky .text-silver.text-size-h3,
.callout-sky .h4.text-silver,
.callout-sky .text-silver.text-size-h4,
.callout-sky .h5.text-silver,
.callout-sky .text-silver.text-size-h5,
.callout-sky .h6.text-silver,
.callout-sky .text-silver.text-size-h6 {
  color: #9eabae;
}

.callout-silver h1.text-mesa,
.callout-silver h2.text-mesa,
.callout-silver h3.text-mesa,
.callout-silver h4.text-mesa,
.callout-silver h5.text-mesa,
.callout-silver h6.text-mesa,
.callout-silver .h1.text-mesa,
.callout-silver .text-mesa.text-size-h1,
.callout-silver .h2.text-mesa,
.callout-silver .text-mesa.text-size-h2,
.callout-silver .h3.text-mesa,
.callout-silver .text-mesa.text-size-h3,
.callout-silver .h4.text-mesa,
.callout-silver .text-mesa.text-size-h4,
.callout-silver .h5.text-mesa,
.callout-silver .text-mesa.text-size-h5,
.callout-silver .h6.text-mesa,
.callout-silver .text-mesa.text-size-h6,
.callout-cool-gray h1.text-mesa,
.callout-cool-gray h2.text-mesa,
.callout-cool-gray h3.text-mesa,
.callout-cool-gray h4.text-mesa,
.callout-cool-gray h5.text-mesa,
.callout-cool-gray h6.text-mesa,
.callout-cool-gray .h1.text-mesa,
.callout-cool-gray .text-mesa.text-size-h1,
.callout-cool-gray .h2.text-mesa,
.callout-cool-gray .text-mesa.text-size-h2,
.callout-cool-gray .h3.text-mesa,
.callout-cool-gray .text-mesa.text-size-h3,
.callout-cool-gray .h4.text-mesa,
.callout-cool-gray .text-mesa.text-size-h4,
.callout-cool-gray .h5.text-mesa,
.callout-cool-gray .text-mesa.text-size-h5,
.callout-cool-gray .h6.text-mesa,
.callout-cool-gray .text-mesa.text-size-h6,
.callout-warm-gray h1.text-mesa,
.callout-warm-gray h2.text-mesa,
.callout-warm-gray h3.text-mesa,
.callout-warm-gray h4.text-mesa,
.callout-warm-gray h5.text-mesa,
.callout-warm-gray h6.text-mesa,
.callout-warm-gray .h1.text-mesa,
.callout-warm-gray .text-mesa.text-size-h1,
.callout-warm-gray .h2.text-mesa,
.callout-warm-gray .text-mesa.text-size-h2,
.callout-warm-gray .h3.text-mesa,
.callout-warm-gray .text-mesa.text-size-h3,
.callout-warm-gray .h4.text-mesa,
.callout-warm-gray .text-mesa.text-size-h4,
.callout-warm-gray .h5.text-mesa,
.callout-warm-gray .text-mesa.text-size-h5,
.callout-warm-gray .h6.text-mesa,
.callout-warm-gray .text-mesa.text-size-h6,
.callout-sky h1.text-mesa,
.callout-sky h2.text-mesa,
.callout-sky h3.text-mesa,
.callout-sky h4.text-mesa,
.callout-sky h5.text-mesa,
.callout-sky h6.text-mesa,
.callout-sky .h1.text-mesa,
.callout-sky .text-mesa.text-size-h1,
.callout-sky .h2.text-mesa,
.callout-sky .text-mesa.text-size-h2,
.callout-sky .h3.text-mesa,
.callout-sky .text-mesa.text-size-h3,
.callout-sky .h4.text-mesa,
.callout-sky .text-mesa.text-size-h4,
.callout-sky .h5.text-mesa,
.callout-sky .text-mesa.text-size-h5,
.callout-sky .h6.text-mesa,
.callout-sky .text-mesa.text-size-h6 {
  color: #a95c42;
}

.callout-silver h1.text-ash,
.callout-silver h2.text-ash,
.callout-silver h3.text-ash,
.callout-silver h4.text-ash,
.callout-silver h5.text-ash,
.callout-silver h6.text-ash,
.callout-silver .h1.text-ash,
.callout-silver .text-ash.text-size-h1,
.callout-silver .h2.text-ash,
.callout-silver .text-ash.text-size-h2,
.callout-silver .h3.text-ash,
.callout-silver .text-ash.text-size-h3,
.callout-silver .h4.text-ash,
.callout-silver .text-ash.text-size-h4,
.callout-silver .h5.text-ash,
.callout-silver .text-ash.text-size-h5,
.callout-silver .h6.text-ash,
.callout-silver .text-ash.text-size-h6,
.callout-cool-gray h1.text-ash,
.callout-cool-gray h2.text-ash,
.callout-cool-gray h3.text-ash,
.callout-cool-gray h4.text-ash,
.callout-cool-gray h5.text-ash,
.callout-cool-gray h6.text-ash,
.callout-cool-gray .h1.text-ash,
.callout-cool-gray .text-ash.text-size-h1,
.callout-cool-gray .h2.text-ash,
.callout-cool-gray .text-ash.text-size-h2,
.callout-cool-gray .h3.text-ash,
.callout-cool-gray .text-ash.text-size-h3,
.callout-cool-gray .h4.text-ash,
.callout-cool-gray .text-ash.text-size-h4,
.callout-cool-gray .h5.text-ash,
.callout-cool-gray .text-ash.text-size-h5,
.callout-cool-gray .h6.text-ash,
.callout-cool-gray .text-ash.text-size-h6,
.callout-warm-gray h1.text-ash,
.callout-warm-gray h2.text-ash,
.callout-warm-gray h3.text-ash,
.callout-warm-gray h4.text-ash,
.callout-warm-gray h5.text-ash,
.callout-warm-gray h6.text-ash,
.callout-warm-gray .h1.text-ash,
.callout-warm-gray .text-ash.text-size-h1,
.callout-warm-gray .h2.text-ash,
.callout-warm-gray .text-ash.text-size-h2,
.callout-warm-gray .h3.text-ash,
.callout-warm-gray .text-ash.text-size-h3,
.callout-warm-gray .h4.text-ash,
.callout-warm-gray .text-ash.text-size-h4,
.callout-warm-gray .h5.text-ash,
.callout-warm-gray .text-ash.text-size-h5,
.callout-warm-gray .h6.text-ash,
.callout-warm-gray .text-ash.text-size-h6,
.callout-sky h1.text-ash,
.callout-sky h2.text-ash,
.callout-sky h3.text-ash,
.callout-sky h4.text-ash,
.callout-sky h5.text-ash,
.callout-sky h6.text-ash,
.callout-sky .h1.text-ash,
.callout-sky .text-ash.text-size-h1,
.callout-sky .h2.text-ash,
.callout-sky .text-ash.text-size-h2,
.callout-sky .h3.text-ash,
.callout-sky .text-ash.text-size-h3,
.callout-sky .h4.text-ash,
.callout-sky .text-ash.text-size-h4,
.callout-sky .h5.text-ash,
.callout-sky .text-ash.text-size-h5,
.callout-sky .h6.text-ash,
.callout-sky .text-ash.text-size-h6 {
  color: #403635;
}

.callout-silver h1.text-sage,
.callout-silver h2.text-sage,
.callout-silver h3.text-sage,
.callout-silver h4.text-sage,
.callout-silver h5.text-sage,
.callout-silver h6.text-sage,
.callout-silver .h1.text-sage,
.callout-silver .text-sage.text-size-h1,
.callout-silver .h2.text-sage,
.callout-silver .text-sage.text-size-h2,
.callout-silver .h3.text-sage,
.callout-silver .text-sage.text-size-h3,
.callout-silver .h4.text-sage,
.callout-silver .text-sage.text-size-h4,
.callout-silver .h5.text-sage,
.callout-silver .text-sage.text-size-h5,
.callout-silver .h6.text-sage,
.callout-silver .text-sage.text-size-h6,
.callout-cool-gray h1.text-sage,
.callout-cool-gray h2.text-sage,
.callout-cool-gray h3.text-sage,
.callout-cool-gray h4.text-sage,
.callout-cool-gray h5.text-sage,
.callout-cool-gray h6.text-sage,
.callout-cool-gray .h1.text-sage,
.callout-cool-gray .text-sage.text-size-h1,
.callout-cool-gray .h2.text-sage,
.callout-cool-gray .text-sage.text-size-h2,
.callout-cool-gray .h3.text-sage,
.callout-cool-gray .text-sage.text-size-h3,
.callout-cool-gray .h4.text-sage,
.callout-cool-gray .text-sage.text-size-h4,
.callout-cool-gray .h5.text-sage,
.callout-cool-gray .text-sage.text-size-h5,
.callout-cool-gray .h6.text-sage,
.callout-cool-gray .text-sage.text-size-h6,
.callout-warm-gray h1.text-sage,
.callout-warm-gray h2.text-sage,
.callout-warm-gray h3.text-sage,
.callout-warm-gray h4.text-sage,
.callout-warm-gray h5.text-sage,
.callout-warm-gray h6.text-sage,
.callout-warm-gray .h1.text-sage,
.callout-warm-gray .text-sage.text-size-h1,
.callout-warm-gray .h2.text-sage,
.callout-warm-gray .text-sage.text-size-h2,
.callout-warm-gray .h3.text-sage,
.callout-warm-gray .text-sage.text-size-h3,
.callout-warm-gray .h4.text-sage,
.callout-warm-gray .text-sage.text-size-h4,
.callout-warm-gray .h5.text-sage,
.callout-warm-gray .text-sage.text-size-h5,
.callout-warm-gray .h6.text-sage,
.callout-warm-gray .text-sage.text-size-h6,
.callout-sky h1.text-sage,
.callout-sky h2.text-sage,
.callout-sky h3.text-sage,
.callout-sky h4.text-sage,
.callout-sky h5.text-sage,
.callout-sky h6.text-sage,
.callout-sky .h1.text-sage,
.callout-sky .text-sage.text-size-h1,
.callout-sky .h2.text-sage,
.callout-sky .text-sage.text-size-h2,
.callout-sky .h3.text-sage,
.callout-sky .text-sage.text-size-h3,
.callout-sky .h4.text-sage,
.callout-sky .text-sage.text-size-h4,
.callout-sky .h5.text-sage,
.callout-sky .text-sage.text-size-h5,
.callout-sky .h6.text-sage,
.callout-sky .text-sage.text-size-h6 {
  color: #4a634e;
}

.callout-silver h1.text-black,
.callout-silver h2.text-black,
.callout-silver h3.text-black,
.callout-silver h4.text-black,
.callout-silver h5.text-black,
.callout-silver h6.text-black,
.callout-silver .h1.text-black,
.callout-silver .text-black.text-size-h1,
.callout-silver .h2.text-black,
.callout-silver .text-black.text-size-h2,
.callout-silver .h3.text-black,
.callout-silver .text-black.text-size-h3,
.callout-silver .h4.text-black,
.callout-silver .text-black.text-size-h4,
.callout-silver .h5.text-black,
.callout-silver .text-black.text-size-h5,
.callout-silver .h6.text-black,
.callout-silver .text-black.text-size-h6,
.callout-cool-gray h1.text-black,
.callout-cool-gray h2.text-black,
.callout-cool-gray h3.text-black,
.callout-cool-gray h4.text-black,
.callout-cool-gray h5.text-black,
.callout-cool-gray h6.text-black,
.callout-cool-gray .h1.text-black,
.callout-cool-gray .text-black.text-size-h1,
.callout-cool-gray .h2.text-black,
.callout-cool-gray .text-black.text-size-h2,
.callout-cool-gray .h3.text-black,
.callout-cool-gray .text-black.text-size-h3,
.callout-cool-gray .h4.text-black,
.callout-cool-gray .text-black.text-size-h4,
.callout-cool-gray .h5.text-black,
.callout-cool-gray .text-black.text-size-h5,
.callout-cool-gray .h6.text-black,
.callout-cool-gray .text-black.text-size-h6,
.callout-warm-gray h1.text-black,
.callout-warm-gray h2.text-black,
.callout-warm-gray h3.text-black,
.callout-warm-gray h4.text-black,
.callout-warm-gray h5.text-black,
.callout-warm-gray h6.text-black,
.callout-warm-gray .h1.text-black,
.callout-warm-gray .text-black.text-size-h1,
.callout-warm-gray .h2.text-black,
.callout-warm-gray .text-black.text-size-h2,
.callout-warm-gray .h3.text-black,
.callout-warm-gray .text-black.text-size-h3,
.callout-warm-gray .h4.text-black,
.callout-warm-gray .text-black.text-size-h4,
.callout-warm-gray .h5.text-black,
.callout-warm-gray .text-black.text-size-h5,
.callout-warm-gray .h6.text-black,
.callout-warm-gray .text-black.text-size-h6,
.callout-sky h1.text-black,
.callout-sky h2.text-black,
.callout-sky h3.text-black,
.callout-sky h4.text-black,
.callout-sky h5.text-black,
.callout-sky h6.text-black,
.callout-sky .h1.text-black,
.callout-sky .text-black.text-size-h1,
.callout-sky .h2.text-black,
.callout-sky .text-black.text-size-h2,
.callout-sky .h3.text-black,
.callout-sky .text-black.text-size-h3,
.callout-sky .h4.text-black,
.callout-sky .text-black.text-size-h4,
.callout-sky .h5.text-black,
.callout-sky .text-black.text-size-h5,
.callout-sky .h6.text-black,
.callout-sky .text-black.text-size-h6 {
  color: #000;
}

.callout-silver h1.text-success,
.callout-silver h2.text-success,
.callout-silver h3.text-success,
.callout-silver h4.text-success,
.callout-silver h5.text-success,
.callout-silver h6.text-success,
.callout-silver .h1.text-success,
.callout-silver .text-success.text-size-h1,
.callout-silver .h2.text-success,
.callout-silver .text-success.text-size-h2,
.callout-silver .h3.text-success,
.callout-silver .text-success.text-size-h3,
.callout-silver .h4.text-success,
.callout-silver .text-success.text-size-h4,
.callout-silver .h5.text-success,
.callout-silver .text-success.text-size-h5,
.callout-silver .h6.text-success,
.callout-silver .text-success.text-size-h6,
.callout-cool-gray h1.text-success,
.callout-cool-gray h2.text-success,
.callout-cool-gray h3.text-success,
.callout-cool-gray h4.text-success,
.callout-cool-gray h5.text-success,
.callout-cool-gray h6.text-success,
.callout-cool-gray .h1.text-success,
.callout-cool-gray .text-success.text-size-h1,
.callout-cool-gray .h2.text-success,
.callout-cool-gray .text-success.text-size-h2,
.callout-cool-gray .h3.text-success,
.callout-cool-gray .text-success.text-size-h3,
.callout-cool-gray .h4.text-success,
.callout-cool-gray .text-success.text-size-h4,
.callout-cool-gray .h5.text-success,
.callout-cool-gray .text-success.text-size-h5,
.callout-cool-gray .h6.text-success,
.callout-cool-gray .text-success.text-size-h6,
.callout-warm-gray h1.text-success,
.callout-warm-gray h2.text-success,
.callout-warm-gray h3.text-success,
.callout-warm-gray h4.text-success,
.callout-warm-gray h5.text-success,
.callout-warm-gray h6.text-success,
.callout-warm-gray .h1.text-success,
.callout-warm-gray .text-success.text-size-h1,
.callout-warm-gray .h2.text-success,
.callout-warm-gray .text-success.text-size-h2,
.callout-warm-gray .h3.text-success,
.callout-warm-gray .text-success.text-size-h3,
.callout-warm-gray .h4.text-success,
.callout-warm-gray .text-success.text-size-h4,
.callout-warm-gray .h5.text-success,
.callout-warm-gray .text-success.text-size-h5,
.callout-warm-gray .h6.text-success,
.callout-warm-gray .text-success.text-size-h6,
.callout-sky h1.text-success,
.callout-sky h2.text-success,
.callout-sky h3.text-success,
.callout-sky h4.text-success,
.callout-sky h5.text-success,
.callout-sky h6.text-success,
.callout-sky .h1.text-success,
.callout-sky .text-success.text-size-h1,
.callout-sky .h2.text-success,
.callout-sky .text-success.text-size-h2,
.callout-sky .h3.text-success,
.callout-sky .text-success.text-size-h3,
.callout-sky .h4.text-success,
.callout-sky .text-success.text-size-h4,
.callout-sky .h5.text-success,
.callout-sky .text-success.text-size-h5,
.callout-sky .h6.text-success,
.callout-sky .text-success.text-size-h6 {
  color: #70b865;
}

.callout-silver h1.text-info,
.callout-silver h2.text-info,
.callout-silver h3.text-info,
.callout-silver h4.text-info,
.callout-silver h5.text-info,
.callout-silver h6.text-info,
.callout-silver .h1.text-info,
.callout-silver .text-info.text-size-h1,
.callout-silver .h2.text-info,
.callout-silver .text-info.text-size-h2,
.callout-silver .h3.text-info,
.callout-silver .text-info.text-size-h3,
.callout-silver .h4.text-info,
.callout-silver .text-info.text-size-h4,
.callout-silver .h5.text-info,
.callout-silver .text-info.text-size-h5,
.callout-silver .h6.text-info,
.callout-silver .text-info.text-size-h6,
.callout-cool-gray h1.text-info,
.callout-cool-gray h2.text-info,
.callout-cool-gray h3.text-info,
.callout-cool-gray h4.text-info,
.callout-cool-gray h5.text-info,
.callout-cool-gray h6.text-info,
.callout-cool-gray .h1.text-info,
.callout-cool-gray .text-info.text-size-h1,
.callout-cool-gray .h2.text-info,
.callout-cool-gray .text-info.text-size-h2,
.callout-cool-gray .h3.text-info,
.callout-cool-gray .text-info.text-size-h3,
.callout-cool-gray .h4.text-info,
.callout-cool-gray .text-info.text-size-h4,
.callout-cool-gray .h5.text-info,
.callout-cool-gray .text-info.text-size-h5,
.callout-cool-gray .h6.text-info,
.callout-cool-gray .text-info.text-size-h6,
.callout-warm-gray h1.text-info,
.callout-warm-gray h2.text-info,
.callout-warm-gray h3.text-info,
.callout-warm-gray h4.text-info,
.callout-warm-gray h5.text-info,
.callout-warm-gray h6.text-info,
.callout-warm-gray .h1.text-info,
.callout-warm-gray .text-info.text-size-h1,
.callout-warm-gray .h2.text-info,
.callout-warm-gray .text-info.text-size-h2,
.callout-warm-gray .h3.text-info,
.callout-warm-gray .text-info.text-size-h3,
.callout-warm-gray .h4.text-info,
.callout-warm-gray .text-info.text-size-h4,
.callout-warm-gray .h5.text-info,
.callout-warm-gray .text-info.text-size-h5,
.callout-warm-gray .h6.text-info,
.callout-warm-gray .text-info.text-size-h6,
.callout-sky h1.text-info,
.callout-sky h2.text-info,
.callout-sky h3.text-info,
.callout-sky h4.text-info,
.callout-sky h5.text-info,
.callout-sky h6.text-info,
.callout-sky .h1.text-info,
.callout-sky .text-info.text-size-h1,
.callout-sky .h2.text-info,
.callout-sky .text-info.text-size-h2,
.callout-sky .h3.text-info,
.callout-sky .text-info.text-size-h3,
.callout-sky .h4.text-info,
.callout-sky .text-info.text-size-h4,
.callout-sky .h5.text-info,
.callout-sky .text-info.text-size-h5,
.callout-sky .h6.text-info,
.callout-sky .text-info.text-size-h6 {
  color: #81d3eb;
}

.callout-silver h1.text-warning,
.callout-silver h2.text-warning,
.callout-silver h3.text-warning,
.callout-silver h4.text-warning,
.callout-silver h5.text-warning,
.callout-silver h6.text-warning,
.callout-silver .h1.text-warning,
.callout-silver .text-warning.text-size-h1,
.callout-silver .h2.text-warning,
.callout-silver .text-warning.text-size-h2,
.callout-silver .h3.text-warning,
.callout-silver .text-warning.text-size-h3,
.callout-silver .h4.text-warning,
.callout-silver .text-warning.text-size-h4,
.callout-silver .h5.text-warning,
.callout-silver .text-warning.text-size-h5,
.callout-silver .h6.text-warning,
.callout-silver .text-warning.text-size-h6,
.callout-cool-gray h1.text-warning,
.callout-cool-gray h2.text-warning,
.callout-cool-gray h3.text-warning,
.callout-cool-gray h4.text-warning,
.callout-cool-gray h5.text-warning,
.callout-cool-gray h6.text-warning,
.callout-cool-gray .h1.text-warning,
.callout-cool-gray .text-warning.text-size-h1,
.callout-cool-gray .h2.text-warning,
.callout-cool-gray .text-warning.text-size-h2,
.callout-cool-gray .h3.text-warning,
.callout-cool-gray .text-warning.text-size-h3,
.callout-cool-gray .h4.text-warning,
.callout-cool-gray .text-warning.text-size-h4,
.callout-cool-gray .h5.text-warning,
.callout-cool-gray .text-warning.text-size-h5,
.callout-cool-gray .h6.text-warning,
.callout-cool-gray .text-warning.text-size-h6,
.callout-warm-gray h1.text-warning,
.callout-warm-gray h2.text-warning,
.callout-warm-gray h3.text-warning,
.callout-warm-gray h4.text-warning,
.callout-warm-gray h5.text-warning,
.callout-warm-gray h6.text-warning,
.callout-warm-gray .h1.text-warning,
.callout-warm-gray .text-warning.text-size-h1,
.callout-warm-gray .h2.text-warning,
.callout-warm-gray .text-warning.text-size-h2,
.callout-warm-gray .h3.text-warning,
.callout-warm-gray .text-warning.text-size-h3,
.callout-warm-gray .h4.text-warning,
.callout-warm-gray .text-warning.text-size-h4,
.callout-warm-gray .h5.text-warning,
.callout-warm-gray .text-warning.text-size-h5,
.callout-warm-gray .h6.text-warning,
.callout-warm-gray .text-warning.text-size-h6,
.callout-sky h1.text-warning,
.callout-sky h2.text-warning,
.callout-sky h3.text-warning,
.callout-sky h4.text-warning,
.callout-sky h5.text-warning,
.callout-sky h6.text-warning,
.callout-sky .h1.text-warning,
.callout-sky .text-warning.text-size-h1,
.callout-sky .h2.text-warning,
.callout-sky .text-warning.text-size-h2,
.callout-sky .h3.text-warning,
.callout-sky .text-warning.text-size-h3,
.callout-sky .h4.text-warning,
.callout-sky .text-warning.text-size-h4,
.callout-sky .h5.text-warning,
.callout-sky .text-warning.text-size-h5,
.callout-sky .h6.text-warning,
.callout-sky .text-warning.text-size-h6 {
  color: #f19e1f;
}

.callout-silver h1.text-danger,
.callout-silver h2.text-danger,
.callout-silver h3.text-danger,
.callout-silver h4.text-danger,
.callout-silver h5.text-danger,
.callout-silver h6.text-danger,
.callout-silver .h1.text-danger,
.callout-silver .text-danger.text-size-h1,
.callout-silver .h2.text-danger,
.callout-silver .text-danger.text-size-h2,
.callout-silver .h3.text-danger,
.callout-silver .text-danger.text-size-h3,
.callout-silver .h4.text-danger,
.callout-silver .text-danger.text-size-h4,
.callout-silver .h5.text-danger,
.callout-silver .text-danger.text-size-h5,
.callout-silver .h6.text-danger,
.callout-silver .text-danger.text-size-h6,
.callout-cool-gray h1.text-danger,
.callout-cool-gray h2.text-danger,
.callout-cool-gray h3.text-danger,
.callout-cool-gray h4.text-danger,
.callout-cool-gray h5.text-danger,
.callout-cool-gray h6.text-danger,
.callout-cool-gray .h1.text-danger,
.callout-cool-gray .text-danger.text-size-h1,
.callout-cool-gray .h2.text-danger,
.callout-cool-gray .text-danger.text-size-h2,
.callout-cool-gray .h3.text-danger,
.callout-cool-gray .text-danger.text-size-h3,
.callout-cool-gray .h4.text-danger,
.callout-cool-gray .text-danger.text-size-h4,
.callout-cool-gray .h5.text-danger,
.callout-cool-gray .text-danger.text-size-h5,
.callout-cool-gray .h6.text-danger,
.callout-cool-gray .text-danger.text-size-h6,
.callout-warm-gray h1.text-danger,
.callout-warm-gray h2.text-danger,
.callout-warm-gray h3.text-danger,
.callout-warm-gray h4.text-danger,
.callout-warm-gray h5.text-danger,
.callout-warm-gray h6.text-danger,
.callout-warm-gray .h1.text-danger,
.callout-warm-gray .text-danger.text-size-h1,
.callout-warm-gray .h2.text-danger,
.callout-warm-gray .text-danger.text-size-h2,
.callout-warm-gray .h3.text-danger,
.callout-warm-gray .text-danger.text-size-h3,
.callout-warm-gray .h4.text-danger,
.callout-warm-gray .text-danger.text-size-h4,
.callout-warm-gray .h5.text-danger,
.callout-warm-gray .text-danger.text-size-h5,
.callout-warm-gray .h6.text-danger,
.callout-warm-gray .text-danger.text-size-h6,
.callout-sky h1.text-danger,
.callout-sky h2.text-danger,
.callout-sky h3.text-danger,
.callout-sky h4.text-danger,
.callout-sky h5.text-danger,
.callout-sky h6.text-danger,
.callout-sky .h1.text-danger,
.callout-sky .text-danger.text-size-h1,
.callout-sky .h2.text-danger,
.callout-sky .text-danger.text-size-h2,
.callout-sky .h3.text-danger,
.callout-sky .text-danger.text-size-h3,
.callout-sky .h4.text-danger,
.callout-sky .text-danger.text-size-h4,
.callout-sky .h5.text-danger,
.callout-sky .text-danger.text-size-h5,
.callout-sky .h6.text-danger,
.callout-sky .text-danger.text-size-h6 {
  color: #a95c42;
}

.callout-silver h1.text-light,
.callout-silver h2.text-light,
.callout-silver h3.text-light,
.callout-silver h4.text-light,
.callout-silver h5.text-light,
.callout-silver h6.text-light,
.callout-silver .h1.text-light,
.callout-silver .text-light.text-size-h1,
.callout-silver .h2.text-light,
.callout-silver .text-light.text-size-h2,
.callout-silver .h3.text-light,
.callout-silver .text-light.text-size-h3,
.callout-silver .h4.text-light,
.callout-silver .text-light.text-size-h4,
.callout-silver .h5.text-light,
.callout-silver .text-light.text-size-h5,
.callout-silver .h6.text-light,
.callout-silver .text-light.text-size-h6,
.callout-cool-gray h1.text-light,
.callout-cool-gray h2.text-light,
.callout-cool-gray h3.text-light,
.callout-cool-gray h4.text-light,
.callout-cool-gray h5.text-light,
.callout-cool-gray h6.text-light,
.callout-cool-gray .h1.text-light,
.callout-cool-gray .text-light.text-size-h1,
.callout-cool-gray .h2.text-light,
.callout-cool-gray .text-light.text-size-h2,
.callout-cool-gray .h3.text-light,
.callout-cool-gray .text-light.text-size-h3,
.callout-cool-gray .h4.text-light,
.callout-cool-gray .text-light.text-size-h4,
.callout-cool-gray .h5.text-light,
.callout-cool-gray .text-light.text-size-h5,
.callout-cool-gray .h6.text-light,
.callout-cool-gray .text-light.text-size-h6,
.callout-warm-gray h1.text-light,
.callout-warm-gray h2.text-light,
.callout-warm-gray h3.text-light,
.callout-warm-gray h4.text-light,
.callout-warm-gray h5.text-light,
.callout-warm-gray h6.text-light,
.callout-warm-gray .h1.text-light,
.callout-warm-gray .text-light.text-size-h1,
.callout-warm-gray .h2.text-light,
.callout-warm-gray .text-light.text-size-h2,
.callout-warm-gray .h3.text-light,
.callout-warm-gray .text-light.text-size-h3,
.callout-warm-gray .h4.text-light,
.callout-warm-gray .text-light.text-size-h4,
.callout-warm-gray .h5.text-light,
.callout-warm-gray .text-light.text-size-h5,
.callout-warm-gray .h6.text-light,
.callout-warm-gray .text-light.text-size-h6,
.callout-sky h1.text-light,
.callout-sky h2.text-light,
.callout-sky h3.text-light,
.callout-sky h4.text-light,
.callout-sky h5.text-light,
.callout-sky h6.text-light,
.callout-sky .h1.text-light,
.callout-sky .text-light.text-size-h1,
.callout-sky .h2.text-light,
.callout-sky .text-light.text-size-h2,
.callout-sky .h3.text-light,
.callout-sky .text-light.text-size-h3,
.callout-sky .h4.text-light,
.callout-sky .text-light.text-size-h4,
.callout-sky .h5.text-light,
.callout-sky .text-light.text-size-h5,
.callout-sky .h6.text-light,
.callout-sky .text-light.text-size-h6 {
  color: #dee2e6;
}

.callout-silver h1.text-dark,
.callout-silver h2.text-dark,
.callout-silver h3.text-dark,
.callout-silver h4.text-dark,
.callout-silver h5.text-dark,
.callout-silver h6.text-dark,
.callout-silver .h1.text-dark,
.callout-silver .text-dark.text-size-h1,
.callout-silver .h2.text-dark,
.callout-silver .text-dark.text-size-h2,
.callout-silver .h3.text-dark,
.callout-silver .text-dark.text-size-h3,
.callout-silver .h4.text-dark,
.callout-silver .text-dark.text-size-h4,
.callout-silver .h5.text-dark,
.callout-silver .text-dark.text-size-h5,
.callout-silver .h6.text-dark,
.callout-silver .text-dark.text-size-h6,
.callout-cool-gray h1.text-dark,
.callout-cool-gray h2.text-dark,
.callout-cool-gray h3.text-dark,
.callout-cool-gray h4.text-dark,
.callout-cool-gray h5.text-dark,
.callout-cool-gray h6.text-dark,
.callout-cool-gray .h1.text-dark,
.callout-cool-gray .text-dark.text-size-h1,
.callout-cool-gray .h2.text-dark,
.callout-cool-gray .text-dark.text-size-h2,
.callout-cool-gray .h3.text-dark,
.callout-cool-gray .text-dark.text-size-h3,
.callout-cool-gray .h4.text-dark,
.callout-cool-gray .text-dark.text-size-h4,
.callout-cool-gray .h5.text-dark,
.callout-cool-gray .text-dark.text-size-h5,
.callout-cool-gray .h6.text-dark,
.callout-cool-gray .text-dark.text-size-h6,
.callout-warm-gray h1.text-dark,
.callout-warm-gray h2.text-dark,
.callout-warm-gray h3.text-dark,
.callout-warm-gray h4.text-dark,
.callout-warm-gray h5.text-dark,
.callout-warm-gray h6.text-dark,
.callout-warm-gray .h1.text-dark,
.callout-warm-gray .text-dark.text-size-h1,
.callout-warm-gray .h2.text-dark,
.callout-warm-gray .text-dark.text-size-h2,
.callout-warm-gray .h3.text-dark,
.callout-warm-gray .text-dark.text-size-h3,
.callout-warm-gray .h4.text-dark,
.callout-warm-gray .text-dark.text-size-h4,
.callout-warm-gray .h5.text-dark,
.callout-warm-gray .text-dark.text-size-h5,
.callout-warm-gray .h6.text-dark,
.callout-warm-gray .text-dark.text-size-h6,
.callout-sky h1.text-dark,
.callout-sky h2.text-dark,
.callout-sky h3.text-dark,
.callout-sky h4.text-dark,
.callout-sky h5.text-dark,
.callout-sky h6.text-dark,
.callout-sky .h1.text-dark,
.callout-sky .text-dark.text-size-h1,
.callout-sky .h2.text-dark,
.callout-sky .text-dark.text-size-h2,
.callout-sky .h3.text-dark,
.callout-sky .text-dark.text-size-h3,
.callout-sky .h4.text-dark,
.callout-sky .text-dark.text-size-h4,
.callout-sky .h5.text-dark,
.callout-sky .text-dark.text-size-h5,
.callout-sky .h6.text-dark,
.callout-sky .text-dark.text-size-h6 {
  color: #343a40;
}

.table .thead-light th {
  color: #343a40;
  background-color: #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(222, 226, 230, 0.4);
}

.table-hover tbody tr:hover {
  background-color: rgba(222, 226, 230, 0.5);
}

/*
The following styles are intended for backwards-compatability for sites moving
from UA Bootstrap (Bootstrap 3) to Arizona Bootstrap (Bootstrap 4). This is to
avoid breaking changes; sites should not use these components/classes as they
have been deprecated in Bootstrap 4.
*/
/*
* > COMPONENTS
*/
.bg-triangles-fade {
  background-image: url("img/fading-triangles.svg");
  background-repeat: repeat-x;
  background-size: auto 450px;
}

.bg-catalinas-abstract {
  background-image: url("img/catalinas-abstract.svg");
  background-repeat: repeat-x;
  background-size: auto 230px;
}

.bg-triangles-mosaic {
  background-image: url("img/mosaic-triangles.svg");
  background-repeat: repeat;
  background-size: auto 130px;
}

.panel .panel-title {
  font-size: 18px;
}

.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

/*
* > UTILITIES
*/
.modal-bg-dark .close {
  color: #000;
}

@media (max-width: 575.98px) {
  .text-left-not-xs,
  .text-center-not-xs,
  .text-right-not-xs,
  .text-justify-not-xs {
    text-align: inherit !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .text-left-not-sm,
  .text-center-not-sm,
  .text-right-not-sm,
  .text-justify-not-sm {
    text-align: inherit !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .text-left-not-md,
  .text-center-not-md,
  .text-right-not-md,
  .text-justify-not-md {
    text-align: inherit !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .text-left-not-lg,
  .text-center-not-lg,
  .text-right-not-lg,
  .text-justify-not-lg {
    text-align: inherit !important;
  }
}

.page-link {
  color: #1e5288;
}

.page-link:hover {
  color: #001c48;
}

.jumbotron {
  padding: 2rem;
  background-color: #fff;
}

.tooltip-inner {
  background-color: #0c234b;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #0c234b;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #0c234b;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #0c234b;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #0c234b;
}

.popover {
  font-size: 1rem;
}

.accordion .card-header {
  padding: 0;
}

.accordion .card-header h2,
.accordion .card-header h3,
.accordion .card-header h4,
.accordion .card-header h5,
.accordion .card-header h6,
.accordion .card-header .h2,
.accordion .card-header .text-size-h2,
.accordion .card-header .h3,
.accordion .card-header .text-size-h3,
.accordion .card-header .h4,
.accordion .card-header .text-size-h4,
.accordion .card-header .h5,
.accordion .card-header .text-size-h5,
.accordion .card-header .h6,
.accordion .card-header .text-size-h6 {
  padding: 0;
}

.accordion .card-header .btn {
  display: block;
  width: 100%;
  padding: .75rem 1.25rem;
  color: #49595e;
  text-align: left;
  text-transform: none;
}

.accordion .card-header .btn::after {
  position: absolute;
  top: 2px;
  right: 20px;
  display: inline-block;
  font-family: "Material Icons Sharp";
  font-size: 2em;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-variant-ligatures: no-common-ligatures;
  content: "expand_more";
}

.accordion .card-header .btn[aria-expanded="true"]::after {
  content: "expand_less";
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-item {
  position: relative;
}

.az-gallery,
.az-gallery-inner {
  width: 100%;
  height: 100%;
}

.az-gallery-modal .close {
  position: absolute;
  top: 0;
  right: 5%;
  z-index: 5000;
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 3rem;
  color: #fff;
  opacity: 1;
}

.ua-gallery-item,
.az-gallery-item {
  width: 100%;
  height: 100%;
}

.ua-gallery-carousel-image,
.az-gallery-img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 70%;
  max-height: 80%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ua-gallery-caption,
.az-gallery-caption {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 10;
  width: 100%;
  max-width: 850px;
  padding: .75rem;
  margin: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ua-gallery-caption p:last-of-type,
.az-gallery-caption p:last-of-type {
  margin-bottom: 0;
}

.photo-gallery-grid-img {
  max-width: 100%;
}

#hide-inaccessible-label {
  cursor: pointer;
}
/*# sourceMappingURL=arizona-bootstrap.css.map */